import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, } from '@chakra-ui/react';
import Business from './Business';
import LatestUpdate from './LatestUpdate';
import Life from './Life';
import Hobbies from './Hobbies';
import ReachOut from './ReachOut';
import OpenForBusiness from '../../general/components/OpenForBusiness';

const NowContent = () => {
  return (
    <>
    <OpenForBusiness available="false"/>
    <LatestUpdate />
    <hr />
    <Business />
    <hr />
    <Life />
    <hr />
    <Hobbies />
    <hr />
    <ReachOut />
    </>
  );
};

export default NowContent;
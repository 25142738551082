import {
  Button,
  useColorModeValue,
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react';
import React, { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { AiOutlineLock } from 'react-icons/ai';


const renderUnlockConditions = (mode, flattenedGameModes) => {
  if (!flattenedGameModes) {
    return "";
  }

  //console.log(mode);
  //console.log(flattenedGameModes);

  return mode.unlock_conditions
    .map((condition) => `scoring ${condition.score}+ in ${flattenedGameModes.find((m) => m.id === condition.id).name}`)
    .join(" and ");
};


function ModeButton({ mode, onModeSelected, selectedMode, isLocked, gameModes, isModeUnlocked }) {
  const { name, settings, unlock_conditions } = mode;
  const [selectedChildMode, setSelectedChildMode] = useState(null);
  // Add this line to detect whether the device is a mobile device
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  // Use the isMobile variable to conditionally disable or enable the tooltip
  const desktopTooltipDisabled = isMobile || !isLocked;
  const mobileTooltipDisabled = !isMobile || !isLocked;
  

  const isSelected = selectedMode && selectedMode.name === mode.name;
  const bgColor = useColorModeValue(isSelected ? 'teal.500' : 'gray.100', isSelected ? 'teal.700' : 'transparent');
  const hoverBgColor = useColorModeValue('teal.200', 'teal.600');
  const isTopLevelLocked = !isModeUnlocked(mode);

  const handleClickWithLock = () => {
    if (!isLocked) {
      handleClick();
    }
  };


  const flattenGameModes = (gameModes) => {
    let flattened = [];
  
    gameModes.forEach((mode) => {
      if (mode.isGrouped) {
        flattened.push(...mode.groupedModes);
      } else {
        flattened.push(mode);
      }
    });
  
    return flattened;
  };
  
  const flattenedGameModes = flattenGameModes(gameModes);
  

  const handleClick = () => {
    if (!isLocked) {
      onModeSelected(mode);
    }
  };
  


  function renderGroupedModes(groupedModes) {
    return groupedModes.map((mode) => {
      const isLocked = !isModeUnlocked(mode);
      const unlockMessage = renderUnlockConditions(mode, flattenedGameModes);
  
      const menuItemContent = (
        <>
          <Box>
            {mode.name}
            {isLocked && (
              <Flex flexDirection="row" ml="auto" alignItems="center">
                <Icon display="inline" as={AiOutlineLock} boxSize="1.2em" color="red.500" />
                <Text display="inline"  fontSize="xs"> {unlockMessage}</Text>
              </Flex>
            )}
          </Box>
          
        </>
      );
  
      return (
        <MenuItem
          isDisabled={isLocked}
          onClick={() => {
            setSelectedChildMode(mode);
            onModeSelected(mode);
          }}
          bg={selectedMode && selectedMode.id === mode.id ? bgColor : "transparent"}
        >
          {menuItemContent}
        </MenuItem>
      );
    });
  }

  const isSelectedGroup = () => {
    return (
      mode.isGrouped &&
      selectedMode &&
      mode.groupedModes.some((childMode) => childMode.id === selectedMode.id)
    );
  };

  //console.log(isSelectedGroup());
  const tooltipLabel = isLocked ? `Unlock by ${renderUnlockConditions(mode, flattenedGameModes)}` : '';
  const tooltipDisabled = isMobile ? false : !isLocked;

  const topLevelTooltipLabel = isTopLevelLocked ? `Unlock by ${renderUnlockConditions(mode, flattenedGameModes)}` : "";
  const buttonBgColor = useColorModeValue(isSelectedGroup() ? 'teal.500' : 'gray.100', isSelectedGroup() ? 'teal.700' : 'transparent');

  if (mode.isGrouped) {
    return (
      <Menu>
        <Tooltip label={topLevelTooltipLabel} placement="top" isDisabled={tooltipDisabled} hasArrow>
        <MenuButton
          as={Button}
          bg={buttonBgColor}
          boxShadow="md"
          mb={{'base':2,'md':0}}
          ml={{'base':0,'md':2}}
          _hover={{
            bg: hoverBgColor,
          }}
          rightIcon={<FaChevronDown />}
          position="relative"
          isDisabled={false} 
        >
          {isTopLevelLocked && (
            <Box position="absolute" top="0" right="0">
              <Icon as={AiOutlineLock} boxSize="1.2em" color="red.500" />
            </Box>
          )}
          {selectedChildMode ? selectedChildMode.name : name}
        </MenuButton>
      </Tooltip>
        <MenuList>
          {renderGroupedModes(mode.groupedModes)}
        </MenuList>
      </Menu>
    );
  }

  return (
    
    <Tooltip label={tooltipLabel} placement="top" isDisabled={tooltipDisabled} hasArrow>
      <Button
        onClick={handleClick}
        bg={bgColor}
        boxShadow="md"
        mb={{'base':2,'md':0}}
        ml={{'base':0,'md':2}}
        _hover={{
          bg: isLocked ? bgColor : hoverBgColor,
        }}
        isDisabled={false}
        position="relative"
      >
        {name}
        {isLocked && (
          <Box position="absolute" top="0" right="0">
            <Icon as={AiOutlineLock} boxSize="1.2em" color="red.500" />
          </Box>
        )}
      </Button>
    </Tooltip>
  );
}

  export default ModeButton;

// PickemContext.js
import React, { createContext, useReducer, useEffect, useRef, useState } from 'react';
import pickemReducer from './pickemReducer';
import { getDaysBetweenDates } from '../../calc/dates';
import { supabase } from '../../../api/supabaseClient';

let weekNumber = 1+Math.floor(getDaysBetweenDates(new Date('2024-09-03'), new Date()) / 7);

if(weekNumber < 1) {
  weekNumber = 1;
}
else if(weekNumber > 22) {
  weekNumber = 22;
}

const initialState = {
  leagues: [],
  games: [],
  players: [],
  user: null,
  event: null,
  event_stats: null,
  current_event: null,
  current_player: null,
  current_season: 2024,
  current_week: weekNumber,

  // Add more initial state properties as needed
};

const PickemContext = createContext();

const PickemProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(pickemReducer, initialState);
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    // Check for existing session
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      dispatch({ type: 'SET_USER', payload: session?.user ?? null });
      setLoading(false);
    };

    checkSession();

    // Listen for auth changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        dispatch({ type: 'SET_USER', payload: session?.user ?? null });
      }
    );

    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  const signIn = async (email, password) => {
    try {
      // First, try to sign in normally
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  
      if (error) {
        // If the error is due to an unconfirmed email
        if (error.message.includes('Email not confirmed') || error.status === 400) {
          console.log('Email not confirmed, attempting alternative sign-in');
          
          // Use signUp to create a session for the unconfirmed user
          const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
            email,
            password,
          });
  
          if (signUpError) {
            throw signUpError;
          }
  
          if (signUpData.user) {
            console.log('Unconfirmed user signed in successfully');
            dispatch({ type: 'SET_USER', payload: signUpData.user });
            return;
          }
        } else {
          // If it's a different error, throw it
          throw error;
        }
      } else {
        // If sign-in was successful
        console.log('User signed in successfully');
        dispatch({ type: 'SET_USER', payload: data.user });
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      dispatch({ type: 'SET_USER', payload: null });
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };



  if (loading) {
    return <div>Loading...</div>; // Or your custom loading component
  }

  return (
    <PickemContext.Provider value={{ state, dispatch, signIn, signOut }}>
      {children}
    </PickemContext.Provider>
  );
};

export { PickemContext, PickemProvider };
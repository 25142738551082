import React, { useState, useEffect } from 'react';
import { Box, Heading, Link, Text, VStack, HStack, Image, Center, useColorModeValue } from '@chakra-ui/react';
import axios from 'axios';
import { API_BASE_URL } from '../../api/constants';

const SubstackFeed = ({ title, blogs, numberOfPosts, specificPosts }) => {
  const topBgColor = useColorModeValue("forwardWhite.100","forwardBlue.800");
  const borderColor = useColorModeValue("forwardWhite.500","forwardBlue.600");
  const linkColor = useColorModeValue("forwardBlue.500","forwardBlue.300");

  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    try {
      const response = await axios.post(API_BASE_URL+'api/substack/', { blogs, numberOfPosts, specificPosts });
      //console.log(response);
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching Substack feed:', error);
    }
  };

  useEffect(() => {
    fetchPosts();
    console.log(posts);
  }, [blogs, numberOfPosts, specificPosts]);

  return (
    <Box width="100%">
        <Center>
      <Heading as="h2" size="lg" mb={4}>
        {title}
      </Heading>
      </Center>
      <Center>
      <VStack alignContent="flex-start" alignItems="flex-start" width="100%" maxWidth="1000px">
      {posts.map((post) => (
        <Box key={post.guid._text} p={2} border="1px" borderColor={borderColor} width="100%"  bgColor={topBgColor} boxShadow="lg">
          <Heading as="h3" size="md" mb={1}>
            <Link href={post.link._text} isExternal color={linkColor}>
              {post.title._cdata}
            </Link>
          </Heading>
          
          <Text fontSize="sm">{post.description._cdata}</Text>
          <HStack justifyContent="space-between">
          <Text fontSize="sm">By: Ben Mumma on {post.pubDate._text}</Text>
          <Link href={post.link._text} isExternal color={linkColor}>
              Read more...
            </Link>
          </HStack>
        </Box>
      ))}
      </VStack>
      </Center>
    </Box>
  )
}

export default SubstackFeed;

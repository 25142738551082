const current = [
    {
      job_title: 'Founder, Consultant',
      category: 'Current',
      location: '[Remote] Dallas, TX',
      company: 'Mumma Labs, LLC',
      start_date: '2023',
      end_date: 'Present',
      tenure: '1 year',
      company_info: 'We provide data & strategy consulting services while building software to make the world a better place.',
      company_website:'https://www.mumma.co',
      bullet_points:[
        'Developed Forward, a productivity web app launched in September 2023. Use it for free @ www.theforwardapp.com!',
        'Advised 50-person stealth-stage SaaS startup on professional services strategy, business value assessment, and growth plan over a 3-month paid engagement.',
        'Advising several local startups on tech and data strategy.',
      ]
    }
]

export {current};
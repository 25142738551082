import { Table, Thead, Tbody, Tr, Th, Td, Text, Flex, HStack, Heading } from "@chakra-ui/react";
import { gameModes } from "../data/constants";
import {calculateGameStats} from "../calc/statistics";
import { FaStar } from "react-icons/fa";
import ShareButton from "./ShareButton";

function StarRating({ num }) {
    const stars = Array.from({ length: num }, (_, i) => (
      <FaStar key={i} color="gold" />
    ));
    return <Flex justifyContent="center" flexDirection="row" display="flex" width="100%">{stars}</Flex>;
  }

function PersonalBests(gameHistory) {

    const {modeStats:modeStats, my_totals:my_totals} = calculateGameStats(gameHistory.gameHistory);

    const my_stats_to_pass = 'I\'ve earned: '+my_totals.stars+' / 30 stars in Expound! Can you beat me? \n Expound is a fun word game, try it out:';
    console.log(modeStats);
    return (
        <>
        <HStack width="90%" justifyContent="space-between">
          <Heading as="h2" size="l">Your Statistics:</Heading>
          <ShareButton my_stats={my_stats_to_pass}/>
          </HStack>
        <Flex alignItems="center" justifyContent="center" flexDirection="row" display="flex" width="100%"><Text mr={1}>Total Stars Earned: {my_totals.stars}</Text><Text mr={1}> / 30 </Text><FaStar key="i" color="gold" /></Flex>
        <Table variant="simple">
        <Thead>
            <Tr>
            <Th>Mode</Th>
            <Th textAlign="center" display="none">Games Played</Th>
            <Th textAlign="center">Best Score</Th>
            <Th textAlign="center">Rating</Th>
            </Tr>
        </Thead>
        <Tbody>
        {Object.entries(modeStats).filter(([key,value]) => { return value.type==='aggregate';}).map(([key, value]) => (
            <Tr key={key}>
                <Td>{value.gameName}</Td>
                <Td textAlign="center" display="none">{value.count}</Td>
                <Td textAlign="center">{value.bestScore}</Td>
                <Td textAlign="center"><StarRating num={value.stars} /></Td>
            </Tr>
            ))}
        
        </Tbody>
        </Table>
        </>
    );
  }

  export default PersonalBests;

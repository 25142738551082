import { useCallback, useContext } from 'react'
import { PickemContext } from '../context/PickemContext';
import { readTeams } from '../database/teams';

export const useTeams = () => {
  const { state, dispatch } = useContext(PickemContext);

  const syncTeams = useCallback(async () => {
    try {
      const data = await readTeams()
      dispatch({ type: 'SET_TEAMS', payload: data })
      //console.log('Teams:', data)
    } catch (error) {
      console.log('error', error)
    }
  }, [dispatch])

  return {
    syncTeams
  }
}
import React from 'react';
import { Box, Grid, Heading, Center, Divider} from '@chakra-ui/react';
import Section from '../../general/components/Section';
import {servicesData} from '../data/services';


function OurServices({onCardClick, hidden, category=null}) {
    
    if(hidden===true) {
        return <></>;
    }

    let shownData = servicesData.filter((card) => card.hidden !== 1);

    if(category !== null) {
    shownData = shownData.filter((card) => (card.category === category || card.category === 'all'));
    }

    return (
        <>
        <Section 
        title="Get Started Today:" 
        subtitle="Click on any offering for more detail." 
        data={shownData}
        onCardClick={onCardClick}
        showImages={false} />
        <Divider py={4} />
        </>
    );
}
  
  export default OurServices;

import {letterFrequencies, firstLetterFrequency, lastLetterFrequency} from '../../expound/data/constants.js';

function generateRandomLetter(frequencyDistribution) {
    const randomValue = Math.random() * 100;
    let cumulativeProbability = 0;
  
    for (const letter in frequencyDistribution) {
      cumulativeProbability += frequencyDistribution[letter];
      if (randomValue < cumulativeProbability) {
        return letter;
      }
    }
  }

  
function generateLetters(count, isPreset, presetList, currentRound) {
    if (count < 2) {
      throw new Error("Count must be at least 2 to generate first and last letters.");
    }

    if (isPreset) {
      console.log(presetList);
      return presetList[currentRound-1];
    }
  
    const letters = [];
  
    // Generate the first letter using the firstLetterFrequency distribution
    letters.push(generateRandomLetter(firstLetterFrequency));
  
    // Generate the middle letters using the generalLetterFrequency distribution
    for (let i = 1; i < count - 1; i++) {
      letters.push(generateRandomLetter(letterFrequencies));
    }
  
    // Generate the last letter using the lastLetterFrequency distribution
    letters.push(generateRandomLetter(lastLetterFrequency));
  
    return letters;
  }

  export {generateLetters, generateRandomLetter}
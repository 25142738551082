const { differenceInDays, format, addMinutes } = require('date-fns');

function getDaysBetweenDates(date1, date2) {
    const diffInDays = differenceInDays(date2, date1);
    return diffInDays;
}
const formatUTCDate = (date, d_format='yyyy-MM-dd') => {
    //Check if date is a string in d_format, if so, return it
    if (typeof date === 'string' && date.length === d_format.length) {
      return date;
    }
    const timezoneOffset = date.getTimezoneOffset();
    // If date is not a Date object, return null
    return format(addMinutes(date, timezoneOffset), d_format, { timeZone: 'UTC' });
}

function getWeekday(date) {
    return date.toLocaleDateString('en-US', { weekday: 'long' });
}

const convertESTtoUTC = (dateString) => {
    // Create a Date object from the EST date string
    const estDate = new Date(dateString);

    // Get the UTC time by adding the EST offset (5 hours)
    const utcDate = new Date(estDate.getTime() + (4 * 60 * 60 * 1000));

    return utcDate;
};


module.exports = {
    getDaysBetweenDates,
    formatUTCDate,
    getWeekday,
    convertESTtoUTC
};
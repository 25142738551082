
import { supabase } from "../../../api/supabaseClient";

export async function readTeams() {
    let { data, error } = await supabase
        .from('nfl_teams')
        .select(`*`)
    if (error) {
        console.log('error', error)
    } else {
        return data
    }
}
import { useCallback, useContext } from 'react'
import { PickemContext } from '../context/PickemContext';
import * as sh from '../../calc/scoring';

export const useEventStats = () => {
    const { state, dispatch } = useContext(PickemContext);

  const calcEventStats = useCallback(async () => {
    
    try {
      console.log('Calculating event stats');
      dispatch({ type: 'PRINT_STATE' });

      const nfl_picks = state?.event?.nfl_picks;
      const games = state?.games;
      const players = state?.players.filter(player => state?.event?.nfl_player_events.some(event => event.player_id === player.id));
      const configuration = state?.event?.configuration;

      /* Define scoring variables */
      const base_points = configuration.scoring.base || 1;
      const key_points = configuration.scoring.key_base || 1;
      const key_unique = configuration.scoring.key_unique || 0;
      const underdog_points = configuration.scoring.underdog_base || 0;
      const underdog_score = configuration.scoring.underdog_score || 0;
      const underdog_spread = configuration.scoring.underdog_spread || 0;
      const underdog_limit = configuration.scoring.underdog_limit || 0;
      const blowout_threshold = configuration.scoring.blowout_threshold || 0;
      const blowout_points = configuration.scoring.blowout_points || 0;
      const first_time_winner = configuration.scoring.first_time_winner || 0;
      const excluded_low_weeks = configuration.scoring.excluded_low_weeks || 0;
      const tiebreaker = configuration.scoring.tiebreaker || "sov";

      const initial_state = {
            picks: 0,
            wins: 0,
            basePoints: 0,
            keyPoints: 0,
            underdogPoints: 0,
            blowoutPoints: 0,
            firstTimeWinnerPoints: 0,
            sov: 0,
            key_sov: 0,
            total_points: 0,
      }



      const newPlayerStats = {};
        if (!nfl_picks || !games || !players || !configuration) {
            console.log(nfl_picks, games, players, configuration);
            console.log('Missing data to calculate event stats');
            return;
            }

      players.forEach(player => {
        newPlayerStats[player.id] = {
          weeklyStats: {},
          overallStats: { ...initial_state }
        };
      });
  
      nfl_picks.forEach((pick) => {
        // Find the corresponding game
        const game = games.find(g => 
          g.season === pick.season &&
          g.week === pick.week &&
          (g.home_team === pick.pick || g.away_team === pick.pick)
        );
  
        if (!game) return; // Skip if no matching game found

          const correct_picks = sh.calc_correct_picks(pick, game);
          let multiplier = 1;
          const week_string = pick.week.toString();
          //console.log('Checking for multiplier for week', week_string);
          if (configuration.scoring.multipliers && configuration.scoring.multipliers[week_string]) {
            //console.log('Found multiplier for week', pick.week);
            multiplier = configuration.scoring.multipliers[pick.week];
          }

          /*
          Need to calculate if a team has been used as a key pick before
          And if a team has been picked and won at all
          */
         const is_unique_key_pick = sh.calc_is_first_key_pick(pick, nfl_picks);
         const was_previous_pick_win = sh.calc_was_previous_pick_win(pick, nfl_picks, games);
        const b_points = base_points * correct_picks;
        const ud_points = sh.calc_underdog_points(pick, game, underdog_points, underdog_score, underdog_spread, underdog_limit);
        const kp_points = sh.calc_key_points(pick, game, is_unique_key_pick, key_points, key_unique);
        const bo_points = sh.calc_blowout_points(pick, game, blowout_threshold, blowout_points);
        const ftw_points = sh.calc_ftw_points(pick, game, was_previous_pick_win, first_time_winner);
        const sov_points = sh.calc_sov(pick, game);
        const key_sov_points = sh.calc_key_sov(pick, game);


        if (!newPlayerStats[pick.player_id].weeklyStats[pick.week]) {
          newPlayerStats[pick.player_id].weeklyStats[pick.week] = { ...initial_state };
        }

        //Update Weekly Stats
        newPlayerStats[pick.player_id].weeklyStats[pick.week].wins += correct_picks;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].picks += 1;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].basePoints += b_points*multiplier;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].keyPoints += kp_points*multiplier;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].underdogPoints += ud_points*multiplier;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].blowoutPoints += bo_points*multiplier;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].firstTimeWinnerPoints += ftw_points*multiplier;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].sov += sov_points;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].key_sov += key_sov_points;
        newPlayerStats[pick.player_id].weeklyStats[pick.week].total_points += multiplier*(b_points + kp_points + ud_points + bo_points + ftw_points+(0.0001*sov_points));
        //Update Overall Stats
        newPlayerStats[pick.player_id].overallStats.wins += correct_picks;
        newPlayerStats[pick.player_id].overallStats.picks += 1;
        newPlayerStats[pick.player_id].overallStats.basePoints += b_points*multiplier;
        newPlayerStats[pick.player_id].overallStats.keyPoints += kp_points*multiplier;
        newPlayerStats[pick.player_id].overallStats.underdogPoints += ud_points*multiplier;
        newPlayerStats[pick.player_id].overallStats.blowoutPoints += bo_points*multiplier;
        newPlayerStats[pick.player_id].overallStats.firstTimeWinnerPoints += ftw_points*multiplier;
        newPlayerStats[pick.player_id].overallStats.sov += sov_points;
        newPlayerStats[pick.player_id].overallStats.key_sov += key_sov_points;
        newPlayerStats[pick.player_id].overallStats.total_points += multiplier*(b_points + kp_points + ud_points + bo_points + ftw_points+(0.0001*sov_points));
      });

      const player_summary = players
      .map((player) => {
        const player_stats = newPlayerStats[player.id] || {};
        
        // Sum up weeks from the player's weekly stats
        const fh_points = Object.keys(player_stats.weeklyStats).reduce((acc, week) => {
            return acc + (week <= 9 ? parseFloat(player_stats.weeklyStats[week].total_points) : 0);
        }, 0);

        const sh_points = Object.keys(player_stats.weeklyStats).reduce((acc, week) => {
            return acc + (week >= 10 && week <= 18 ? parseFloat(player_stats.weeklyStats[week].total_points) : 0);
        }, 0);

        const po_points = Object.keys(player_stats.weeklyStats).reduce((acc, week) => {
            return acc + (week >= 19 ? parseFloat(player_stats.weeklyStats[week].total_points) : 0);
        }, 0);

        return {
          ...player,
          total_points: player_stats.overallStats.total_points || 0,
          fh_points: fh_points,
        sh_points: sh_points,
            po_points: po_points,
            sov: player_stats.overallStats.sov,
            base_points: player_stats.overallStats.basePoints,
            key_points: player_stats.overallStats.keyPoints,
            underdog_points: player_stats.overallStats.underdogPoints,
            blowout_points: player_stats.overallStats.blowoutPoints,
            first_time_winner_points: player_stats.overallStats.firstTimeWinnerPoints,
            wins: player_stats.overallStats.wins,
            picks: player_stats.overallStats.picks,
        };
      })
      .map((player, index) => {
        const weeklyStats = newPlayerStats[player.id].weeklyStats;

        const start_week = configuration.scoring.excluded_start_week;
        const end_week = configuration.scoring.excluded_end_week;
       const allScores = Object.keys(newPlayerStats[player.id].weeklyStats)
                        .map(week => parseFloat(newPlayerStats[player.id].weeklyStats[week].total_points));
        const droppableScores = Object.keys(newPlayerStats[player.id].weeklyStats)
                        .filter(week => parseInt(week) >= parseInt(start_week) && parseInt(week) <= parseInt(end_week))
        .map(week => parseFloat(newPlayerStats[player.id].weeklyStats[week].total_points));

        const bw_start = configuration.payments.payouts.weekly_pool_start;
        const bw_end = configuration.payments.payouts.weekly_pool_end;

       const bestWeekScores = Object.keys(newPlayerStats)
       .map(pId => 
        Object.keys(newPlayerStats[pId].weeklyStats)
        .filter(week => parseInt(week) >= parseInt(bw_start) && parseInt(week) <= parseInt(bw_end))
        .map(week => 
            parseFloat(newPlayerStats[pId].weeklyStats[week].total_points + 
            (0.0001 * newPlayerStats[pId].weeklyStats[week].sov))
        )
    );
    const bestScores = {};

    Object.keys(weeklyStats).forEach(week => {
        const scoresForWeek = bestWeekScores.map(scores => scores[week - 1]); // Get all scores for that week
        const maxScore = Math.max(...scoresForWeek);
        const playersWithMaxScore = scoresForWeek.filter(score => score === maxScore).length;
        bestScores[week] = (weeklyStats[week].total_points + (0.0001 * weeklyStats[week].sov) === maxScore) ? 1 / playersWithMaxScore : 0;
    });
    const totalBestWeeks = Object.values(bestScores).reduce((acc, isBest) => acc + isBest, 0);

       const excluded_low_weeks = configuration.scoring.excluded_low_weeks || 0;
       const sortedScores = droppableScores.sort((a, b) => a - b);
        //const validScores = sortedScores.slice(excluded_low_weeks); // Remove the lowest N scores
        const invalidScores = sortedScores.slice(0, excluded_low_weeks);
        const validTotalPoints = allScores.reduce((acc, score) => acc + score, 0) - invalidScores.reduce((acc, score) => acc + score, 0);

        return {
            ...player,
            valid_total_points: validTotalPoints,
            best_weeks: totalBestWeeks,
            excluded_points: player.total_points - validTotalPoints,
        }
    }).sort((a, b) => b.valid_total_points - a.valid_total_points);

    console.log('Player Summary:', player_summary);

    const leading_values = {
        total_points: Math.max(...player_summary.map(p => p.total_points)),
        fh_points: Math.max(...player_summary.map(p => p.fh_points)),
        sh_points: Math.max(...player_summary.map(p => p.sh_points)),
        po_points: Math.max(...player_summary.map(p => p.po_points)),
        valid_total_points: Math.max(...player_summary.map(p => p.valid_total_points)),
        wins: Math.max(...player_summary.map(p => p.wins)),
        picks: Math.max(...player_summary.map(p => p.picks)),
        base_points: Math.max(...player_summary.map(p => p.base_points)),
        key_points: Math.max(...player_summary.map(p => p.key_points)),
        underdog_points: Math.max(...player_summary.map(p => p.underdog_points)),
        blowout_points: Math.max(...player_summary.map(p => p.blowout_points)),
        first_time_winner_points: Math.max(...player_summary.map(p => p.first_time_winner_points)),
        best_weeks: Math.max(...player_summary.map(p => p.best_weeks)),
        excluded_points: Math.max(...player_summary.map(p => p.excluded_points)),
        // Include 'sov' if it's part of your player_summary objects
        sov: Math.max(...player_summary.map(p => p.sov || 0)),  // assuming 'sov' might be missing or undefined
    };

      const overallStats = {
        'player_summary': player_summary,
        'leading_values': leading_values
      }

      
      


      dispatch({ type: 'UPDATE_EVENT_STATS', payload: newPlayerStats})
      dispatch({ type: 'UPDATE_OVERALL_STATS', payload: overallStats})
      dispatch({ type: 'PRINT_STATE' });
    } catch (error) {
      console.log('error', error)
    }
  }, [state, dispatch])

  return {
    calcEventStats
  }
}
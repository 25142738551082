const current = [
    {
      job_title: 'Founder, Consultant',
      category: 'Current',
      location: '[Remote] Dallas, TX',
      company: 'Mumma Labs, LLC',
      start_date: '2023',
      end_date: 'Present',
      tenure: '3 months',
      company_info: 'We provide AI, tech, and data strategy advisory and services focused on helping enterprises develop their architecture to leverage ML and AI.',
      company_website:'https://mumma.co',
      bullet_points:[
      ]
    }
]

export {current};
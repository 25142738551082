import React from 'react';
import { Box, Heading, Text, Flex, Button, ButtonGroup, Center, VStack} from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import ResumeHead from '../content/ResumeHead';
import Jobs from '../content/Jobs';
import Skills from '../content/Skills';
import Education from '../content/Education';
import Hobbies from '../content/Hobbies';
import { paddingSizes, fontSizes } from '../../general/responsive.js';
import {education} from '../data/base/education';
import { skills } from '../data/base/skills';
import { interests } from '../data/base/interests';
import { roles } from '../data/base/roles';
import { current } from '../data/base/current';
import { Link } from 'react-router-dom';

const Resume = ({session}) => {
  return (
    <>
    <MyHeader className="no-print" />
    <Navbar className="no-print" />
    <Box py={2} px={2} fontSize={fontSizes} width="100%">
      <Center>
        <VStack maxWidth="100%">
        <ResumeHead />
        <Jobs current={current} roles={roles}/>
        <Skills skills={skills}/>
        <Education education={education}/>
        <Hobbies interests={interests}/>
        </VStack>
      </Center>

    </Box>
    </>
  );
};

export default Resume;
const appsData = [
    
    {
      title: 'Expound',
      description: 'A fast and fun word game to test your vocabulary!',
      imageUrl: '/home/expound_new.png',
      url: './expound',
      internal: true,
    },
    {
      title: 'Math24 Solver',
      description: 'Ever been stumped by a Math24 Card? No more! Get your answers here!',
      imageUrl: '/home/m24.jpg',
      url: './math24',
      internal: true,
    },
    {
      title: 'God: The Democracy',
      description: 'Read the start of my Novel',
      imageUrl: 'home/gtd.png',
      url: './god',
      internal: true,
    },
    /*{
      title: 'WeAchieve Lite',
      description: 'Simple and Private Habit Tracking',
      imageUrl: '/home/wa1024-tb.png',
      url: './weachieve',
      internal: true,
    },*/
    // Add more objects as needed
  ];
  
  export default appsData;
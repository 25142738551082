import React from 'react';
import { HomeButton } from '../../general/components/HomeButton';
import { Box, VStack, Heading, Text, Flex, Button, ButtonGroup, IconButton, useDisclosure } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../general/components/ColorModeSwitcher';
import { FaComment, FaInfoCircle } from 'react-icons/fa';
import FeedbackModal from '../../general/components/FeedbackModal';
import InfoModal from '../../general/components/InfoModal';
import { infoObject } from '../data/info';

const Header24 = () => {
    const { isOpen: isPFOpen, onOpen: onPFOpen, onClose: onPFClose } = useDisclosure();
    const { isOpen: isInfoOpen, onOpen: onInfoOpen, onClose: onInfoClose } = useDisclosure();

return (<><Flex as="header" p={4} borderBottomWidth="1px" justifyContent="space-between">
<VStack alignItems="flex-start">
<Heading as="h1" size="lg">
Math24 Solver
</Heading>
<Heading as="h2" size="sm" mt={2} fontWeight="normal">
Stumped? Get an answer!
</Heading>
</VStack>
<ButtonGroup flexDirection={{'base':'row','md':'row'}}>
<IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color="current"
      marginLeft={{'base':0,'md':2}}
      onClick={() => onInfoOpen()}
      icon={<FaInfoCircle />}
    />
<IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color="current"
      marginLeft={{'base':'0 !important','md':2}}
      onClick={() => onPFOpen()}
      icon={<FaComment />}
    />
<HomeButton marginLeft={{'base':'0 !important','md':2}}/>
<ColorModeSwitcher marginLeft={{'base':'0 !important','md':2}} />
</ButtonGroup>
</Flex>
<FeedbackModal 
        isOpen={isPFOpen}
        onClose={onPFClose}
        product="M24 Solver" />
      <InfoModal 
        isOpen={isInfoOpen}
        onClose={onInfoClose}
        infoObject={infoObject} />
</>
);

}
export default Header24;
import { useCallback, useContext } from 'react'
import { PickemContext } from '../context/PickemContext';
import { readPlayers } from '../database/players';
import { addPlayerToEvent, addPlayer, updatePlayer } from '../database/players';
import { useToast } from '@chakra-ui/react';

export const usePlayers = () => {
  const { state, dispatch } = useContext(PickemContext);
  const toast = useToast();

  const syncPlayers = useCallback(async () => {
    try {
      const data = await readPlayers()
      dispatch({ type: 'SET_PLAYERS', payload: data })
      //console.log('Players:', data)
    } catch (error) {
      console.log('error', error)
    }
  }, [dispatch])

  const joinEvent = ({player_id, user_id, event_id}) => {
    console.log('Joining event:', player_id, user_id, event_id);
    let new_player_id = player_id
    try {
      if(new_player_id === null) {
        //We need to add a player to the database, and grab the ID to use
        const new_player = addPlayer({user_id});
        new_player_id = new_player.id;
      }
      
      //Update the database
      const response = addPlayerToEvent({player_id: new_player_id, user_id, event_id});
  
      //Update the state
      dispatch({
        type: 'JOIN_EVENT',
        payload: {new_player_id, user_id, event_id}
      });
  } catch (error) {
      console.error('Error upserting picks', error);
      return { error };
  }
      toast({
        title: 'Welcome!',
        description: 'You have joined this Event!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return {response: 'Success'};


  }

  const updatePlayerName = ({player_id, player_name}) => {
    console.log('Updating player name:', player_id, player_name);
    try {
      //Update the database
      const response = updatePlayer({player_id, player_name});
  
      //Update the state
      dispatch({
        type: 'UPDATE_PLAYER_NAME',
        payload: {player_id, player_name}
      });
  } catch (error) {
      console.error('Error updating player name', error);
      return { error };
  }
      toast({
        title: 'Player Name Updated',
        description: 'Your player name has been updated!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return {response: 'Success'};
    }

  return {
    syncPlayers,
    joinEvent,
    updatePlayerName
  }
}

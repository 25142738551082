import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  UnorderedList,
  ListItem,
  Heading,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';
import axios from 'axios';
import {API_BASE_URL} from '../../api/constants';
import { servicesData } from '../data/services';
import {headerSizes, fontSizes, bigFontSizes, smallFontSizes, bigHeaderSizes, smallHeaderSizes} from '../../general/responsive';
import CalendlyButton from '../../general/components/CalendlyButton';
const ProductModal = ({
  productName,
  isOpen,
  onClose,
}) => {
  const bgColor = useColorModeValue("forwardWhite.100","forwardBlue.700");


    if(productName !== null) {

        const productData = servicesData.filter((service) => service.title === productName)[0];
        const modalData = productData.modalData;
        
        const hasChallenge = modalData.challenge && modalData.challenge !== '';
        const hasSolution = modalData.solution && modalData.solution !== '';
        const hasQualifications = modalData.qualifications && modalData.qualifications !== '';
        const hasDetails = modalData.details.length >= 1;

        return (
            <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={bgColor}>
                  <ModalHeader>{productName}
                  <Text fontWeight="medium" fontSize={fontSizes}>{modalData.header}</Text>
                  <Divider />
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                  {hasChallenge && <><Heading size={smallHeaderSizes} my={2}>The Challenge:</Heading><Text fontSize={smallFontSizes}>{modalData.challenge}</Text></>}
                  {hasSolution && <><Heading size={smallHeaderSizes} my={2}>Our Solution:</Heading><Text fontSize={smallFontSizes}>{modalData.solution}</Text></>}
                  {hasDetails && 
                  <><Heading size={smallHeaderSizes} my={2}>Details:</Heading>
                    <UnorderedList>
                    {modalData.details.map((detail, index) => (
                  <ListItem fontSize={smallFontSizes} key={index}>{detail}</ListItem>
                ))}
                    </UnorderedList>
                    </>}
                    {hasQualifications &&<><Heading size={smallHeaderSizes}  my={2}>Why Us:</Heading><Text fontSize={smallFontSizes}>{modalData.qualifications}</Text></>}
                    <Box m={4}>
                    <CalendlyButton url={modalData.url} buttonText={modalData.ctaText}/>
                    <Text textAlign="center" fontSize="xs" fontStyle="italic">{modalData.ctaSubtext}</Text>
                    </Box>
                    </ModalBody>
                  <ModalFooter>
                  </ModalFooter>
                </ModalContent>
            </Modal>
          );
    }
    else {
        return <></>;
    }
    //console.log(modalData.header);

  
};

export default ProductModal;
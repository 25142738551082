const infoObject = {
    title:'Math24 Solver',
    content: [
        {section:"The Essentials",
         text:"Math24 is a great way for kids to learn math! For those unfamiliar, the game is played with actual cards (you can find them online) with the goal of using \
         each of the four numbers to get to 24. You need to use each number once and only once and you can add, subtract, multiply, and divide."},
         {section:"Terms",
          text:"This solver is free is free to use and will continue to be hosted here barring any unforeseen catastrophies.\
           All current capabilities will remain free forever."},
          {section:"More Info",
          text:"Can be found here: [https://benmumma.notion.site/Math24-Solver-13de89e4d8b14215bf996ed6ccdd6d2f]"},
    ]
}



export {infoObject}

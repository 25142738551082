import {
    Box,
    Stack,
  } from "@chakra-ui/react";
import { FaRecycle } from "react-icons/fa";

const CustomSkipIcon = ({skipCount}) => (
    <Stack direction="row" alignItems="center">
      <FaRecycle />
      <Box>Re-Shuffles ({skipCount})</Box>
    </Stack>
  );
  
export default CustomSkipIcon;
import React from 'react';
import { Box, Heading, Image, Text, Center, VStack, Link, HStack, Flex, Button, ButtonGroup, UnorderedList, ListItem, useDisclosure } from '@chakra-ui/react';
import NewContactModal from '../../general/components/NewContactModal';
import MyHeader from '../../general/components/MyHeader';
import Navbar from '../../general/components/Navbar';
import { FaCog, FaEnvelope } from "react-icons/fa";
const God = (session) => {
  const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure();

  return (
    <>
    <MyHeader />
    <Navbar session={session}/>
    <Box width="100%" p={4}>
    <Center>
        <VStack margin={3}>
        <Heading as="h3" size="lg" mb={2}>God: The Democracy</Heading>
        <Heading as="h4" size="md" mb={1}>By: Ben Mumma</Heading>
        <Box maxWidth="600px">
        <Text fontStyle="italic" mb="12px">God: The Democracy is an in-progress novel that I've been working on, off and on, for several years. Here, check out the first couple chapters. If you like it, let me know and I'll be more likely to actually finish this book!</Text>
        <Heading as="h5" size="sm" my={2}>Chapter 1: Sam's Wild Ride</Heading>
<Text mb={1}>Driving along an open road, Sam Buckmore’s life was going well. At the age of 33, he had taken a respected but little-known activist from New York all the way to the halls of Congress. His phone was now ringing day and night with offers from several ‘next big things’ who wanted him as their campaign manager for the next set of races.</Text>
<Text mb={1}>Sam Buckmore was ready for the offers. Even he admitted that sometimes his ambition got the better of him – but he didn’t mind it at all. Politics was about manipulation, and Sam was good at it. He knew how to get the newly-elected congressmen to take the positions that he needed to win. He knew how to make the public see one man as good and the other as evil. He knew how to trap political opponents and force them to give the sound bite that he needed to sway the fickle public. All the people involved were just pieces on a chess board, and Buckmore was a master of chess of this kind.</Text>
<Text mb={1}>Buckmore was on the way back to New York, where he lived in the city. He was going home to his self-styled “bachelor’s pad” to enjoy his success and inevitable promotion. He would be hitting up the bars and clubs tonight, and definitely had no intention of returning to his pad alone. While he was getting a little old for the bachelor scene, Sam didn’t care, and still viewed marriage as a trap to be avoided. In his view of the chessboard it was like willingly giving up your queen at the start of the game. He didn’t want to be handicapped like that.</Text>
<Text mb={1}>As his trip continued, his mind continued to wander. He wondered which politician would give him the best job offer. Even though not on a team yet, he was already analyzing the playing field in his head. While it had been a quick start, he was convinced that with the right politician he could get them all the way to the White House. The public was so predictable: fear ruled them from both sides, and their virtuous goals could easily be played on. The only question was which virtuous goal would be the focus of the next election. </Text>
<Text mb={1}>Buckmore knew that he could count on acquaintances within the media to help him out with that (often unknowingly). The media was a great tool for shifting public desires. If it wasn’t in the news people would lose interest. If it was in the news, then people would care.</Text>
<Text mb={1}>As he was driving through the Pennsylvania countryside, Buckmore was glad that he did not have to worry about these people in the last election. I-78 traveled through some of the most rural parts that one could find. “Campaigning would have been a pain in the ass, we’d have seen more cows than people,” Buckmore thought as he drove by a pasture filled with cattle.</Text>
<Text mb={1}>Suddenly, as Sam was taking a sharp turn one of his tires blew out. He tried to swerve back onto the road, but without the front tire he wasn’t going to be able to avoid the steep bank just beyond the guardrail, nor the tree that he rammed into 30 feet below the surface of the road. As he hit the tree at 50 miles per hour, Buckmore’s last thought was “White House.”</Text>
    <Heading as="h5" size="sm" my={2}>Chapter 2: The Void</Heading>
    <Text mb={1}>Buckmore awoke with a start. He was in an elevator, classical music was playing, and he was wearing a hospital gown drinking a box of apple juice. Something wasn’t adding up. He waited rather impatiently for the doors to open. Five minutes went by until he decided to start pressing buttons. As he hit the “Open doors” button a new sight was revealed to him.</Text>
    <Text mb={1}>Buckmore stood in front of a great canyon. Perched on the edge stood two figures – one dressed in white the other in a dark brown.</Text>
    <Text mb={1}>“Hello Mr. Buckmore, it is good to see you,” the figure in white said sharply.</Text>
    <Text mb={1}>“Uh, you too, I guess. By the way, who are you and where the hell am I?” Buckmore replied.</Text>
    <Text mb={1}>“Heh, it’s funny that you should mention hell, I wouldn’t joke about that place quite yet. I am Bartholemew – you can call me Barty!” He had rapidly gone from his sharp welcome to a positively jovial mood as he finished his sentence. “And you are at the Abyss. It’s where we bring people like you.” He said now returning to a very dark tone. Sam didn’t know what to think.</Text>
    <Text mb={1}>“People like me? Political consultants?” </Text>
    <Text mb={1}>“Ha; you are actually one of the few political consultants who have made it here. For most of them, the decision is more… simple.” Barty hung on to that last word before replying. His tone had  become more serious.</Text>
    <Text mb={1}>“So, I’m dead, is that right? This is some sort of purgatory?”</Text>
    <Text mb={1}>“Well I thought we had established that already! You don’t remember hitting that tree I guess?”</Text>
    <Text mb={1}>Barty was back to being cheerful, and Sam was quickly considering whether Barty was neurotic. Without waiting for a response he continued, “So we are here to decide your fate. Jones here…” Barty motioned to a tall figure in brown robe that Sam had just noticed. He blended into the surroundings quite well. Barty continued, “…is from hell. I am from heaven. You scored in an intermediate range on our evaluation test, and so it is standard practice in these cases to bargain for your soul.”</Text>
    </Box>      
    <Text fontStyle="italic">That's all for now, want more? Let me know!</Text>
    <Button
      leftIcon={<FaEnvelope />}
      colorScheme="teal"
      variant="outline"
      onClick={onContactOpen}
      >
            <Text display={{ base: 'none', md: 'block' }}>Let me know!</Text>
      </Button>
      </VStack>
      </Center>
    </Box>
    <NewContactModal
      isOpen={isContactOpen}
      onClose={onContactClose}
      />
    </>
  ); 
};

export default God
import React, {useState} from 'react';
import { Box, Heading, Text, Center, VStack, 
  HStack, Flex, Button, ButtonGroup, SimpleGrid, Grid, GridItem, useColorModeValue, useDisclosure,
  Divider, Image,
  UnorderedList,
  ListItem
 } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import Footer from '../../general/components/Footer';
import {Link as ReachLink} from 'react-router-dom';
import CalendlyButtonModal from '../../general/components/CalendlyButtonModal';
import CompetencyBlock from '../content/CompetencyBlock';
import {saas_competencies} from '../data/saas_competencies';

const PriceOpt = () => {
    return (
        <>
            <MyHeader />
            <Navbar />
            <Box py={8} px={{ base: 4, md: 8 }}>
                <Center maxWidth="100%">
                    <VStack width="100%" maxWidth="800px">
                        <Text width="100%" textAlign="left" as={ReachLink} to="/consulting" color="blue.500">Back to Consulting</Text>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>The Client</Text>
                        <Text width="100%" textAlign="left" mt={2}>A publicly traded restaurant franchise with over 7,000 locations across the U.S.</Text>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>The Challenge</Text>
                        <Text width="100%" textAlign="left" mt={2}>The client had localized pricing driven by franchisees. Inflationary pressures were driving price increases that the client was worried would have negative long-term impact to the brand and the chain as a whole. However, analyzing the data was difficult to separate the causal impact of price changes from broader market trends. The client worried that their analysis and guidance was not influential to the franchisees who ultimately controlled the price of goods sold.</Text>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>What We Did</Text>
                        <UnorderedList width="100%" textAlign="left" mt={2}>
                            <ListItem>Developed an approach to generate a mix-neutral price index based on a store’s prices any given day, operationalizing the data pipeline to make daily data available immediately.</ListItem>
                            <ListItem>We then mined that data to identify level shifts in a store’s price index. These level shifts were then matched to control locations that shared similar characteristics but did not raise price.</ListItem>
                            <ListItem>From this, we were able to assess store-level elasticities with high confidence: given an X% price increase, how that flowed through to transactions, units per transaction, and cost per unit.</ListItem>
                            <ListItem>Using these analysis results, we were able to build a machine learning model that was able to reliably identify the types of stores where customers would respond better and worse to a pricing change.</ListItem>
                            <ListItem>Based on that analysis, we developed an approach for providing optimal price point guidance for stores, taking into account unique characteristics and then filtering those prices down to product-level recommendations.</ListItem>
                            <ListItem>Initial recommendations alone generated over $10MM in incremental profit opportunity for the chain.</ListItem>
                        </UnorderedList>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>Why It Mattered</Text>
                        <Text width="100%" textAlign="left" mt={2}>The client had a lot of low-hanging fruit to price more effectively: both by holding price in sensitive areas and by taking price in areas with more inelastic demand.</Text>
                        <Text width="100%" textAlign="left" mt={2}>Both the analysis and the solution were valued by the client: they ended up signing a multi-year license, which helped them continually assess pricing changes and offer targeted guidance that improved their credibility with their franchisees.</Text>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>What We Can Do For You</Text>
                        <Text width="100%" textAlign="left" mt={2}>Pricing analytics can be hard, but if the historical data is there, we can always leverage past variations to drive targeted recommendations on where to go. We can work with you to understand what data you have available, your current strategy, and then come up with a statement of work that delivers immediate value and establishes the necessary process to drive continuous improvement.</Text>
                    </VStack>
                </Center>
            </Box>
            <Footer />
        </>
    );
};

export default PriceOpt;

import React from 'react';
import { Box, Heading, Text, Flex, Button, ButtonGroup } from '@chakra-ui/react';
import Math24Solver from '../content/Math24Solver';
import Header24 from '../content/Header24';

const Math24 = () => {
  return (
    <>
    <Box py={8} px={{ base: 4, md: 8 }}>
      <Header24 />
      <Math24Solver />
    </Box>
    </>
  );
};

export default Math24;
import React, {useState} from 'react';
import { Box, Heading, Text, Center, VStack, 
  HStack, Flex, Button, ButtonGroup, SimpleGrid, Grid, GridItem, useColorModeValue, useDisclosure,
  Divider, Image,
  UnorderedList,
  ListItem
 } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import Footer from '../../general/components/Footer';
import {Link as ReachLink} from 'react-router-dom';
import CalendlyButtonModal from '../../general/components/CalendlyButtonModal';
import CompetencyBlock from '../content/CompetencyBlock';
import {saas_competencies} from '../data/saas_competencies';

const CsAttrition = () => {
    return (
        <>
            <MyHeader />
            <Navbar />
            <Box py={8} px={{ base: 4, md: 8 }}>
                <Center maxWidth="100%">
                    <VStack width="100%" maxWidth="800px">
                        <Text width="100%" textAlign="left" as={ReachLink} to="/consulting" color="blue.500">Back to Consulting</Text>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>The Client</Text>
                        <Text width="100%"  textAlign="left" >
                        APT was about a ~300-person SaaS company building analytic software that allowed large enterprise clients to better understand their business.
                        </Text>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >The Challenge</Text>
                        <Text width="100%" textAlign="left" >
                        APT had grown by 30-50% for several years, and was still struggling to adapt their client delivery model, and was seeing high attrition of new consultants, which led to even more pressure on the rest of the organization.
                        </Text>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >What We Did</Text>
                        <Text width="100%" textAlign="left" >
                        We developed a task force to assess the root problems and develop actionable recommendations to improve our delivery into a more sustainable and scalable pattern. We spent about two months as we:
                        </Text>
                        <UnorderedList>
                            <ListItem>interviewed over 40 team members across four corporate offices.</ListItem>
                            <ListItem>developed eight recommendations that were then implemented across the company.</ListItem>
                            <ListItem>spearheaded the development of a client services productivity team - a cross-functional team designed to invest in and automate away manual work.</ListItem>
                            <ListItem>led a POC to evaluate a client interaction portal to share documents and knowledge sharing between consultants and clients.</ListItem>
                            <ListItem>built consensus around several other organizational changes to improve the efficiency while keeping our standards for client success high.</ListItem>
                        </UnorderedList>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >Why It Mattered</Text>
                        <Text width="100%" textAlign="left">
                        APT implemented the changes and saw substantially lower attrition over the following 18 months, prior to acquisition by MasterCard. The changes implemented were key to ensuring that the company could continue to offer best-in-class offerings to our clients while we scaled.
                        </Text>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >What We Can Do For You</Text>
                        <Text width="100%" textAlign="left" >
                        Scaling your B2B Software company always comes with challenges. Mid-sized companies in particular need to start standardizing and considering process rather than just relying on hiring talented individuals. But not all processes are created equal! Care needs to be taken to ensure that the steps you take don’t erode the culture and ethos of the company.
                        </Text>
                        <Text width="100%" textAlign="left" >
                        We can work with you to assess your business and craft a tailored plan for how you can scale your client services organization. You’ll leave the engagement with:
                        </Text>
                        <UnorderedList>
                            <ListItem>A custom assessment of your business as it stands today.</ListItem>
                            <ListItem>A tailored plan of action that we create by collaborating with your key stakeholders.</ListItem>
                            <ListItem>Several training sessions for your team to help them deliver more value to clients and become more efficient in doing so.</ListItem>
                            <ListItem>A final set of recommendations, reviewed with all key stakeholders, to ensure alignment moving forward.</ListItem>
                        </UnorderedList>
                    </VStack>
                </Center>
            </Box>
            <Footer />
        </>
    );
};
export default CsAttrition;

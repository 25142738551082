
import React from 'react';
import { Box, Grid, Heading, Center, Text, VStack } from '@chakra-ui/react';
import ContentCard from './ContentCard';
import AppCard from './AppCard';

const Section = ({ title, subtitle, data, onCardClick, showImages, cardType, size, alignment }) => {

  const num_items = data.length;
  const base_col = 1;
  const sm_col = Math.min(2,num_items);
  /*const md_col = Math.max(Math.min(3,num_items),1);
  const lg_col = Math.max(Math.min(3,num_items),1);
  const xl_col = Math.max(Math.min(3,num_items),1);*/
  const md_col = 4;
  const lg_col = 4;
  const xl_col = 4;

  return (
    <Box mb={4} maxWidth="100%">
      <Center>
        <VStack mb={2}>
        <Heading as="h2" size="xl">
          {title}
        </Heading>
        {subtitle !== undefined && <Text fontStyle="italic">{subtitle}</Text>}
        </VStack>
      </Center>
      
      <Grid
       
        templateColumns={{
          base: 'repeat('+base_col+', 1fr)',
          sm: 'repeat('+sm_col+', 1fr)',
          md: 'repeat('+md_col+', 1fr)',
          lg: 'repeat('+lg_col+', 1fr)',
          xl: 'repeat('+xl_col+', 1fr)',
        }}
        gap={2}
        justifyContent={alignment}
      >
        {data.map((item, index) => {

          if (cardType==='regular') {
            return (<ContentCard key={index} {...item} onCardClick={onCardClick} cardName={item.title} showImages={showImages} action_callout={item.action_callout}/>)
          }
          else if (cardType==='app') {
            return (<AppCard key={index} {...item} onCardClick={onCardClick} cardName={item.title} showImages={showImages} action_callout={item.action_callout} size={size}/>)
          }
          return (<ContentCard key={index} {...item} onCardClick={onCardClick} cardName={item.title} showImages={showImages} action_callout={item.action_callout}/>)

})}
      </Grid>
    </Box>
  )
        };

  export default Section
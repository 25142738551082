import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link, HStack, VStack, Divider} from '@chakra-ui/react';
import JobCard from './JobCard';
import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes, smallFontSizes } from '../../general/responsive.js'

const Jobs = ({current, roles}) => {
  return (
    <Box padding={2} w="100%">
        <Heading as="h3" size={bigHeaderSizes} textAlign="center">Current</Heading>
        <VStack w="100%" justifyContent="center" spacing={4} py={1} alignItems="stretch">
            {current.map((role, index) => (
                <JobCard key={index}
                job_title={role.job_title}
                category={role.category}
                location={role.location}
                company={role.company}
                start_date={role.start_date}
                end_date={role.end_date}
                tenure={role.tenure}
                company_info={role.company_info}
                company_website={role.company_website}
                bullet_points={role.bullet_points}
              />
            ))}
        </VStack>
        <Heading as="h3" size={bigHeaderSizes} textAlign="center">Past Work</Heading>
        <VStack w="100%" justifyContent="center" spacing={4} py={1} alignItems="stretch">
            {roles.map((role, index) => (
                <JobCard key={index}
                job_title={role.job_title}
                category={role.category}
                location={role.location}
                company={role.company}
                start_date={role.start_date}
                end_date={role.end_date}
                tenure={role.tenure}
                company_info={role.company_info}
                company_website={role.company_website}
                bullet_points={role.bullet_points}
              />
            ))}
        </VStack>
        <Divider />
    </Box>
  )
}

export default Jobs;
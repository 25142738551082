import React from 'react';
import { Box , VStack, Text, useDisclosure} from '@chakra-ui/react';
import NewContactModal from './NewContactModal';


import { Link, Link as RouterLink } from 'react-router-dom';

const OpenForBusiness = ({ available }) => {
    const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure();
const trackCustomEvent = (eventName) => {
      if (window.plausible) {
        window.plausible(eventName);
      }
    };

const handleContactModalOpen = () => {
    trackCustomEvent('Contact Modal Open');
    onContactOpen();
}




    return (
        <Box width="100%">
            <VStack width="100%">
                <Box fontWeight="bold">Mumma Labs is:</Box>
                {available===true ? (
                    <Box 
                    style={{ background: 'linear-gradient(to right, #00cc00, #009900)', color: 'white', padding: '10px',  borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
                    onClick={handleContactModalOpen}
                    cursor="pointer"
                    >
                        <VStack>
                            <Text fontSize='18px'>Available for New Contracts</Text>
                            <Text cursor="pointer" onClick={onContactOpen}>Contact Ben</Text>
                        </VStack>
                    </Box>
                ) : (
                    <Box style={{ background: 'linear-gradient(to right, #ffd700, #ffcc00)', color: 'black', padding: '10px', fontSize: '24px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                        <VStack>
                        <Text fontSize='18px'>At Capacity for Contract Work</Text>
                        <Text fontSize="sm" cursor="pointer" onClick={onContactOpen}>Contact Ben anyway</Text>
                        </VStack>
                    </Box>
                )}
            </VStack>
            <NewContactModal
      isOpen={isContactOpen}
      onClose={onContactClose}
      />
        </Box>
        
    );
};

export default OpenForBusiness;

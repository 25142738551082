
import { supabase } from "../../../api/supabaseClient";

export async function readLeague() {
    let { data, error } = await supabase
        .from('nfl_leagues')
        .select(`
            *,
            nfl_events:nfl_events!inner (*)
        `)
    if (error) {
        console.log('error', error)
        throw error
    } else {
        return data
    }
}
import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const LatestUpdate = () => {
  return (
    <Box padding={4}>
    <Heading as="h2" size="md">The Highlights:</Heading>
      <Text as="i">Last update: January 2024</Text>
      <UnorderedList>
        <ListItem><b>Where are you?</b> I've been in the DFW area for the past 5+ years, now up in the Addison/Plano area.</ListItem>
        <ListItem><b>Who's around?</b> Anh and I got married back in July of 2021, and it's just us for now.</ListItem>
        <ListItem><b>What's happening?</b>
            <UnorderedList>
                <ListItem>Anh and I are both doing well, we just got back from a trip to Vietnam in February.</ListItem>
                <ListItem>I've rejoined Databricks as a Solution Architect! I am not taking any consulting gigs at the moment, but feel free to reach out if you'd like to discuss a project and I see who I could connect you with.</ListItem>
                <ListItem>I've built two web apps that I plan to keep around:
                  <UnorderedList>
                    <ListItem><Link as={RouterLink} color='teal.500' to="/forward">Forward</Link> to help people manage habits, projects, and goals all in one place.</ListItem>
                  <ListItem><Link as={RouterLink} color='teal.500' to="/intellectinbox">Intellect Inbox</Link> to provide people with high-quality daily educational lessons, focused on helping parents engage in educational topics with their kids.</ListItem>
                </UnorderedList>
                </ListItem>
                <ListItem>I'm preparing for my 13th full marathon: the Dallas Marathon here in December!</ListItem>
                <ListItem>I've got various other projects going on in the background, all highlighted on <Link as={RouterLink} color='teal.500' to="/">the Home Page</Link></ListItem>
            </UnorderedList>
        </ListItem>
      </UnorderedList>
    </Box>
  )
}

export default LatestUpdate;
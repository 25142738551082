import React, {useState} from 'react';
import { Box, Heading, Image, Text, Link, Center, VStack, HStack, Flex, Button, ButtonGroup, useColorModeValue, IconButton } from '@chakra-ui/react';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai'
import { case_studies } from '../data/case_studies';
import { Link as ReachLink } from 'react-router-dom';

const CaseStudyPromos = ({tn, hidden, category=null}) => {

    console.log(category);

    const [tNum, setTNum] = useState(tn);
    const bgColor = useColorModeValue("forwardWhite.100","forwardBlue.700");
    //const highlightColor = useColorModeValue("blue.700","teal.200")

    console.log(case_studies);

    let cases_to_show = case_studies;

    if (category !== null) {
        cases_to_show = case_studies.filter((acase) => acase.type === category);
    }

    

    console.log(cases_to_show);
    let n_cases = cases_to_show.length;
    const cs = cases_to_show[tNum];

    console.log(cs);


    function handle_prev() {
        if(tNum > 0) {
            setTNum(tNum-1);
        }
        else {
            setTNum(n_cases-1);
        }
    }

    function handle_next() {
        if(tNum === n_cases-1) {
            setTNum(0);
        }
        else {
            setTNum(tNum+1);
        }
    }

    if(hidden === 1) {
        return <></>;
    }

    
  return (
    <>
    <VStack maxWidth="800px">
    <Heading as="h3" mt={3}>What We've Done</Heading>
    <Box>
    {n_cases > 1 && <><Button variant="ghost" size="sm" m={2} onClick={handle_prev}><AiOutlineArrowLeft /> Previous</Button>
    <Button variant="ghost" size="sm" m={2} onClick={handle_next}>Next <AiOutlineArrowRight /></Button></>}
    </Box>
    <Box width="100%" bgColor={bgColor} p={4}  boxShadow="lg">
        <HStack flexDirection={{'base':'column','md':'row'}}>
        <Box p={2}><Image src={cs.image_url} minWidth="150px" maxWidth="150px" borderRadius="md" boxShadow="lg"></Image></Box>
        <Box>
        <Heading size="md">{cs.case_name}</Heading>
        <Text>{cs.description}</Text>
        <Link as={ReachLink} color="teal" to={cs.url}>Read More</Link>
        </Box>
        </HStack>
    </Box>
    </VStack>
    </>
  );
};

export default CaseStudyPromos
import React from 'react';
import { Box, Grid, Heading, Center } from '@chakra-ui/react';
import keyData from '../data/key';
import writingData from '../data/writing';
import appsData from '../data/apps';
import upcomingData from '../data/upcomingData';
import moreData from '../data/more';
import Section from '../../general/components/Section';


function ContentArea() {
  return (
    <Center>
    <Box py={8} px={{ base: 4, md: 8 }} maxWidth="1000px">
      <Section title="What We Do" data={keyData.filter((card) => card.hidden !== 1)} showImages={true} cardType="app" />
      <Section title="How We Think" data={writingData.filter((card) => card.hidden !== 1)} showImages={true} cardType="app" />
      <Section title="Other Projects" data={moreData.filter((card) => card.hidden !== 1)} showImages={true}  cardType="app" />
      <Section title="Free & Fun" data={appsData.filter((card) => card.hidden !== 1)} showImages={true} cardType="app"/>
      {/*<Section title="Upcoming Projects" data={upcomingData.filter((card) => card.hidden !== 1)} />*/}
    </Box>
    </Center>
  );
}

export default ContentArea;
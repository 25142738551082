
import { supabase } from "../../../api/supabaseClient";

export async function readEvent(event_id) {
    let { data, error } = await supabase
        .from('nfl_events')
        .select(`
            *,
            nfl_leagues:nfl_leagues!left (*),
            nfl_player_events:nfl_player_events!inner (*, nfl_players:nfl_players!left (*)),
            nfl_picks:nfl_picks!left (*)

        `)
        .eq('id', event_id)
        .eq('nfl_player_events.status', 1)
    if (error) {
        console.log('error', error)
        throw error
    } else {
        return data
    }
}
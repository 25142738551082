import React from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

const PauseOverlay = ({ isPaused, onUnpause }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    if (isPaused) {
      onOpen();
    } else {
      onClose();
    }
  }, [isPaused, onOpen, onClose]);

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="10"
          backgroundColor="rgba(0, 0, 0, 0.8)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{duration: 0.3 }}
          >
          <Button
                   size="lg"
                   colorScheme="blue"
                   onClick={onUnpause}
                 >
          Unpause
          </Button>
          </MotionBox>
          )}
          </AnimatePresence>
          );
          };
          
          export default PauseOverlay;
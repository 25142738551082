
import { supabase } from "../../../api/supabaseClient";

export async function readGames(season) {
    let { data, error } = await supabase
        .from('nfl_games')
        .select(`*`)
        .eq('season', season)
    if (error) {
        console.log('error', error)
    } else {
        return data
    }
}


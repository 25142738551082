import React from 'react';
import { Box, Hr, Badge, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link, HStack, Divider} from '@chakra-ui/react';
import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes, smallFontSizes } from '../../general/responsive.js'

const Hobbies = ({interests}) => {
  return (
    <Box padding={2} w="100%">
        <Heading as="h3" size="md" textAlign="center">Interests</Heading>
        {interests.map((interest, index) => (
                    <Badge p={1} m={1} borderRadius={2} colorScheme="blue" key={index} fontSize="xs">{interest}</Badge>
                ))}
        <Divider />
    </Box>
  )
}

export default Hobbies;
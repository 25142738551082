import {
    Box,
    Stack,
    Button,
    ButtonGroup,
    Flex
  } from "@chakra-ui/react";
import { FaRecycle } from "react-icons/fa";
import Score from "./Score";

const EndGameButtons = ({roundResults, scoringMode, calculateFinalScore, replay, repeat}) => (
    <Flex justifyContent="space-between" alignItems="center" width="100%" px="4" m="2">
        <Score roundResults={roundResults} scoringMode={scoringMode} calculateFinalScore={calculateFinalScore} />
        <ButtonGroup flexDirection = {{'base':'column','md':'row'}} spacing={{'base':0,'md':2}}>
        <Button onClick={replay} colorScheme="green" mb="2">
          Home
        </Button>
        <Button onClick={repeat} colorScheme="blue" mb="2">
          Replay
        </Button>
        <Button onClick={replay} colorScheme="gray" mb="2">
          Share
        </Button>
        </ButtonGroup>
      </Flex>
  );
  
export default EndGameButtons;
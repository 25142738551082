import { InlineWidget, PopupModal } from "react-calendly";
import React from 'react';
import {Button, Box} from '@chakra-ui/react';
class CalendlyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { url, buttonText } = this.props;

    const trackCustomEvent = (eventName) => {
      if (window.plausible) {
        window.plausible(eventName);
      }
    };

    const handleClick = () => {
      console.log('Schedule Button Clicked');
      trackCustomEvent('Schedule Button Clicked');
      this.setState({ isOpen: true })
    }



    return (
      <>
        <Button
            colorScheme = "teal"
            size="lg"
          style={{ display: "block", margin: "0 auto" }}
          onClick={handleClick}
        >
          {buttonText || "Schedule it now!"}
        </Button>
        {this.state.isOpen && (
          <Box>
            <InlineWidget
              url={url || 'https://calendly.com/benjaminmumma/intro'}
              pageSettings={this.props.pageSettings}
              utm={this.props.utm}
              prefill={this.props.prefill}
              styles={{
                height: '80vh',
                width: '80vw',
              }}
              my={2}
            />
            <Button onClick={() => this.setState({ isOpen: false })}>
              Close
            </Button>
          </Box>
        )}
      </>
    );
  }
}

export default CalendlyButton;
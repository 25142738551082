import React, {useState} from 'react';
import { Box, Heading, Text, Center, VStack, 
  HStack, Flex, Button, ButtonGroup, SimpleGrid, Grid, GridItem, useColorModeValue, useDisclosure,
  Divider, Image,
  UnorderedList,
  ListItem
 } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import Footer from '../../general/components/Footer';
import {Link as ReachLink} from 'react-router-dom';
import CalendlyButtonModal from '../../general/components/CalendlyButtonModal';
import CompetencyBlock from '../content/CompetencyBlock';
import {saas_competencies} from '../data/saas_competencies';
import CaseStudyPromos from '../content/CaseStudyPromos';
import Testimonials from '../content/Testimonials';
import OurServices from '../content/OurServices';
import ProductModal from '../content/ProductModal';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';

const ConsultingSaaS = () => {
    const { isOpen: isProductOpen, onOpen: onProductOpen, onClose: onProductClose } = useDisclosure();
    const [selectedCardName, setSelectedCardName] = useState(null);
    const handleCardClick = (cardName) => {
        const eventName = "Click on: "+cardName
        console.log(eventName);
        trackCustomEvent(eventName);
        setSelectedCardName(cardName);
        onProductOpen();
      }
    
    
    return (<>
        <MyHeader />
        <Navbar />
        <Box py={8} px={{ base: 4, md: 8 }}>
          <Center maxWidth="100%">
            <VStack width="100%" maxWidth="800px">
                <Text width="100%" textAlign="left" as={ReachLink} to="/consulting" color="blue.500">Back to Consulting</Text>
                <Heading as="h1" fontSize="3xl">Scaling B2B Customer Success</Heading>
                <Divider py={2} />
                <Text>
                    I've seen B2B SaaS companies from many different angles as they've experienced tremendous growth. I worked at APT as it expanded from 100 to 500 employees, and I worked at Databricks as it expanded from about 1,000 to over 4,000 employees.
                </Text>
                <Text>
                    Both of these companies did SaaS really well: building great products that customers need, developing high-performance sales teams, and building client services teams that were indispensable to customers.
                </Text>
                <Text>
                    I want to help your SaaS company succeed. As AI starts to proliferate in the SaaS world, many things will change, but the market will still reward excellence. Let's make it happen!
                </Text>
                <CalendlyButtonModal colorScheme="green" url={'https://calendly.com/benjaminmumma/intro'} text={'Schedule a 30 minute call'} />
                <Divider py={2} />
                <OurServices onCardClick={handleCardClick} category="SaaS Growth" />
                <CaseStudyPromos tn={0} hidden={0} category='SaaS Growth'/>
                <Divider py={2} />
                <Testimonials tn={0} hidden={0} category='SaaS Growth'/>
                <Divider py={2} />
                <Heading as="h3" size="md">Our Core Competencies</Heading>
                {saas_competencies && saas_competencies.map((competency, index) => (
                    <CompetencyBlock key={index} title={competency.cname} bulletPoints={competency.points} via_partners={competency.via_partners} />
                ))}

                <Divider py={2} />

        </VStack>
        </Center>
        </Box>
        <ProductModal productName={selectedCardName} isOpen={isProductOpen} onClose={onProductClose} />
        <Footer />
        </>
    );
};

export default ConsultingSaaS;

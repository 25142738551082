

const data_competencies = [
    {cname:'Data Strategy & Architecture',
    points:[
        'Improve an existing architecture to enable a new use case.',
        'Develop a ground-up architecture to modernize the business.',
        'Develop proof-of-concept solutions.'
    ]},
    {cname:'Analytic Services',
    points:[
        'We can provide value-add analysis services to your business.',
        'Whether you need analytics and dashboarding or full-blown machine learning and AI, we can help.',
    ]},
    {cname:'AI Integration Strategy',
    points:[
        'You may not be ready for AI, but the world doesn\'t care. We can help you get ready.',
        'We assess how AI could help your business, and create a roadmap to make that happen.',
    ]},
    {cname:'Application Development',
    points:[
        'We can work with your team to develop applications that leverage your data.',
        'We have partners who can help with any aspect of full-stack web or mobile development.'
    ]},
]
export {data_competencies};
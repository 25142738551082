
const { calc } = require("@chakra-ui/react");

function calc_correct_picks(pick, game, cp_override = null) {
    if(cp_override !== null) {
        return cp_override;
    }
    if(!pick || !game) {
        return 0;
    }
      
    let correct_picks = 0;
    if(
      (pick.pick === game.home_team && game.home_points > game.away_points) ||
      (pick.pick === game.away_team && game.away_points > game.home_points)) {
        correct_picks = 1;
      }
      else if (game.home_points + game.away_points <= 0) {
        correct_picks = 0.0000001;
      }
      else if (game.home_points === game.away_points) {
        correct_picks = 0.5;
      }

    return correct_picks;
}


function calc_is_first_key_pick(pick, nfl_picks) {
    //We need to check and see if any picks for player X have been made for team Y as a key game prior to this pick
    //If not, return true
    if(!pick.is_key) {
        return false;
    }
    const player_key_picks = nfl_picks.filter(p => p.player_id === pick.player_id).filter(p => p.week < pick.week).filter(p => p.is_key);
    const key_picks_as_team = player_key_picks.filter(p => p.pick === pick.pick);
    return key_picks_as_team.length === 0;
}
    

function calc_was_previous_pick_win(pick, nfl_picks, games, cp_override = null) {
    //We need to check and see if any picks for player X have been made for team Y and won
    let was_previous_pick_win = false;
    const player_team_prior_picks = nfl_picks.filter(p => p.player_id === pick.player_id).filter(p => p.week < pick.week).filter(p => p.pick === pick.pick);
    if(player_team_prior_picks.length === 0) {
        return false;
    }
    player_team_prior_picks.forEach(pick => {
        const game = games.find(g => g.season === pick.season && g.week === pick.week && (g.home_team === pick.pick || g.away_team === pick.pick));
        if(!game) {
            return;
        }
        const was_correct = calc_correct_picks(pick, game, cp_override);
        if(was_correct > 0) {
            was_previous_pick_win = true;
        }
    });
    return was_previous_pick_win;
}

function calc_is_underdog(pick, game) {
    // Based on game line: if line is negative, home team is favorite, positive = road team is favorite
    // If the pick is the underdog, return true
    const is_home_team = pick.pick === game.home_team;
    if (is_home_team) {
        return game.line > 0;
    }
    return game.line < 0;
}

function calc_underdog_points(pick, game, underdog_points, underdog_score, underdog_spread, underdog_limit, cp_override = null) {
    //console.log('Underdog Points:', pick, game, underdog_points, underdog_score, underdog_spread, underdog_limit)
    const was_correct = calc_correct_picks(pick, game, cp_override);
    if(was_correct < 1) {
        return 0;
    }
    const was_underdog = calc_is_underdog(pick, game);
    if(!was_underdog) {
        return 0;
    }
    const spread = Math.abs(game.line);
    const score_diff = Math.abs(game.home_points - game.away_points);
    const dog_points = underdog_points + (underdog_score * score_diff) + (underdog_spread * spread);
    if(dog_points > underdog_limit) {
        return underdog_limit;
    }
    return dog_points;

}

function calc_key_points(pick, game, is_unique_key_pick, key_points, key_unique, cp_override = null) {
    const was_correct = calc_correct_picks(pick, game, cp_override);
    if(was_correct < 1 || !pick.is_key) {
        return 0;
    }
    return key_points + (is_unique_key_pick ? key_unique : 0);
}

function calc_blowout_points(pick, game, blowout_threshold, blowout_points, cp_override = null) {
    const was_correct = calc_correct_picks(pick, game, cp_override);
    if(was_correct < 1) {
        return 0;
    }
    const score_diff = Math.abs(game.home_points - game.away_points);
    if(score_diff > blowout_threshold) {
        return blowout_points;
    }
    return 0;
}

function calc_ftw_points(pick, game, was_previous_pick_win, first_time_winner, cp_override = null) {
    const was_correct = calc_correct_picks(pick, game, cp_override);
    if(was_correct < 1) {
        return 0;
    }
    if(!was_previous_pick_win) {
        return first_time_winner;
    }
    return 0;
}

function calc_sov(pick, game, cp_override = null) {
    const was_correct = calc_correct_picks(pick, game, cp_override);
    if(was_correct < 1) {
        return -1*Math.abs(game.home_points - game.away_points);
    }
    return Math.abs(game.home_points - game.away_points);
}

function calc_key_sov(pick, game, cp_override = null) {
    if(!pick.is_key) {
        return 0;
    }
    const was_correct = calc_correct_picks(pick, game, cp_override);
    if(was_correct < 1) {
        return -1*Math.abs(game.home_points - game.away_points);
    }
    return Math.abs(game.home_points - game.away_points);
}

function calc_full_game_score(pick, game, configuration, nfl_picks, nfl_games, cp_override = null) {
    if(!pick || !game) {
        return 0
    }
    const correct_pick = calc_correct_picks(pick, game, cp_override);
    const sov = calc_sov(pick, game, cp_override);
    const key_sov = calc_key_sov(pick, game, cp_override);
    const ud_points = calc_underdog_points(pick, game, 
        configuration.scoring.underdog_base, 
        configuration.scoring.underdog_score, 
        configuration.scoring.underdog_spread, 
        configuration.scoring.underdog_limit, 
        cp_override);
    const key_points = calc_key_points(pick, game,
        calc_is_first_key_pick(pick, nfl_picks),
        configuration.scoring.key_base,
        configuration.scoring.key_unique, 
        cp_override);
    const blowout_points = calc_blowout_points(pick, game,
        configuration.scoring.blowout_threshold,
        configuration.scoring.blowout_points,
        cp_override);
    const ftw_points = calc_ftw_points(pick, game,
        calc_was_previous_pick_win(pick, nfl_picks, nfl_games),
        configuration.scoring.first_time_winner,
        cp_override);

    const multiplier = configuration.scoring.multipliers[game.week] || 1;
    const total_points = multiplier*((correct_pick * configuration.scoring.base)+ud_points+key_points+blowout_points+ftw_points);

    const return_data =  {
        correct_pick: correct_pick,
        sov: sov,
        key_sov: key_sov,
        underdog_points: ud_points,
        key_points: key_points,
        blowout_points: blowout_points,
        ftw_points: ftw_points,
        total_points: total_points,
    }

    //console.log('Full Game Score:', return_data);

    return return_data;
    


}

function get_week_color(score) {
    if(score <= 0) {
        return 'var(--chakra-colors-gray-300)';
    }
    else if(score < 20) {
        return 'var(--chakra-colors-red-300)';
    }
    else if(score < 30) {
        return 'var(--chakra-colors-orange-300)';
    }
    else if(score < 40) {
        return 'var(--chakra-colors-yellow-300)';
    }
    else if(score < 50) {
        return 'var(--chakra-colors-green-300)';
    }
    else if(score < 60) {
        return 'var(--chakra-colors-green-500)';
    }
    else if (score < 70) {
        return 'var(--chakra-colors-green-700)';
    }
    else if(score >= 70) {
        return 'var(--chakra-colors-green-900)';
    }
}


module.exports = {
    calc_correct_picks,
    calc_is_first_key_pick,
    calc_was_previous_pick_win,
    calc_underdog_points,
    calc_key_points,
    calc_blowout_points,
    calc_ftw_points,
    calc_sov,
    calc_key_sov,
    calc_full_game_score,
    get_week_color,
};
import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link, HStack, VStack, Divider, useColorModeValue, Stack} from '@chakra-ui/react';
 import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes } from '../../general/responsive.js'
const Skills = ({skills}) => {
  const cardBg = useColorModeValue('forwardWhite.100', 'forwardBlue.700');
  const cardBorder = useColorModeValue('forwardWhite.200', 'forwardBlue.600');
  const textColor = useColorModeValue('gray.800', 'gray.200');

  return (
    <Box padding={2} w="100%">
        <Heading as="h2" size={bigHeaderSizes} textAlign="center" my={2}>Skills</Heading>
            <Stack justifyContent="center" spacing={{'base':'1','md':'4'}} alignItems="stretch" flexDirection={{'base':'column','md':'row'}} className="print-rows">
        {skills.map((skillCategory) => (
                <Box
                borderWidth="1px"
                borderRadius="lg"
                borderColor={cardBorder}
                backgroundColor={cardBg}
                padding="6"
                boxShadow="lg"
                marginBottom={{'base':'2 !important','md':'0'}}
                marginTop="0"
                width="100%"
                key={skillCategory.category}
              >
            <Heading size={headerSizes}>{skillCategory.category}</Heading>
            <UnorderedList spacing="1" paddingLeft="4">
                {skillCategory.skills.map((skill) => (
                <ListItem fontSize={fontSizes} key={skill}>{skill}</ListItem>
                ))}
            </UnorderedList>
            </Box>
        ))}
        </Stack>
        <Divider mt={4} />
    </Box>
  )
}

export default Skills;
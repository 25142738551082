import { useCallback, useContext } from 'react'
import { readEvent } from '../database/events';
import { PickemContext } from '../context/PickemContext';

export const useEvents = () => {
  const { state, dispatch } = useContext(PickemContext);

  const syncEvent = useCallback(async (event_id) => {
    try {
      console.log('Event ID:', event_id)
      const data = await readEvent(event_id)
      dispatch({ type: 'SET_EVENT', payload: data[0] })
      //console.log('My Event:', data)
    } catch (error) {
      console.log('error', error)
    }
  }, [dispatch])

  return {
    syncEvent
  }
}
import React from 'react';
import { Box, Grid, Heading, Center } from '@chakra-ui/react';
import Section from '../../general/components/Section';
import {examplesData} from '../data/services';


function OurExamples() {

    return (
        <Section title="Interactive Examples" 
        subtitle="Feel free to explore some of the apps and systems that we have made that are publicly usable!" 
        data={examplesData} 
        showImages={true}
        cardType="app"
        />
    );
  }
  
  export default OurExamples;
const skills = [
    {
      category: 'Business Strategy',
      skills: [
        'Strategy Consulting',
        'Project and Team Leadership',
        'Change Management',
        'Communicating Analytics',
        'Cloud Cost Management',
        'Financial Analysis',
        'Pricing Strategy',
        'Knowledge Management',
      ]
    },
    {
        category: 'Data Science + Engineering',
        skills: [
          'Data Architecture',
          'Database Design',
          'Batch and Streaming Pipelines',
          'Supervised Learning',
          'A/B Testing',
          'Optimization',
          'Forecasting',
          'Reinforcement Learning',
          'Spark',
          'Machine Learning (ML)',
        ]
      },
      {
        category: 'Languages & Software',
        skills: [
          'Azure',
          'Databricks',
          'GCP',
          'PowerBI',
          'Tableau',
          'SQL',
          'Python',
          'Node + React',
          'Rest APIs',
          'Terraform',
        ]
      },
      
    // Add more objects as needed
  ];

  
  export {skills};
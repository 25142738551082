import React, { useState } from 'react';
import { Box, Heading, HStack, VStack, FormControl, FormLabel, Input, Button, Textarea, Text} from '@chakra-ui/react';
import axios from 'axios';
import {API_BASE_URL} from '../../api/constants';

function Math24Solver() {
  
  const [nums, setNums] = useState({ num1: '', num2: '', num3: '', num4: '' });
  const [solveValue, setSolveValue] = useState(24);
  const [solution, setSolution] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNums({ ...nums, [name]: value });
  };

  const trackCustomEvent = (eventName) => {
    if (window.plausible) {
      window.plausible(eventName);
    }
  };

  const handleSubmit = async (event) => {
    trackCustomEvent('Math 24 Solver Run');
    console.log(API_BASE_URL);
    event.preventDefault();
    console.log(nums);
    try {
      const response = await axios.post(API_BASE_URL+'m24/', { nums, solveValue });
      console.log(response);
      setSolution(response.data.solutions);
    } catch (err) {
      console.error(err);
    }
  };

  const num_font = {'base':'xl','md':'4xl'};

  return (
    <Box maxW="xl" mx="auto">
      <Heading as="h1" size="xl" textAlign="center" my={4}>Math 24 Solver</Heading>
      <Text textAlign="center" fontSize="sm" fontStyle="italic" my={4}>Just throw 4 numbers here and hit enter! We'll tell you how to get to 24 with basic operations.</Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
        <HStack spacing={4}>
          <FormControl>
            <FormLabel textAlign="center">Number 1</FormLabel>
            <Input type="text" variant="flushed" textAlign="center" height="80px" fontSize={num_font} pattern="^-?\d+(\.\d+)?(/\d+)?$" name="num1" value={nums.num1} onChange={handleChange} required />
          </FormControl>
          <FormControl>
            <FormLabel textAlign="center">Number 2</FormLabel>
            <Input type="text" variant="flushed" textAlign="center" height="80px" fontSize={num_font}  pattern="^-?\d+(\.\d+)?(/\d+)?$" name="num2" value={nums.num2} onChange={handleChange} required />
          </FormControl>
          <FormControl>
            <FormLabel textAlign="center">Number 3</FormLabel>
            <Input type="text" variant="flushed" textAlign="center" height="80px" fontSize={num_font}   pattern="^-?\d+(\.\d+)?(/\d+)?$" name="num3" value={nums.num3} onChange={handleChange} required />
          </FormControl>
          <FormControl>
            <FormLabel textAlign="center">Number 4</FormLabel>
            <Input type="text" variant="flushed" textAlign="center" height="80px" fontSize={num_font}  pattern="^-?\d+(\.\d+)?(/\d+)?$" name="num4" value={nums.num4} onChange={handleChange} required />
          </FormControl>
        </HStack>
        <HStack spacing={4}>
        <FormControl>
            <FormLabel textAlign="center">Solve Value</FormLabel>
            <Input type="number" variant="flushed" textAlign="center"  height="80px" fontSize="2xl"  name="solveValue" value={solveValue} onChange={(event) => setSolveValue(event.target.value)} required />
          </FormControl>
        </HStack>
        <Button width={{'base':'100%','md':'300px'}} size="lg" type="submit" colorScheme="teal">Solve</Button>
        </VStack>
      </form>
      {solution && (
        <Box mt={8}>
         {solution.length > 0 ? (
            <>
            <Heading as="h3" size="md">Solutions: </Heading>
                <Textarea
                    value={"There are "+solution.length+" solutions!\n"+solution.join('\n')}
                    isReadOnly
                    resize="none"
                    rows={solution.length+1}
                />
                </>
                ): "No Solutions, Sorry!"}
        </Box>
      )}
    </Box>
  );
}

export default Math24Solver;
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Heading,
  Box
} from '@chakra-ui/react';
import helpers from '../../general/calc/helpers'


const InfoModal = ({
  isOpen,
  onClose,
  infoObject,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{infoObject.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            {infoObject.content.map((part,index) => { 
                return(<Box key={index} mb={1}>
                <Heading size="lg">{part.section}</Heading>
                <Text>{part.text.split('\n').map((textLine, lineIndex) => (
            <React.Fragment key={lineIndex}>
              {helpers.parseTextWithLinks(textLine)}
              <br />
            </React.Fragment>
          ))}</Text>
                </Box>);
            })
        }
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onClose}>
            Cool
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
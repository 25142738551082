import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link, HStack, Divider, VStack, Badge, useColorModeValue} from '@chakra-ui/react';
import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes, smallFontSizes } from '../../general/responsive.js'

const EdCard = ({ degree, major, university, startDate, endDate, details }) => {
    const cardBg = useColorModeValue('forwardWhite.100', 'forwardBlue.700');
    const cardBorder = useColorModeValue('forwardWhite.200', 'forwardBlue.600');
    const textColor = useColorModeValue('gray.800', 'gray.200');
  
    return (
      <Flex
        borderWidth="1px"
        borderRadius="lg"
        borderColor={cardBorder}
        backgroundColor={cardBg}
        padding="4"
        boxShadow="lg"
        width="100%"
        marginBottom={{'base':'2 !important','md':'0'}}
      >
        <VStack alignItems="start" spacing="2">
          <HStack spacing="2">
            <Badge colorScheme="blue" fontSize={fontSizes}>
              {degree}
            </Badge>
            <Text fontSize={fontSizes} fontWeight="bold" color={textColor}>
              {major}
            </Text>
          </HStack>
          <Text fontSize={smallFontSizes} fontWeight="bold" color={textColor}>
              {university} - {endDate}
            </Text>
          <Text fontSize={smallFontSizes} color={textColor}>
            {details}
          </Text>
        </VStack>
      </Flex>
    );
  };
  
export default EdCard;
import React, {useState} from 'react';
import { Box, 
         VStack, 
         Flex, 
         Text, 
         Image, 
         useDisclosure, 
         ScaleFade, 
         LinkBox, 
         LinkOverlay, 
         HStack, 
         useMediaQuery,
         Spacer,
         useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

function AppCard({ title, cost, discounted, description, imageUrl, url, onCardClick, cardName, showImages, asNewWindow, internal, action_callout, size }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const bgColorBase = useColorModeValue('gray.100','gray.700');
    const bgColorHover = useColorModeValue('blue.100','blue.700');

    let pixelSize  = '160px';
    if (size === 'small') {
      pixelSize = '90px';
    }

    const linkProps = internal
    ? {
        as: Link,
        to: url,
        tabIndex: 0,
      }
    : {
        href: url,
        tabIndex: 0,
        target: asNewWindow ? '_blank' : null,
      };

    const handleMouseEnter = () => {
      onOpen();
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      onClose();
      setIsHovered(false);
    };

    const handleClick = (event) => {
      if (onCardClick) {
        event.preventDefault();
        onCardClick.onCardClick(cardName);
      }
    };

    let price_format = ''
    let cost_color = ''

    if (discounted !== undefined) {
      price_format = 'line-through';
      cost_color = 'gray.600'
    }
    let target_path = null;
    if (asNewWindow) {
      target_path = '_blank';
    }

  return (
    <VStack>
    <LinkBox
        as="article"
        borderWidth="1px"
        borderRadius="24px !important"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            <Box borderRadius="24px" height={pixelSize} width={pixelSize}>
            {showImages && <Image
          src={imageUrl}
          alt={title}
          height={pixelSize}
          objectFit="cover"
          transition="0.3s ease-in-out"
          boxShadow={isHovered ? '0 4px 6px rgba(70, 153, 255, 0.5)' : 'xl'}
          mx="auto"
          borderRadius="24px"
        />}
        </Box>
      <LinkOverlay {...linkProps} />
    </LinkBox>
    <VStack p={1} spacing={2} height="100%" width="100%" alignItems="stretch">
        
    {cost !== undefined && 
    <HStack width="100%" justifyContent="space-between">
      <Text textAlign="center" fontSize="xl" fontWeight="bold">{title}</Text>
      <VStack>
        <Text m={0} textAlign="right" color={cost_color} textDecoration={price_format}>{cost}</Text>
        {discounted !== undefined && <Text mt="0px !important" textAlign="right">{discounted}</Text>}
        </VStack>
        </HStack>}
    {cost === undefined && 
    <Flex width="100%" justifyContent="center">
      <Text  textAlign="center"  fontSize="xl" fontWeight="bold">{title}</Text>
      </Flex>}
    <Text fontStyle="italic" textAlign="center" fontSize="sm" noOfLines={3} width="100%">
      {description}
    </Text>
    <Spacer />
    {action_callout !== undefined && <HStack py={1} justifyContent="flex-end" width="100%">
      <Text textAlign="center" >{action_callout}</Text>
      <AiOutlineArrowRight />
      </HStack>}
  </VStack>
  </VStack>
  );
}

export default AppCard;

import { supabase } from "../../../api/supabaseClient";

export async function upsertPicks(picks) {
    if (!Array.isArray(picks) || picks.length === 0) {
        throw new Error('Invalid picks: must be a non-empty array');
    }

    const { player_id, event_id, season, week } = picks[0];

    if (!player_id || !event_id || !season || !week) {
        throw new Error('Invalid pick: missing required fields');
    }

    // Validate all picks have the same player_id, event_id, season, and week
    if (!picks.every(pick => 
        pick.player_id === player_id &&
        pick.event_id === event_id &&
        pick.season === season &&
        pick.week === week
    )) {
        throw new Error('All picks must have the same player_id, event_id, season, and week');
    }

    try {
        // Delete existing picks
        const { error: deleteError } = await supabase
            .from('nfl_picks')
            .delete()
            .eq('player_id', player_id)
            .eq('event_id', event_id)
            .eq('season', season)
            .eq('week', week);

        if (deleteError) throw deleteError;

        // Insert new picks
        const { data: insertData, error: insertError } = await supabase
            .from('nfl_picks')
            .insert(picks);

        if (insertError) throw insertError;

        console.log('Upserted picks:', insertData);
        return insertData;
    } catch (error) {
        console.error('Error upserting picks:', error);
        throw new Error(`Failed to upsert picks: ${error.message}`);
    }
}
import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem } from '@chakra-ui/react';

const Life = () => {
  return (
    <Box padding={4}>
        <Heading as="h3" size="md">Life</Heading>
        <UnorderedList>
            <ListItem>Anh and I are both doing well here in the DFW area.</ListItem>
            <ListItem>Recent trips include Switzerland, Tahoe/Yosemite, Vietnam, Delaware Beach Week. This fall/winter we mostly have trips to see family in NC, VA, MD, and PA.</ListItem>
        </UnorderedList>
    </Box>
  )
}

export default Life;
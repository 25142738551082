const keyData = [
    {
      title: 'Forward',
      description: 'A one-stop life management platform!',
      imageUrl: '/home/forward_logo_v1_min.png',
      url: 'https://www.theforwardapp.com',
      internal: false,
    },
    {
      title: 'Intellect Inbox',
      description: 'Learn Anything Now with AI!',
      imageUrl: '/home/ii-v1.png',
      url: 'https://www.intellect.email',
      internal:false,
    },
    {
      title: 'Consulting Services',
      description: 'Data, Analytics & AI Expertise',
      imageUrl: '/home/m_consulting_2.png',
      url: './consulting',
      internal:true,
    },
   /* {
        title: 'Glacio',
        description: 'Private and Secure Data Storage',
        imageUrl: '/home/Glacio-Icon-ColorFill_v2.png',
        url: 'https://glacio.herokuapp.com',
        hidden: 1,
        internal:false,
    },
    {
        title: '5to9 Events',
        description: 'The best way to plan your next event!',
        imageUrl: '/home/5to9-low-res.png',
        url: '',
        hidden: 1,
        internal:false,
    },*/
    
    // Add more objects as needed
  ];
  
  export default keyData;
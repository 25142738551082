//Core React
import React, { useState, useEffect, useRef } from "react";
//UI and Icons
import {
    Box,
    Button,
    Heading,
    Input,
    Text,
    Stack,
    VStack,
    IconButton,
    ButtonGroup,
    Flex,
    Link,
    Alert, AlertIcon, AlertTitle, AlertDescription, useColorModeValue
  } from "@chakra-ui/react";

//Components
import RoundResult from '../content/RoundResult.jsx';

import StartScreen from './StartScreen.jsx';
import WordInput from "../content/WordInput.jsx";
import WordInputButtons from "../content/WordInputButtons.jsx";
import Timer from "../content/Timer.jsx";
import LiveScore from "../content/LiveScore.jsx";
import RoundCounter from "../content/RoundCounter.jsx";

import EndGameButtons from "../content/EndGameButtons.jsx";
import GameHeader from "../content/GameHeader.jsx";
import PauseOverlay from "../content/PauseOverlay.jsx";
//Functions
import {calculateFinalScore, calculateRoundScore, wordExists, validateWord } from '../calc/core.js';
import {saveGameHistory, loadGameHistory} from '../calc/statistics.js';
import { generateLetters } from "../calc/letterGeneration.js";

//Custom Hooks
import  useGameTimer from "../hooks/useGameTimer"

const Expound = props => {
    /***************************** */
    /* Set State */
    /**************************** */
    /* Game Settings */
    const [playingMode, setPlayingMode] = useState({});
    const [letterCount, setLetterCount] = useState(3);
    const [gameHistory, setGameHistory] = useState(loadGameHistory());
    const [gameDifficulty, setGameDifficulty] = useState(null);
    const [gameMode, setGameMode] = useState(null);
    const [gameId, setGameId] = useState(null);
    const [gameProgression, setGameProgression] = useState(null)
    const [enduranceMode, setEnduranceMode] = useState(false);
    const [hasPresetRounds, setHasPresetRounds] = useState(false);
    const [presetList, setPresetList] = useState([]);
    const [visualIsPaused, setVisualIsPaused] = useState(false);

    /* Overall State */
    const [gameState, setGameState] = useState('start');
    const [roundResults, setRoundResults] = useState([]);
    const [currentRound, setCurrentRound] = useState(0);
    const [currentScore, setCurrentScore] = useState(0);
    const [totalRounds, setTotalRounds] = useState(1);
    const [scoringMode, setScoringMode] = useState('');
    const [usedWords, setUsedWords] = useState([]);
    const [banner, setBanner] = useState({ status: "", message: "" });
    const [recentScore, setRecentScore] = useState(0);
    const [hasTimer, setHasTimer] = useState(true);
    const [minLength, setMinLength] = useState(4);
    const [maxLength, setMaxLength] = useState(null);

    /* Base Game State - every round */
    const [input, setInput] = useState('');
    const [message, setMessage] = useState('');
    const [letters, setLetters] = useState(generateLetters(letterCount, hasPresetRounds, presetList, currentRound));
    const [showWarning, setShowWarning] = useState(false);
    
    /* Timer Variables */
    const [duration, setDuration] = useState(0);
    const [skipCount, setSkipCount] = useState(0);
    const {
      remainingTime,
      paused,
      totalTimeUsed,
      startTimer,
      pauseGame,
      unpauseGame,
      resetTimer,
      setTimer,
      getTotalTimeUsed,
    } = useGameTimer(duration);

    const trackCustomEvent = (eventName, props) => {
      if (window.plausible) {
        const options = {props:props};
        console.log(options);
        window.plausible(eventName, options);
      }
    };

    /**********************************************/
    /* Use Effects */
    /**********************************************/
    //Generate the letters upon a new round or change in letter count
    useEffect(() => {
        setLetters(generateLetters(letterCount, hasPresetRounds, presetList, currentRound));
      }, [letterCount, currentRound]);

    //Set state to game over if all rounds are complete
    useEffect(() => {
        if (roundResults.length === totalRounds) {
            setGameState('gameOver')
        }
      }, [roundResults]);

      //Handle a Skip
      useEffect(() => {
        if (gameState === 'inProgress' || gameState ==='use_skip') {
          if (gameState === 'use_skip') {
            setLetters(generateLetters(letterCount, hasPresetRounds, presetList, currentRound));
            setGameState('inProgress');
          }
        }
      }, [gameState]);

      //Expire the Timer
      useEffect(() => {
        if (gameState === 'inProgress' && remainingTime <= 0) {
          console.log('Timer is expiring!');
          setGameState('timer_expired');
        }
      }, [remainingTime]);

      //Various Game State Transitions and Handlers
      useEffect(() => {
        if (gameState === 'gameOver') {
          //Reset Certain States
          setLetterCount(3);
          setHasPresetRounds(false);
          setPresetList([]);
          setHasTimer(false);

          const finalScore = calculateFinalScore(roundResults);
          setBanner({ status: "success", message: `Good Game John! Click "replay" below to play again!` });
          const newGameHistory = [...gameHistory, {gameId, totalRounds, gameMode, scoringMode, gameDifficulty, roundResults, finalScore }];
          setGameHistory(newGameHistory);
          // Save the updated game history to local storage
          saveGameHistory(newGameHistory);
          setCurrentRound(0);
          setDuration(999999);
          pauseGame();
          console.log(gameHistory);
          //Report the scores:
          const eventName = 'Expound Game Ended';
          console.log(eventName);
          const props = {'mode': gameMode, 'score':finalScore, 'mode-score':gameMode+' '+finalScore.toString()};
          trackCustomEvent(eventName,props);
        }
        if (gameState ==='timer_expired') {
          console.log('Timer Expired!');
          handleSubmit();
        }
        if (gameState ==='start') {
          setBanner({status:"", message: ``});
          pauseGame();
        }
        if (gameState === 'paused') {
            console.log('paused');
            pauseGame();
            setVisualIsPaused(true);
        }
        if (gameState === 'unpausing') {
            console.log('unpaused');
            unpauseGame();
            setVisualIsPaused(false);
        }
      }, [gameState]);

      /**********************************************/
      /**********************************************/
      /* Main Game Functions */
      /**********************************************/
      /**********************************************/
      function startGame(selectedMode) {
        /* Overall State */
        console.log('Starting Game! Modes:');
        console.log(selectedMode);
        setPlayingMode(selectedMode);
        setGameState('inProgress');
        setGameMode(selectedMode.name);
        setGameId(selectedMode.id);
        setGameProgression(selectedMode.settings.progression);
        setHasPresetRounds(selectedMode.settings.hasPresetRounds);
        setPresetList(selectedMode.settings.presetRounds);
        setCurrentRound(1);
        setCurrentScore(0);
        setRecentScore(0);
        setSkipCount(selectedMode.settings.skips);
        setDuration(selectedMode.settings.duration);
        setRoundResults([]);
        setUsedWords([]);
        setTotalRounds(selectedMode.settings.progression.length);
        setLetterCount(selectedMode.settings.progression[0]);
        setEnduranceMode(selectedMode.name === 'Endurance');
        setHasTimer(selectedMode.settings.hasTimer);
        setMinLength(selectedMode.settings.minLength);
        setMaxLength(selectedMode.settings.maxLength);
        
        /* Base Game State - every round */
        setLetters(generateLetters(letterCount, hasPresetRounds, presetList, currentRound));
        setInput("");
        setMessage("");
        setShowWarning(false);
        resetTimer();

        /* Scoring Variables */
        setScoringMode(selectedMode.settings.scoringMode);

        setTimer(selectedMode.settings.duration);
        startTimer();
      }
    
      function replay() {
        setGameState('start');
      }

      function repeat() {
        startGame(playingMode);
      }

      const handleSkip = () => {
        if (skipCount > 0) {
          setGameState('use_skip');
          setSkipCount(skipCount - 1);
          resetTimer();
          startTimer();
        }
      };

    /**********************************************/
    /* Timer and Submit Funcs */
    /**********************************************/
    const pauseHandler = () => {
      console.log('Pausing!');
      setGameState('paused');
    };

    const unpauseHandler = () => {
      console.log('Unpausing!');
      setGameState('unpausing');
      
    };
    /**********************************************/
    /* Main Input Submission */
    /**********************************************/
  async function handleSubmit(event) {
    if (event) { 
        event.preventDefault();
    }
    console.log(gameState);
    const inputLowerCase = input.toLowerCase();
    const minLengthAllowed = Math.max(minLength,letters.length+1); // Replace X with the desired minimum length
    const maxLengthAllowed = Math.min(maxLength === null ? 50 : maxLength,50);

    if (gameState !== 'timer_expired' && input.length > 0) {
    //Check base rules, and prevent submission in these cases:

      // Rule 1: Check Letter Length
    if (input.length < minLengthAllowed) {
      setBanner({ status: "warning", message: `No dice! The word must be at least ${minLengthAllowed} letters long.` });
      return;
    }
    if (input.length > maxLengthAllowed) {
      setBanner({ status: "warning", message: `No dice! The word must be less than ${maxLengthAllowed} letters long.` });
      return;
    }
      // Rule 2: Check if the word has already been used in the current round
      const inputLowerCase = input.toLowerCase();
      const containsUsedWord = usedWords.some((usedWord) => inputLowerCase.includes(usedWord.toLowerCase()));
      if (containsUsedWord) {
        setBanner({ status: "warning", message: "Whoopsie! This word has already been used." });
        return;
      }
    }
        
    const validationResults = await validateWord(letters, inputLowerCase);
    const isCorrect = validationResults['value'];
    const isWord = validationResults['isWord'];

    if (gameState !== 'timer_expired' && !isWord) {
      setBanner({status:"warning", message: `That's not a word, bro! Try again!`});
      return;
    }

    if(gameState !== 'timer_expired') {
    // If the input passes the rules, add the word to the usedWords set
    setUsedWords([...usedWords, input.toLowerCase()]);
    }
    //Reset the warning and clear the timer interval
    if(hasTimer) {
    }
    setShowWarning(false);
    setBanner({ status: "", message: `` });
    
    console.log(getTotalTimeUsed());
    const score = calculateRoundScore(isCorrect, input, getTotalTimeUsed(), scoringMode,letters, minLengthAllowed, maxLengthAllowed);
    setRecentScore(score);

    setCurrentScore(currentScore + score);
    setRoundResults((prevResults) => [
      ...prevResults,
      {
        key: currentRound,
        round: currentRound,
        letters: letters,
        word: input,
        valid: isCorrect,
        timeTaken: getTotalTimeUsed(),
        score: score
      },
    ]);

    if (currentRound < totalRounds) {
      if(isCorrect<=0 && enduranceMode) {
        //Immediately end game
        setGameState('gameOver');
        console.log('EM: Game Ended!');
        
      }
      else {
        setGameState('inProgress');
        setMessage(isCorrect ? "Great job!" : "Try again!");
        setCurrentRound((prevRound) => prevRound + 1);
        setLetterCount(gameProgression[currentRound]);
        setLetters(generateLetters(letterCount, hasPresetRounds, presetList, currentRound));
        setInput("");
        resetTimer();
        setTimer(duration);
        startTimer();
      }

    } else {
      //This is when the game ends!
      console.log('Game Ended!');
      setGameState('gameOver');
      //setRoundState('none');
      
    }
}

  /**********************************************/
  /* Main Displays */
  /**********************************************/
  /**********************************************/
  /* Show Start Screen if the state is "start" */
  /**********************************************/
  const header_color = useColorModeValue("gray.100","gray.700");
  if (gameState === 'start') {
    //console.log(gameMode);
    //setLetterCount(3);
    return (
      <Box p={{'base':'3','md':'8'}} maxWidth="container.md" mx="auto">
        <GameHeader />
        <StartScreen onStartGame={startGame} gameMode={gameMode}/>
      </Box>
    );
  }
  
/**********************************************/
  /* Else: Return the main app */
/**********************************************/

  return (
    <Box p={{'base':'3','md':'8'}} maxWidth="container.md" mx="auto" position="relative">
      <GameHeader />
      <PauseOverlay isPaused={visualIsPaused} onUnpause={unpauseHandler} />
      <Flex justifyContent="space-between" alignItems="center" width="100%" px="4" bg={header_color} shadow="md">
        <RoundCounter currentRound={currentRound} totalRounds={totalRounds} gameMode={gameMode} gameState={gameState} />
        <LiveScore score={currentScore} recent_addition={recentScore} currentRound={currentRound}/>
        <Timer hasTimer={hasTimer} remainingTime={remainingTime} />
      </Flex>
      <Box mt={2} h="60px">
      {banner.status && (
          <Alert status={banner.status} mt="0" borderRadius="md" width="100%" py="2" textAlign="center" boxShadow="md">
            <AlertIcon />
            <AlertDescription>{banner.message}</AlertDescription>
          </Alert>
        )}
        </Box>
         {gameState === 'gameOver' && (
          <EndGameButtons roundResults={roundResults} scoringMode={scoringMode} calculateFinalScore={calculateFinalScore} replay={replay} repeat={repeat} />
      )}
      {gameState !== 'gameOver' && (
        <Flex flexDirection="column" alignItems="center">
        <Box width="80%">
          <WordInput letters={letters} onChange={(value) => setInput(value)} input={input} setInput={setInput} onSubmit={handleSubmit}/>      
        </Box>
        <WordInputButtons gameState={gameState} setGameState={setGameState} 
                          handleSubmit={handleSubmit} handleSkip={handleSkip} 
                          skipCount={skipCount} pauseHandler={pauseHandler} 
                          unpauseHandler={unpauseHandler} />
      </Flex>
      )}
     
      <VStack spacing="2" alignItems="start">
        {roundResults.slice().reverse().map((result) => (
          <RoundResult
            key={result.round}
            round={result.round}
            letters={result.letters}
            word={result.word}
            valid={result.valid}
            timeTaken={result.timeTaken}
            score={result.score}
          />
        ))}
      </VStack>      
    </Box>
  );
}
  export default Expound;
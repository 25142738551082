import { Box, Heading, UnorderedList, ListItem, useColorModeValue, Text, HStack } from "@chakra-ui/react";

const CompetencyBlock = ({ title, bulletPoints, via_partners }) => {

    const bgColor = useColorModeValue("forwardWhite.100","forwardBlue.700");
    return (
        <Box bg={bgColor} p={4} borderRadius="md" width="100%">
            <HStack>
            <Heading as="h2" size="md" mb={2}>
                {title}
            </Heading>
            {via_partners && <Text fontSize="xs" fontStyle="oblique">via our consulting partners</Text>}
            </HStack>
            <UnorderedList>
                {bulletPoints && bulletPoints.map((point, index) => (
                    <ListItem key={index}>{point}</ListItem>
                ))}
            </UnorderedList>
        </Box>
    );
};

export default CompetencyBlock;

const moreData = [
  {
    title: 'NFL Pick\'em',
    description: 'A home for NFL Pick\'em leagues, including my own!',
    imageUrl: '/home/nfl_picks.png',
    url: '/pickem',
    internal: true,
  },
  {
    title: 'MRR Growth Calculator',
    description: 'Use this tool to assess how retention and growth rates impact your recurring revenue',
    imageUrl: '/home/mrr.png',
    url: './mrr',
    internal: true,
  },
  {
    title: 'NFL Machine Learning',
    description: 'Get the latest NFL picks from my machine learning model!',
    url: 'https://mummapicks.substack.com',
    imageUrl: '/home/nfl_picks.png',
    asNewWindow:true,
  },
    {
      title: 'Notion Templates',
      description: 'Templates to use in your Notion environment',
      imageUrl: '/home/notion-templates.jpg',
      url: 'https://productivityinnotion.gumroad.com',
      asNewWindow:true,
    },
    // Add more objects as needed
  ];
  
  export default moreData;
import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Badge,
  UnorderedList,
  ListItem,
  Link,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes, smallFontSizes } from '../../general/responsive.js'


const JobCard = ({
  job_title,
  category,
  location,
  company,
  start_date,
  end_date,
  tenure,
  company_info,
  company_website,
  bullet_points,
}) => {
  const cardBg = useColorModeValue('forwardWhite.100', 'forwardBlue.700');
  const cardBorder = useColorModeValue('forwardWhite.200', 'forwardBlue.600');
  const textColor = useColorModeValue('gray.800', 'gray.200');

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      borderColor={cardBorder}
      backgroundColor={cardBg}
      padding="4"
      boxShadow="lg"
      marginBottom="0"
      width="100%"
    >
      <Flex justifyContent="space-between" alignItems="start" flexDirection={{'base':'column','md':'row'}}>
        <VStack alignItems="start" spacing="0">
          <HStack spacing="1">
            <Badge colorScheme="blue" fontSize="sm" whiteSpace="normal">
              {job_title}
            </Badge>
            <Flex display="flex" flexDirection="row">
              <Text mr={1} display={{'base':'none','md':'inline'}}>@ </Text><Text fontSize="md" fontWeight="bold" color={textColor}>{company}
            </Text>
            </Flex>
          </HStack>
          <Text fontSize="sm" color={textColor} fontStyle="italic">
            {company_info}
          </Text>
          {company_website && (
            <Link fontSize="sm" color="teal.500" href={company_website} isExternal>
              {company_website}
            </Link>
          )}
          </VStack>
          <VStack alignItems={{'base':'start','md':"end"}} justifyContent="space-between" spacing="0" flexDirection={{'base':'row','md':'column'}} width={{'base':'100%','md':'auto'}}>
          <Text fontSize="sm" fontWeight="semibold" color={textColor}>
            {start_date} - {end_date}</Text><Text display={{'base':'none','md':'inline'}}>({tenure})</Text>
          
          <Text fontSize="sm" color={textColor}>
            {location}
          </Text>
        </VStack>
          </Flex>
          <UnorderedList spacing="0" paddingLeft="6" marginLeft={0}>
            {bullet_points.map((point, index) => (
              <ListItem key={index} fontSize="sm" color={textColor}>
                {point.split(' ').map((word, i) => {
                  const punctuation = ['.', ',', ';', ':', '!', '?'];
                  let displayWord = word;
                  let linkWord = word;
                  if (punctuation.includes(word[word.length - 1])) {
                    displayWord = word;
                    linkWord = word.slice(0, -1);
                  }
                  return word.includes('www.') ? <Link color="teal.500" key={i} href={'https://'+linkWord} isExternal>{displayWord}</Link> : word + ' ';
                })}
              </ListItem>
            ))}
          </UnorderedList>
    </Box>
  );
};
export default JobCard;

import React, {useState} from 'react';
import { Box, Heading, Text, Center, VStack, 
  HStack, Flex, Button, ButtonGroup, SimpleGrid, Grid, GridItem, useColorModeValue, useDisclosure,
  Divider, Image,
  UnorderedList,
  ListItem
 } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import Footer from '../../general/components/Footer';
import {Link as ReachLink} from 'react-router-dom';
import CalendlyButtonModal from '../../general/components/CalendlyButtonModal';
import CompetencyBlock from '../content/CompetencyBlock';
import {saas_competencies} from '../data/saas_competencies';

const B2BGrowth = () => {
    return (
        <>
            <MyHeader />
            <Navbar />
            <Box py={8} px={{ base: 4, md: 8 }}>
                <Center maxWidth="100%">
                    <VStack width="100%" maxWidth="800px">
                        <Text width="100%" textAlign="left" as={ReachLink} to="/consulting" color="blue.500">Back to Consulting</Text>
                        <Text width="100%" textAlign="left" as="h2" fontSize="xl" fontWeight="bold" mt={4}>The Client</Text>
                        <Text width="100%"  textAlign="left" >
                            A ~50-person tech startup about to come out of Stealth Mode.
                        </Text>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >The Challenge</Text>
                        <Text width="100%" textAlign="left" >
                            The team had done tremendous work to grow the business to several million dollars in sales, but was concerned with their ability to scale their client engagements as they prepared for their launch.
                        </Text>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >What We Did</Text>
                        <Text width="100%" textAlign="left" >
                            We worked with their team closely over a couple months to:
                        </Text>
                        <UnorderedList>
                            <ListItem>Understand the current state of the business: value proposition, challenges, successes, gaps, and priorities.</ListItem>
                            <ListItem>Assess the client services team in particular to understand how time was being spent, how clients were engaged, and how deliverables were being produced.</ListItem>
                            <ListItem>Based on that work, we adapted best practices from larger and more mature client services organizations at larger tech companies and led several presentations to share those with the team and discuss how they could be implemented.</ListItem>
                            <ListItem>Provide a data-driven assessment of the client’s value proposition for their clients, leveraging actual data from their client engagements.</ListItem>
                            <ListItem>Provided finalized document of key recommendations and steps to take moving forward as they prepared for launch.</ListItem>
                        </UnorderedList>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >Why It Mattered</Text>
                        <Text width="100%" textAlign="left">
                            The client was able to leverage the recommendations to improve their delivery motion with their existing client base, and become a bit more confident in their ability to deliver as they headed toward launch. Additionally, the value proposition analysis was instrumental in building a better relationship with one of their flagship clients.
                        </Text>
                        <Text width="100%" as="h2" fontSize="xl" fontWeight="bold" mt={4} textAlign="left" >What We Can Do For You</Text>
                        <Text width="100%" textAlign="left" >
                            We have all the resources available to help you improve how you deliver your software to clients. In as little as one month, we can work with you to assess your current positioning and develop a tailored path forward to drive remarkable improvement in your delivery motion. You’ll leave the engagement with:
                        </Text>
                        <UnorderedList>
                            <ListItem>A custom assessment of your business as it stands today.</ListItem>
                            <ListItem>Several training sessions for your team to help them deliver more value to clients and become more efficient in doing so.</ListItem>
                            <ListItem>A final set of recommendations, reviewed with all key stakeholders, to ensure alignment moving forward.</ListItem>
                        </UnorderedList>
                    </VStack>
                </Center>
            </Box>
            <Footer />
        </>
    );
};
export default B2BGrowth;

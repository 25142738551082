const letterFrequencies = {
    A:8.167, 
    B:1.492, 
    C:2.782, 
    D:4.253, 
    E:12.702, 
    F:2.228, 
    G:2.015, 
    H:6.094, 
    I:6.966, 
    J:0.153, 
    K:0.772, 
    L:4.025, 
    M:2.406, 
    N:6.749, 
    O:7.507, 
    P:1.929, 
    Q:0.095, 
    R:5.987, 
    S:6.327, 
    T:9.056, 
    U:2.758, 
    V:0.978, 
    W:2.36, 
    X:0.15, 
    Y:1.974, 
    Z:0.1755,     
    };

    const firstLetterFrequency = {
        A:12.159, 
        B:4.934, 
        C:5.538, 
        D:3.415, 
        E:2.956, 
        F:4.081, 
        G:2.061, 
        H:5.413, 
        I:7.599, 
        J:0.614, 
        K:0.802, 
        L:4.185, 
        M:2.613, 
        N:2.374, 
        O:7.943, 
        P:4.893, 
        Q:0.104, 
        R:2.998, 
        S:8.297, 
        T:7.235, 
        U:2.842, 
        V:1.031, 
        W:4.247, 
        X:0.135, 
        Y:1.478, 
        Z:0.152,         
      };
    
    const lastLetterFrequency = {
        A:8.348, 
        B:1.643, 
        C:2.465, 
        D:4.909, 
        E:20.154, 
        F:0.639, 
        G:1.907, 
        H:4.382, 
        I:4.99, 
        J:0.071, 
        K:0.74, 
        L:5.467, 
        M:2.931, 
        N:9.818, 
        O:6.197, 
        P:1.552, 
        Q:0.02, 
        R:5.822, 
        S:6.218, 
        T:7.628, 
        U:1.724, 
        V:0.314, 
        W:0.254, 
        X:0.041, 
        Y:1.704, 
        Z:0.161,         
    }

    const evenLetterFrequency = {
        A:3.84615385, 
        B:3.84615385, 
        C:3.84615385, 
        D:3.84615385, 
        E:3.84615385, 
        F:3.84615385, 
        G:3.84615385, 
        H:3.84615385, 
        I:3.84615385, 
        J:3.84615385, 
        K:3.84615385, 
        L:3.84615385, 
        M:3.84615385, 
        N:3.84615385, 
        O:3.84615385, 
        P:3.84615385, 
        Q:3.84615385, 
        R:3.84615385, 
        S:3.84615385, 
        T:3.84615385, 
        U:3.84615385, 
        V:3.84615385, 
        W:3.84615385, 
        X:3.84615385, 
        Y:3.84615385, 
        Z:3.94615385,         
    }

const scrabbleValues = {
    'a': 1, 'b': 3, 'c': 3, 'd': 2, 'e': 1, 'f': 4, 'g': 2, 'h': 4, 'i': 1, 'j': 8, 'k': 5, 'l': 1, 'm': 3,
    'n': 1, 'o': 1, 'p': 3, 'q': 10, 'r': 1, 's': 1, 't': 1, 'u': 1, 'v': 4, 'w': 4, 'x': 8, 'y': 4, 'z': 10,' ':0
};

const gameModes = [
{
    id: "trad_001",
    name: "Traditional",
    unlock_conditions: [],
    settings: {
        hasTimer:true,
        hasPresetRounds:false,
        duration: 30,
        skips: 3,
        minLength: 4,
        maxLength: null,
        scoringMode: "traditional_plus",
        stopMode: "rounds",
        progression: [2,2,2,2,2,3,3,3,3,3,4,4,4,4,4,5],
    },
    description: "Find a word that has the given letters in that order!",
},
{
    id: "scrab_001",
    name: "Scrabble",
    unlock_conditions: [{'id':'trad_001','score':30}],
    settings: {
        hasTimer:true,
        hasPresetRounds:false,
        duration: 30,
        skips: 3,
        minLength: 4,
        maxLength: null,
        progression: [2,2,2,2,2,3,3,3,3,3,4,4,4,4,4,5],
        scoringMode: "scrabble",
        stopMode: "rounds"
    },
    description: "Like traditional mode, but come up with the most valuable words based on Scrabble scoring!",
},
{
    id: "grouped_001",
    name: "Puzzles",
    isGrouped: true,
    unlock_conditions:[{'id':'scrab_001','score':200}],
    groupedModes: [
{
    id: "puzz_001",
    name: "Puzzle Level 1",
    unlock_conditions: [{'id':'scrab_001','score':200}],
    settings: {
        hasTimer: false,
        hasPresetRounds:true,
        duration: 999999,
        skips: 0,
        minLength: 4,
        maxLength: null,
        progression: [2,2,2,2,2,2,2,2,2,2],
        scoringMode: "traditional_plus",
        stopMode: "rounds",
        presetRounds: [['U','U'],
                       ['W','F'],
                       ['Q','H'],
                       ['X','K'],
                       ['G','J'],
                       ['F','Q'],
                       ['K','X'],
                       ['W','J'],
                       ['Z','B'],
                       ['Z','W'],
                      ],
    },
    description: "Solve these tough two letter combos - no time limits!",
},
{
    id: "puzz_002",
    name: "Puzzle Level 2",
    unlock_conditions: [{'id':'puzz_001','score':20}],
    settings: {
        hasTimer: false,
        hasPresetRounds:true,
        duration: 999999,
        skips: 0,
        minLength: 4,
        maxLength: null,
        progression: [3,3,3,3,3,3,3,3,3,3],
        scoringMode: "traditional_plus",
        stopMode: "rounds",
        presetRounds: [['A','B','C'],
                       ['W','F','A'],
                       ['G','H','H'],
                       ['K','S','V'],
                       ['Q','W','E'],
                       ['P','D','F'],
                       ['I','G','W'],
                       ['U','U','U'],
                       ['X','Y','Z'],
                       ['P','Q','R'],
                      ],
    },
    description: "We're moving on to 3 letters: solve these 10 to unlock Level 3!",
},
{
    id: "puzz_003",
    name: "Puzzle Level 3",
    unlock_conditions: [{'id':'puzz_002','score':30}],
    settings: {
        hasTimer: false,
        hasPresetRounds:true,
        duration: 999999,
        skips: 0,
        minLength: 5,
        maxLength: 5,
        progression: [3,3,3,3,3,3,3,3,3,3,3,3],
        scoringMode: "traditional_plus",
        stopMode: "rounds",
        presetRounds: [ ['K','Y','K'],
                        ['D','M','L'],
                        ['T','W','P'],
                        ['I','Y','L'],
                        ['S','Y','Y'],
                        ['F','T','W'],
                        ['E','B','U'],
                        ['Q','A','F'],
                        ['T','S','A'],
                        ['A','S','D'],
                        ['U','L','M'],
                        ['C','E','W'],
                      ],
    },
    description: "Let's do it Wordle-style! Each of your answers here must be 5 letters, no more no less.",
},
{
    id: "puzz_004",
    name: "Puzzle Level 4",
    unlock_conditions: [{'id':'puzz_003','score':36}],
    settings: {
        hasTimer: false,
        hasPresetRounds:true,
        duration: 999999,
        skips: 0,
        minLength: 4,
        maxLength: null,
        progression: [2,2,2,3,3,3,3,3,3,4,4,4,5],
        scoringMode: "traditional_plus",
        stopMode: "rounds",
        presetRounds: [['Z','U'],
                       ['X','X'],
                       ['Y','Q'],
                       ['V','N','V'],
                       ['M','K','U'],
                       ['V','B','A'],
                       ['G','V','K'],
                       ['P','U','U'],
                       ['D','G','F'],
                       ['B','A','A','M'],
                       ['M','N','O','P'],
                       ['Y','H','D','N'],
                       ['A','E','I','O','U'],
                      ],
    },
    description: "We're amping it up with level 4, good luck!",
},
{
    id: "puzz_005",
    name: "Puzzle Level 5",
    unlock_conditions: [{'id':'puzz_004','score':41}],
    settings: {
        hasTimer: false,
        hasPresetRounds:true,
        duration: 999999,
        skips: 0,
        minLength: 7,
        maxLength: 7,
        progression: [3,3,3,3,3,3,3,4,4,4,4,4,4,5],
        scoringMode: "traditional_plus",
        stopMode: "rounds",
        presetRounds: [['A','A','A'],
                       ['L','L','L'],
                       ['P','O','Z'],
                       ['Z','Z','Z'],
                       ['B','S','B'],
                       ['L','F','G'],
                       ['V','V','A'],
                       ['S','S','S','S'],
                       ['O','P','Q','R'],
                       ['A','B','C','D'],
                       ['E','O','U','E'],
                       ['D','F','U','D'],
                       ['R','A','B','U'],
                       ['E','X','P','U','D'],
                      ],
    },
    description: "7 Letter Words Only. We are here to stump you!",
}
]},
{
    id: "race_001",
    name: "Race",
    unlock_conditions: [{'id':'puzz_001','score':19}],
    settings: {
        hasTimer:true,
        hasPresetRounds:false,
        duration: 30,
        skips: 0,
        minLength: 4,
        maxLength: null,
        progression: [2,2,2,2,2,3,3,3,3,3,4,4,4,4,4,5],
        scoringMode: "timed",
        stopMode: "rounds"
    },
    description: "It's a race! The quicker you answer, the more points you get! Avoid penalties for incorrect answers!",
},
{
    id: "endur_001",
    name: "Endurance",
    unlock_conditions: [{'id':'race_001','score':80}],
    settings: {
        hasTimer: true,
        hasPresetRounds:false,
        duration: 60,
        skips: 3,
        minLength: 4,
        maxLength: null,
        progression: [2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,6,6,6,6,6],
        scoringMode: "traditional_plus",
        stopMode: "endurance"
    },
    description: "Keep going until you miss!",
},
{
    id: "grouped_002",
    name: "Challenge",
    isGrouped: true,
    unlock_conditions:[{'id':'trad_001','score':40},{'id':'endur_001','score':100}],
    groupedModes: [
        {
            id: "chal_001",
            name: "Challenge Level 1",
            unlock_conditions: [{'id':'trad_001','score':40},{'id':'endur_001','score':100}],
            settings: {
                hasTimer: true,
                hasPresetRounds:false,
                duration: 15,
                skips: 1,
                minLength: 4,
                maxLength: null,
                progression: [2,2,2,2,3,3,3,3,4,4,4,4,5,5,5,5],
                scoringMode: "traditional_plus",
                stopMode: "rounds"
            },
            description: "Welcome to the Expound Challenge! Score over 40 to unlock level 2!",
        },
        {
            id: "chal_002",
            name: "Challenge Level 2",
            unlock_conditions: [{'id':'chal_001','score':40}],
            settings: {
                hasTimer: true,
                hasPresetRounds:false,
                duration: 20,
                skips: 0,
                minLength: 6,
                maxLength: null,
                progression: [2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,5,5],
                scoringMode: "traditional_plus",
                stopMode: "rounds"
            },
            description: "Level 2: 6-letter minimum word length. Use your big boy words!",
        },
        {
            id: "chal_003",
            name: "Challenge Level 3",
            unlock_conditions: [{'id':'chal_002','score':56}],
            settings: {
                hasTimer: true,
                hasPresetRounds:false,
                duration: 10,
                skips: 0,
                minLength: 4,
                maxLength: null,
                progression: [2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,5,5,5,5,5,6,7],
                scoringMode: "traditional_plus",
                stopMode: "rounds"
            },
            description: "Level 3: Hold on to your dictionaries, kids! Things are about to go down!",
        },
    ]},

];

export {gameModes, scrabbleValues, letterFrequencies, firstLetterFrequency, lastLetterFrequency, evenLetterFrequency}

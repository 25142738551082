import { Box, Flex, Heading, ButtonGroup, Button } from '@chakra-ui/react';
import ContentArea from '../content/ContentArea';
import MyHeader from '../../general/components/MyHeader';
import Navbar from '../../general/components/Navbar';
import Footer from '../../general/components/Footer';

const HomePage = ({session, ...props }) => {
  const webState = props.webState;

    return (
      <Box>
      <MyHeader />
      <Navbar session={session}/>
      <Box>
      <ContentArea />
    </Box>
    <Footer />
      </Box>
      );
    }

  export default HomePage;
  
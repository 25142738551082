import { Heading, List, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';


function ShortRules() {

    return (
        <>
      <Heading as="h5" size="m" mb="2">
        The Essentials:
      </Heading>
      <UnorderedList fontSize="xs">
        <ListItem>You'll be given a set of letters, e.g. [E, P, D]</ListItem>
        <ListItem>Your goal: come up with a word that has those letters in that order. E.g. <b>E</b>x<b>P</b>oun<b>D</b></ListItem>
        <ListItem>You always have to add at least one letter to make it a word</ListItem>
        <ListItem>Partial points for words that get the order reversed or at least have all the letters.</ListItem>
        <ListItem>Can't reuse the same word or part of word in a given round!</ListItem>
        </UnorderedList>
      </>
    );
  }

  export default ShortRules;
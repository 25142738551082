const servicesData = [
  {
    title: 'Customer Success Growth Accelerator',
    cost: '$25,000/mo',
    description: 'Are you a B2B SaaS company looking to grow your client services team? We can help!',
    imageUrl: '/products/cs-growth.png',
    category: 'SaaS Growth',
    url:'',
    action_callout: 'Learn More',
    modalData: {
      header: 'Looking to grow your client services team? We can help!',
      challenge: 'Scaling your client services team is hard. You need to make sure that you are hiring the right people, that you have the right processes in place, and that you are able to scale your team as your business grows.',
      details: ['We\'ll do a custom assessment of your business as it stands today.',
      'We\'ll work with you to identify areas for growth and develop a customized plan to make it happen',
      'We\'ll host several training sessions for your team to help them deliver more value to clients and become more efficient in doing so.',
      'And we\'ll provide a final set of recommendations, reviewed with all key stakeholders, to ensure alignment and drive action.',
    ],
      qualifications: 'We\'ve been in the midst of tremendous growth at all sizes of company. From 20 to 50, 100 to 500, or 1,000 to 4,000, we\'ve seen it all from both the Product side and the Services side. We know what it takes to scale, and we\'ll help you get there.',
      cta:'book',
      ctaText:'Setup an Intro Call',
      ctaUrl: 'https://calendly.com/benjaminmumma/intro',
      ctaSubtext:'No obligations!',
    }

  },
    {
      title: '30 Minute Assessment',
      cost: 'FREE',
      description: 'Let\'s talk and understand what you need. Schedule some time right here!',
      imageUrl: '/home/m_consulting_2.png',
      category: 'all',
      url: '',
      action_callout:'Book Today',
      modalData: {
        header:'We\'ll have a quick discussion to get introduced, understand your needs, and align on next steps.',
        details:['One quick call, no obligations!',
                 'Agenda: Introductions (5 mins), Discuss Your Needs (20 mins), Plan Next Steps (5 mins)',
                 'After our discussion, we\'ll quickly follow-up to come get you a proposal for how we can best help.'],
        cta:'book',
        ctaText:'Schedule it now!',
        ctaUrl: 'https://calendly.com/benjaminmumma/intro',
        ctaSubtext:'Three clicks on Calendly is all it takes!',
      },
    },
    /*{
      title: 'AI Strategy Development',
      cost: '$2,000',
      discounted: '$1,500',
      description: 'AI will revolutionize your business whether you like it or not. Are you ready?',
      imageUrl: '/home/m_consulting_2.png',
      url: '',
      action_callout:'Learn More',
      modalData: {
        header:'AI will revolutionize your business whether you like it or not. Are you ready?',
        challenge:'The arrival of ChatGPT and GPT4 have kicked off an arms race in AI. It isn\'t just hype: these Large Language Models (LLMs) are displaying human-level comprehension and capability that \
        is going to change business forever. And to make things even tougher: it\'s all happening incredibly quickly, with new tools and findings coming out every week.',
        solution:'Our goal in an AI assessment is to a) help you better understand the current AI landscape, b) identify areas where you could get \
        the most value from leveraging these tools, and c) help you with a few tactical first steps toward doing so.',
        details:['Includes over 10 hours focused entirely on how YOU can adapt to AI.',
                 '1 hour introductory session to understand your business and competencies.',
                 '2-3 deep dives then will be scheduled to dig further understand your business.',
                 'We\'ll come back to you 1-2 weeks later with a detailed report outlining your opportunities.',
                 'Finally, we\'ll conduct a 2-hour training session for your team on AI essentials and next steps from the report.',
                ],
        qualifications:'Ben has extensive background in Data Science and developing data systems: from building pipelines to architecting databases to developing machine learning models. \
        Since the arrival of ChatGPT, Ben has been studying and experimenting with various LLM technology.',
        cta:'book',
        ctaText:'Setup an Intro Call',
        ctaUrl: 'https://calendly.com/benjaminmumma/intro',
        ctaSubtext:'No obligations!',
      },
    },
    {
      title: 'Product Development Starter Pack',
      cost: '$4,000',
      discounted: '$3,000',
      description: 'Do you want to get your product or project going on the right foot? Let me help you!',
      imageUrl: '/home/m_consulting_2.png',
      url: '',
      hidden: 1,
      action_callout:'Learn More',
      modalData: {
        header:'Do you want to get your product going on the right foot? Let me help you!',
        challenge:'Getting started with a tech product is hard, and making the wrong decisions upfront can have major costs down the road.',
        solution:'We offer an intensive product management sprint as a way to ensure your product starts on the right path. Through a consultative effort, we\'ll work \
        with you to ensure that visions are aligned and that there is a concrete path for you as you start development.',
        details:[
                  'Includes over 20 hours of work, curated to your business.',
                  '2-3 hours of initial discovery work to understand your business, team competencies, and current architecture, further deep dives as necessary.',
                  'We will take that information and develop an initial architecture plan and review it with you and your team to gather feedback.',
                  'One we arrive at a final set of recommendations, we\'ll work with you to take the early steps in building out an early POC.',
                  'Before we conclude our effort, we\'ll ensure that your team is trained up on the tech stack and ready to build off of a strong foundation!'],
        qualifications:'Ben has over five years experience in Product Management, in large and small companies, as well as in his own startup. \
        He regularly advises startups at various stages on the technological frameworks that will work best for them.',
        cta:'book',
        ctaText:'Setup an Intro Call',
        ctaUrl: 'https://calendly.com/benjaminmumma/intro',
        ctaSubtext:'No obligations!',
      },
    },
    {
      title: 'Data Architecture Evaluation',
      cost: '$8,000',
      discounted: '$6,000',
      description: 'Is your data infrastructure slowing you down? Don\'t know where to start? This is a good place!',
      imageUrl: '/home/m_consulting_2.png',
      url: '',
      hidden: 1,
      action_callout:'Learn More',
      modalData: {
        header:'Is your data infrastructure slowing you down? Don\'t know where to start? You came to the right place!',
        challenge:'Data architecture has evolved substantially, and many older models of storing data no longer work well. And the fact is you need good data architecture:\
         without it your teams will struggle to use that data and create valuable insights or products from it. As we move into an age of Machine Learning and AI, success \
         will increasingly depend on how well you use your unique datasets.',
        solution:'We will work with you to understand your current architecture, and then develop recommendations tailored to your needs. If you are in the process of evaluating a few \
        different paths, definitely bring us in so that we can help you assess as an independent third party. Whether you are looking at Snowflake, Databricks, or one or more of the cloud providers \
        we can help you figure out which option is best for you.',
        details:[ 'Includes up to 50 hours of work to evaluate your current architecture and data assets and develop curated recommendations on how to proceed.',
                  'We plan for 4-8 hours of initial discovery to understand your current setup.',
                  'After that, we\'ll take that information and develop an initial recommendation that we\'ll share with you to discuss tradeoffs and get feedback.',
                  'From there, we\'ll iterate further and come to a final recommendation along with detailed action plans to help you get started on the right track!',
                  'As needed, we will provide high-level training on the concepts and tools needed for you to be successful.'],
        qualifications:'Ben has in the Data & Analytics space for over a decade. Most recently he worked at Databricks, which pioneered distributed computing and architecture models to handle the largest data volumes.',
        cta:'book',
        ctaText:'Setup an Intro Call',
        ctaUrl: 'https://calendly.com/benjaminmumma/intro',
        ctaSubtext:'No obligations!',
      },
    },
    {
        title: 'Custom Work',
        cost: '$250/hr',
        discounted: '$200/hr',
        description: 'Get architecture, analytic, and development skills on demand, all at one fixed rate!',
        imageUrl: '/home/m_consulting_2.png',
        url: '',
        action_callout:'Inquire Today',
        modalData: {
          header:'We\'ll have a quick discussion to get introduced, understand your needs, and align on next steps.',
          challenge:'',
          solution:'No matter your need, let us use our time to help you bring innovative solutions to market. From ideation to architecture to developing a proof-of-concept, we can help you \
          move quickly and efficiently toward your goal.',
          details:['No commitment: pay as you go/only pay for what you need.',
                    'Use our time wherever you need it',
                    'Discounting is available for bulk hour purchases'],
          qualifications:'',
          cta:'book',
          ctaText:'Setup an Intro Call',
          ctaUrl: 'https://calendly.com/benjaminmumma/intro',
          ctaSubtext:'No obligations/fees during early discussions',
        },
      },
    // Add more objects as needed*/
  ];

  const examplesData = [
    {
      title: 'Forward',
      description: 'Habits. Tasks. Goals. All in one place!',
      imageUrl: '/home/forward_logo_v1_min.png',
      url: 'https://www.theforwardapp.com',
      internal:false,
    },
    {
      title: 'Intellect Inbox',
      description: 'Daily e-mails to make you smarter!',
      imageUrl: '/home/ii-v1.png',
      url: 'https://www.intellect.email',
      internal:false,
    },
    {
      title: 'MRR Growth Calculator',
      description: 'Use this tool to assess how retention and growth rates impact your recurring revenue',
      imageUrl: '/home/mrr.png',
      url: '/mrr',
      internal: true,
    },
    // Add more objects as needed
  ];
  
  export {servicesData, examplesData};
import React from 'react';
import { Box, Heading, Text, Flex, Button, ButtonGroup, Center, VStack, Link } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import { Link as RouterLink } from 'react-router-dom';
import Footer from '../../general/components/Footer';

const About = () => {
  return (
    <>
    <MyHeader />
    <Navbar />
    <Box py={4} px={{ base: 4, md: 8 }}>
      <Center>
        <VStack maxWidth="800px">
      <Heading as="h2" mb={4}>
        About
      </Heading>
      <Text>
        Mumma Labs is a small software development shop and consultancy. We build software products to help people manage their lives and businesses.
      </Text>
      <Text>
        In 2023, we launched <Link fontWeight="bold" href="https://www.theforwardapp.com">Forward</Link>, an all-in-one life management platform designed to help entrepreneurs and contractors manage all the different aspects of their life in a single place.
      </Text>
      <Text>
        In 2024, we are launching <Link fontWeight="bold" as={RouterLink} to="/intellectinbox">Intellect Inbox</Link>, a free educational service that sends high-quality educational content to your inbox every weekday, powered by GPT4.
      </Text>
      <Text>
        Mumma Labs was founded by Ben Mumma in 2023. Prior to founding Mumma Labs, Ben has worked for over 12 years in the software, data, and AI space. For more about Ben Mumma, check out his resume or what he is doing now:
      </Text>
      <Link fontSize="lg" color="teal" as={RouterLink} to="/resume" fontWeight="bold">Ben's Resume</Link>
      <Link fontSize="lg" color="teal" as={RouterLink} to="/now" fontWeight="bold">What Ben is doing now</Link>
      <Text>
        Welcome, please have a look around and don't hesitate to reach out if you need some consulting or added manpower on your data and analytic challenges!</Text>
      </VStack>
      </Center>
    </Box>
    <Footer />
    </>
  );
};

export default About;
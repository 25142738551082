
import { supabase } from "../../../api/supabaseClient";

export async function readPlayers() {
    let { data, error } = await supabase
        .from('nfl_players')
        .select(`*`)
        .eq('status', 1)
    if (error) {
        console.log('error', error)
    } else {
        return data
    }
}

export async function addPlayer({user_id}) {
    const data_to_send = {
        user_id: user_id,
        player_name: 'New Player',
    }
    let { data, error } = await supabase
        .from('nfl_players')
        .insert([data_to_send])
    if (error) {
        console.log('error', error)
    } else {
        return data
    }
}

export async function addPlayerToEvent({player_id, user_id, event_id}) {

    const data_to_send = {
        player_id: player_id,
        event_id: event_id,
        status: 1
    }
    console.log('data_to_send', data_to_send)
    let { data, error } = await supabase
        .from('nfl_player_events')
        .insert([data_to_send])
    if (error) {
        console.log('error', error)
    } else {
        return data
    }
}

export async function updatePlayer({player_id, player_name}) {
    const data_to_send = {
        player_name: player_name
    }
    let { data, error } = await supabase
        .from('nfl_players')
        .update(data_to_send)
        .eq('id', player_id)
    if (error) {
        console.log('error', error)
    } else {
        return data
    }
}
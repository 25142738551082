import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link, HStack, Stack, Divider, VStack, Badge, useColorModeValue} from '@chakra-ui/react';
import EdCard from './EdCard';
import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes, smallFontSizes } from '../../general/responsive.js'

const Education = ({education}) => {
  return (
    <Box padding={2} w="100%">
        <Heading as="h3" size={bigHeaderSizes} textAlign="center">Education</Heading>
        <Stack w="100%" justifyContent="center" py={2} spacing={{'base':'0','md':'4'}} alignItems="stretch" flexDirection={{'base':'column','md':'row'}}>
            {education.map((degree, index) => (
                <EdCard key={index}
                degree={degree.degree}
                major={degree.major}
                university={degree.university}
                startDate={degree.start_date}
                endDate={degree.end_date}
                details={degree.details}
                
              />
            ))}
        </Stack>
        <Divider />
    </Box>
  )
}

export default Education;
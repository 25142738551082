import React from 'react';
import { Box, Heading, Text, Flex, Button, ButtonGroup, Link, Center } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import NowContent from '../content/NowContent';

const Now = () => {
  return (
    <>
    <MyHeader />
    <Navbar />
    <Center >
    <Box py={8} px={{ base: 4, md: 8 }} maxWidth="1000px">
      <Heading as="h3" mb={4}>
        Ben's Now Page
      </Heading>
      <Text as="i">A "<Link href="https://www.nownownow.com" target="_blank">Now</Link>" Page gives a quick update on the latest in my life, but it's no substitute to having a conversation, so don't hesitate to reach out!</Text>
      <NowContent />
    </Box>
    </Center>
    </>
  );
};

export default Now;
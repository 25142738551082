const writingData = [
    {
      title: 'Bizlandia',
      description: 'Business, Tech, and Data Strategy',
      imageUrl: '/home/bizlandia.png',
      url: 'https://bizlandia.substack.com',
      asNewWindow:true,
    },
    {
      title: 'The Human Optimization Project',
      description: 'Playbooks for happier, healthier, and more productive living',
      imageUrl: '/home/hop.png',
      url: 'https://hopforward.substack.com',
      asNewWindow:true,
    },
    {
      title: 'The Book of Principles',
      description: 'Principles to live by',
      imageUrl: '/home/principles.png',
      url: 'https://thebookofprinciples.substack.com',
      asNewWindow:true,
    },
    {
      title: 'A Moment with Mumma',
      description: 'Fearless but fair political and social commentary',
      imageUrl: '/home/amwm.png',
      url: 'https://mumma.substack.com',
      asNewWindow:true,
    },
    
    
    // Add more objects as needed
  ];
  
  export default writingData;
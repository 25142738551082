import { useCallback, useContext } from 'react'
import { readLeague } from '../database/leagues';
import { PickemContext } from '../context/PickemContext';

export const useLeagues = () => {
  const { state, dispatch } = useContext(PickemContext);

  const syncLeagues = useCallback(async () => {
    try {
      const data = await readLeague()
      dispatch({ type: 'SET_LEAGUES', payload: data })
      //console.log('My Leagues:', data)
    } catch (error) {
      console.log('error', error)
    }
  }, [dispatch])

  return {
    syncLeagues
  }
}
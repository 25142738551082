import { Button } from "@chakra-ui/react";
import React from "react";
import { PopupModal } from "react-calendly";

class CalendlyButtonModal extends React.Component {
  constructor(props) {
    super(props);
    this.url = props.url;
    this.text = props.text;

    this.state = {
      isOpen: false,
    };
  }

  render() {

    
    return (
      <div>
        <Button
        colorScheme={this.props.colorScheme || "teal"}
          onClick={() => this.setState({ isOpen: true })}
        >
          {this.props.text || "Click here to schedule!"}
        </Button>
        <PopupModal
          url={this.props.url || "https://calendly.com/benjaminmumma/intro"}
          pageSettings={this.props.pageSettings}
          utm={this.props.utm}
          prefill={this.props.prefill}
          onModalClose={() => this.setState({ isOpen: false })}
          open={this.state.isOpen}
          rootElement={document.getElementById("root")}
        />
      </div>
    );
  }
}

export default CalendlyButtonModal;


import React from 'react';
import { Heading, ButtonGroup, Button, VStack, Box, Flex, Link, useColorModeValue, useDisclosure, HStack, Image, Text, IconButton } from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { FaCog, FaEnvelope, FaHome } from "react-icons/fa";
import NewContactModal from './NewContactModal';
import { Link as RouterLink } from 'react-router-dom';
const MyHeader = ({...props}) => {

let isForwardApp = (window.location.hostname === 'www.theforwardapp.com' || window.location.href.includes('/forward'));
let isIntellectInboxApp = window.location.href.includes('intellectinbox');

const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure();
const trackCustomEvent = (eventName) => {
      if (window.plausible) {
        window.plausible(eventName);
      }
    };

const handleContactModalOpen = () => {
      trackCustomEvent('Contact Modal Open');
      onContactOpen();
}

let appName = "Mumma Labs";
let appTagline = "Software & Analytics";
let appLogo = "/home/m_consulting_round.png";
let homeLink = "/";
let homeButton = <IconButton icon={<FaHome />} as={RouterLink} to="/" borderRadius="20px" colorScheme="teal" variant="solid" boxShadow="lg"  />

if (isForwardApp) {
      appName = "Forward";
      appTagline = "The Life Management Platform";
      appLogo = "/home/forward_logo_v1_min.png";
      homeLink = "/";
      homeButton = <Box as={RouterLink} to="/" width="40px"><Image src='/home/m_consulting_round.png' width="40px" boxShadow="lg" borderRadius="40px"  /></Box>
}
if (isIntellectInboxApp) {
      appName = "Intellect Inbox";
      appTagline = "AI-Powered Educational Platform";
      appLogo = "/home/ii-v1.png";
      homeLink = "/intellectinbox";
      homeButton = <Box as={RouterLink} to="/" width="40px"><Image src='/home/m_consulting_round.png' width="40px" boxShadow="lg" borderRadius="40px"  /></Box>
}

return (
      <>
      <Flex as="header" p={4} borderBottomWidth="1px" justifyContent="space-between" alignItems="center" {...props}>
            <Link as={RouterLink} to={homeLink} style={{ textDecoration: "none" }}>
            <HStack alignItems="center">
                  <Image src={appLogo} width="65px" boxShadow="lg" borderRadius="40px"/>
                  <VStack alignItems="flex-start">
                  <Heading as="h1" size="lg">
                  {appName}
                  </Heading>
                  <Heading as="h2" size="xs" mt={1} fontWeight="normal" display={{ base: 'none', md: 'block' }}>
                  {appTagline}
                  </Heading>
                  </VStack>
            </HStack>
            </Link>
      
      <ButtonGroup direction={{ base: 'column', md: 'row' }}>
      <Button
      leftIcon={<FaEnvelope />}
      colorScheme="teal"
      onClick={handleContactModalOpen}
      variant="solid" boxShadow="lg"
      borderRadius="20px"
      >
            <Text display={{ base: 'none', md: 'block' }}>Contact</Text>
      </Button>
      {homeButton}
      <Button colorScheme="teal" variant="outline" display={'none'} borderRadius={0}><FaCog /></Button>
      <ColorModeSwitcher  borderRadius="20px" bg="teal" color="white"/>
      </ButtonGroup>
      </Flex>
      <NewContactModal
      isOpen={isContactOpen}
      onClose={onContactClose}
      />
      </>);
}

export default MyHeader;

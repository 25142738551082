import { Box, HStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { PickemContext } from '../handlers/context/PickemContext';

const PickWeekSelector = ({ start_week, end_week, selected_season, selected_week, handleWeekChange}) => {

    const { state, dispatch } = useContext(PickemContext);
    const current_season = state?.current_season;
    const current_week = state?.current_week;

    console.log('Selected Week:', selected_week);

    const handleWeekChangeWrapper = (week) => {
        console.log('Selected Week:', week);
        const is_in_past = parseInt(week) < parseInt(current_week) && parseInt(selected_season) <= parseInt(current_season);
        const is_current_week = parseInt(week) === parseInt(current_week) && parseInt(selected_season) === parseInt(current_season);
        const is_future_week = parseInt(week) > parseInt(current_week) && parseInt(selected_season) >= parseInt(current_season);
        if (is_future_week) {
            console.log('Cannot select future week');
        }
        else {
        handleWeekChange(week);
        }
    }
    return (
        <HStack width="100%" spacing="0.5" overflowX="scroll">
              {
                /* We need to display a link box from start week to end week */
                Array.from({ length: end_week - start_week + 1 }, (_, i) => i + start_week).map((week) => {

                  const is_in_past = parseInt(week) < parseInt(current_week) && parseInt(selected_season) <= parseInt(current_season);
                  const is_current_week = parseInt(week) === parseInt(current_week) && parseInt(selected_season) === parseInt(current_season);
                  const is_future_week = parseInt(week) > parseInt(current_week) && parseInt(selected_season) === parseInt(current_season);

                  const week_color = is_in_past ? 'gray.400' : is_current_week ? 'teal.400' : is_future_week ? 'gray.500' : 'gray.200';
                    
                  return (
                    <Box key={week} 
                        flex="1" 
                        textAlign="center"
                        boxShadow="md"
                        bg={week_color} 
                        _hover={is_future_week ? {bg: 'gray.500'} : {bg: 'teal.300'}}
                        cursor={is_future_week ? 'not-allowed' : 'pointer'}
                        onClick={() => handleWeekChangeWrapper(week)}
                        p={2}>
                      {week}
                    </Box>
                  );
                
                })
              }

            
            </HStack>
    )

}

export default PickWeekSelector;
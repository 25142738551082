import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';

const LiveScore = ({ score, recent_addition, currentRound }) => {
  const [key, setKey] = useState(0);
  const isR1 = currentRound === 1;

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [recent_addition, score]);

  return (
    <Box>
      <Text fontSize={{'base':'md','md':'xl'}} fontWeight="bold">
        Score: {score} pts
      </Text>
      <Box h="24px">
      {!isR1 && (<Text
        key={key}
        fontSize="xs"
        fontWeight="bold"
        textAlign="right"
        sx={{
          animationName: 'fade',
          animationDuration: '5s',
          animationFillMode: 'forwards',
          '@keyframes fade': {
            '0%': { color: 'green.500', fontSize:'md' },
            '100%': { color: 'gray.500', fontSize:'xs' },
          },
        }}
      >
        + {recent_addition} pts
      </Text>)}
      </Box>
    </Box>
  );
};

export default LiveScore;
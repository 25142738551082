import { useCallback, useContext } from 'react'
import { PickemContext } from '../context/PickemContext';
import { readGames } from '../database/games';

export const useGames = () => {
  const { state, dispatch } = useContext(PickemContext);

  const syncGames = useCallback(async (season) => {
    try {
      if(!season || season === null) {
        console.log('No season, not pulling games');
        return
      }
      const data = await readGames(season)
      dispatch({ type: 'SET_GAMES', payload: data })
      //console.log('Games:', data)
    } catch (error) {
      console.log('error', error)
    }
  }, [dispatch])

  return {
    syncGames
  }
}
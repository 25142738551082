import React, { useContext, useState } from 'react';
import { PickemContext } from '../handlers/context/PickemContext';
import { Box, Link, Text, useDisclosure, Button, HStack, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Input } from '@chakra-ui/react';
import SignInModal from './SignInModal';
import { usePlayers } from '../handlers/full/usePlayers';

function UserInfo() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { state, dispatch, signOut } = useContext(PickemContext);
    const player_info = state?.players?.find((player) => player.user_id === state?.user?.id);
    const {updatePlayerName} = usePlayers();

    const [newPlayerName, setNewPlayerName] = useState(state?.players?.find((player) => player.user_id === state?.user?.id)?.player_name);

    const handleEditClick = () => {
      console.log('edit clicked');
    }

    const handleNameChange = (e) => {
      setNewPlayerName(e.target.value);

    }

    const handleSubmit = () => {
      // Dispatch an action or call a function to update the player name
      console.log('New player name:', newPlayerName);
      updatePlayerName({player_id: player_info.id, player_name: newPlayerName});
      onClose();

    }

    if (!state.user) {
      return (
        <HStack>
          <Text p={2}>Not signed in</Text>
          <Box p={2}>
            <Button colorScheme="teal" onClick={onOpen}>Sign In</Button>
          </Box>
          <SignInModal isOpen={isOpen} onClose={onClose} />
        </HStack>
      );
    }

    return (
      <HStack>
        <Box p={2}>
          <Text>
            Welcome, {player_info?.player_name}! 
            <Popover>
              <PopoverTrigger>
                <Link onClick={handleEditClick} color="blue.500" mt={2} display="inline">Edit</Link>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Edit Player Name</PopoverHeader>
                <PopoverBody>
                  <Input 
                    value={newPlayerName} 
                    onChange={handleNameChange} 
                    placeholder="Enter new player name" 
                  />
                  <Button mt={2} colorScheme="teal" onClick={handleSubmit}>Submit</Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Text>
          <Text>Email: {state.user.email}</Text>
        </Box>
        <Button onClick={() => signOut()}>Sign Out</Button> 
      </HStack>
    );
}

export default UserInfo;
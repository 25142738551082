const roles = [
    {
      job_title: 'Director of Product',
      category: 'Previous',
      location: '[Remote] Dallas, TX',
      company: 'Analytic Partners',
      start_date: '2022',
      end_date: '2023',
      tenure: '0.5 years',
      company_info: 'Analytic Partners is an industry leader in marketing optimization for Fortune 500 clients',
      company_website:'https://www.analyticpartners.com',
      bullet_points:[
        'Led a team of 4 product managers delivering our client-facing analytic software platform',
        'Developed plans to modernize two key areas: Data Architecture and Design Process',
        'Led roadmap planning for 2023 development to gain alignment across cross-functional stakeholders',
      ]
    },
    {
        job_title: 'Sr. Customer Engineer - Strategic Accounts',
        category: 'Previous',
        location: '[Remote] Dallas, TX',
        company: 'Databricks',
        start_date: '2020',
        end_date: '2022',
        tenure: '2.5 years',
        company_info: 'Databricks is a fast-growing firm building an Unified Data & Analytics Platform',
        company_website:'https://www.databricks.com',
        bullet_points:[
          'Supported 3 Fortune 100 clients in developing their analytic systems used across the business',
          'Grew book of business from ~$2M ARR to ~$10M ARR and got promoted within the first 18 months',
          'Regularly led enablement sessions for 50-100 Data Scientists, Data Engineers, and Data Analysts',
          'Provided guidance to clients on architectural decisions for their big data applications',
        ]
      },
      {
        job_title: 'Data Science Technical Expert',
        category: 'Part-Time',
        location: '[Remote] Dallas, TX',
        company: 'Thinkful',
        start_date: '2019',
        end_date: '2020',
        tenure: '1.5 years',
        company_info: 'Thinkful was an EdTech company, acquired by Chegg for $80 MM',
        company_website:'',
        bullet_points:[
            'Mentored students through entire Thinkful curriculum, from zero to Data Science Proficiency',
            'Executed and provided feedback on ~100 capstone reviews and provided constructive feedback',   
        ]
      },
      {
        job_title: 'Founder',
        category: 'Previous',
        location: 'Washington DC -> Dallas, TX',
        company: 'WeAchieve',
        start_date: '2018',
        end_date: '2020',
        tenure: '2 years',
        company_info: 'WeAchieve was a habit-building platform that helps people improve their lives',
        company_website:'',
        bullet_points:[
          'Designed & developed a full web platform and iOS app from the ground up with one other co-founder',
          'Reached over 1,000 users logging over 200,000 data points via manual entry and API',
          'Shut down in 2020 due to lack of product-market fit',
        ]
      },
      {
        job_title: 'Director of Revenue & Analytics',
        category: 'Previous',
        location: 'Washington DC',
        company: 'Curiosity Media',
        start_date: '2017',
        end_date: '2017',
        tenure: '1 year',
        company_info: 'Curiosity Media builds English to Spanish learning tools used by over 20MM people/month',
        company_website:'',
        bullet_points:[
          'Led a four-person team to identify, implement revenue generating projects focused in Ad Tech',
          'Overhauled A/B testing approach to adopt industry best practices',
          'Actions drove 11% increase in topline revenue (A/B test validated) and a 26% YOY rev growth on over $7MM',
        ]
      },
      {
        job_title: 'Product Manager',
        category: 'Previous',
        location: 'Washington DC',
        company: 'Applied Predictive Technologies (A MasterCard Company)',
        start_date: '2015',
        end_date: '2016',
        tenure: '2 years',
        company_info: 'APT provided analytic platforms to much of the Fortune 500; acquired by MasterCard in 2015 for $600 MM',
        company_website:'',
        bullet_points:[
          'Led analytics development of a price optimization platform developed for a leading restaurant, successfully licensed for $400k/yr',
          'Analyzed MasterCard datasets to produce metrics that improved APT\'s Real Estate Analytics ability to predict new store performance',
          'Led a 3-person engineering team to implement new features into the platform',
        ]
      },
      {
        job_title: 'Consultant & Engagement Manager',
        category: 'Sub Role',
        location: 'Washington DC',
        company: 'Applied Predictive Technologies (A MasterCard Company)',
        start_date: '2011',
        end_date: '2015',
        tenure: '4 years',
        company_info: 'APT provided analytic platforms to much of the Fortune 500; acquired by MasterCard in 2015 for $600 MM',
        company_website:'',
        bullet_points:[
          'Managed multiple teams of 2-3 consultants to implement APT\'s analytic platform for clients',
          'Clients included: Dunkin\' Brands, Wawa, AB Inbev, Chico\'s, SunTrust, KKR, Walmart, & Goldman Sachs',
          'Co-led internal team that developed and implemented initiatives to improve productivity & retention',
          'Led analytic and strategic advisory for multiple clients with their Finance, Strategy, and Marketing teams',
        ]
      },

    // Add more objects as needed
  ];

  
  export {roles};
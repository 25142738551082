const interests = [
    'Marathons (12 down, more to go)',
    'Building things: digital & physical',
    'Golf',
    'Writing',
    'Learning languages',
    'Gardening',
];

  
  export {interests};
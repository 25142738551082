import React from "react";

const parseTextWithLinks = (text) => {
    const parts = text.split(/(\[https?:\/\/[^\]]+\])/);
    return parts.map((part, index) => {
      if (part.startsWith('[') && part.endsWith(']')) {
        const link = part.slice(1, -1); // Remove the square brackets
        return (
          <a key={index} href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        );
      }
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
  };


  export default {
    parseTextWithLinks
  }
import { useCallback, useContext } from 'react'
import { readEvent } from '../database/events';
import { PickemContext } from '../context/PickemContext';
import { upsertPicks } from '../database/picks';
import { useToast } from '@chakra-ui/react';

export const usePicks = () => {
  const { state, dispatch } = useContext(PickemContext);
    const toast = useToast();

  const handleUpsertPicks = ({new_picks}) => {

    //Send to the database
    try {
    const insertedData = upsertPicks(new_picks);

    //Update the state
    dispatch({
      type: 'UPDATE_PLAYER_PICKS',
      payload: new_picks
    });
} catch (error) {
    console.error('Error upserting picks', error);
    return { error };
}
    toast({
      title: 'Picks Saved',
      description: 'Your picks have been saved!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    return {response: 'Success'};

  }


  return {
    handleUpsertPicks
  }
}
const education = [
    {
      degree: 'B.S.',
      major: 'Systems Engineering',
      university: 'Lehigh University',
      end_date: '2010',
      details: 'GPA: 3.72 | IBE Honors Program, President\'s Scholar, Magna Cum Laude, Tau Beta Pi',
    },
    {
        degree: 'M.S.',
        major: 'Analytical Finance',
        university: 'Lehigh University',
        end_date: '2011',
        details: '',
      },
      {
        degree: 'M.B.A.',
        major: 'Business Administration',
        university: 'Quantic School of Business',
        end_date: '2018',
        details: '',
      },
    // Add more objects as needed
  ];

  
  export {education};
import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text,useColorModeValue, Divider} from '@chakra-ui/react';

const ResultsGrid = ({ resultsData }) => {
  // Function to format numbers as percent
  const formatAsPercent = (value) => `${(value * 100).toFixed(2)}%`;
  const numberColor = useColorModeValue('black', 'black');
  const isMobile = window.innerWidth < 768;
  // Placeholder for colors based on the value
  const getColor = (value,goal_rate) => {
    if (value/goal_rate >= 1.2) return 'green.500'; // Assuming values are in decimal format
    if (value/goal_rate >= 1.0) return 'green.100';
    if (value/goal_rate >= 0.9) return 'yellow.300';
    if (value/goal_rate >= 0.75) return 'red.200';
    else return 'red.500';
  };

  const formatDollars = (val) => {
    // Convert the number to a string with US currency formatting
    return `$${Number(val).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}`;
  }

  console.log(resultsData);

  const outputs = resultsData.data ||  null;

   // Function to check if resultsData has all required components
   const hasValidData = () => {
    return resultsData && outputs && 
           Array.isArray(outputs.grid) && outputs.grid.length > 0 &&
           Array.isArray(outputs.retention_rates) && outputs.retention_rates.length > 0 &&
           Array.isArray(outputs.nb_rates) && outputs.nb_rates.length > 0 &&
           typeof outputs.months === 'number';
  };

  if (!hasValidData()) {
    return (
      <Box textAlign="center" py={10}>
        <Text fontSize="lg" color="gray.500">
          No data available. Please input values and calculate to see results.
        </Text>
      </Box>
    );
  }

  console.log(outputs)

  return (
    <Box overflowX="auto" width="100%">
      <Table variant="simple" border="1px solid" borderColor="black">
        <Thead>
          <Tr>
            <Th borderColor="black" borderRight={'1px solid'} fontSize={isMobile ? 'xs' : 'sm'}>Monthly Retention % <Divider /> Annual NB Growth %</Th>
            {outputs.retention_rates.map((rate, index) => (
              <Th key={index} textAlign="center" borderRight={'1px solid'} borderColor="black" fontSize={isMobile ? 'sm' : 'md'}>{formatAsPercent(rate)}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {outputs.grid.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              <Td textAlign="center" borderRight="1px solid" borderColor="black" as="th" fontSize={isMobile ? 'sm' : 'md'}>{formatAsPercent(outputs.nb_rates[rowIndex])}</Td>
              {row.map((cell, cellIndex) => (
                <Td key={cellIndex} 
                    bg={getColor(cell,outputs.goal_rate)} 
                    textColor={numberColor} 
                    borderRight={'1px solid'} 
                    borderColor="black" 
                    fontSize={isMobile ? 'sm' : 'md'}
                    textAlign={"center"}>{formatDollars(parseInt(cell))}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {outputs.grid.length === 0 && (
        <Box textAlign="center" py={10}>
          <Text fontSize="lg" color="gray.500">
            No data available. Please input values and calculate to see results.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ResultsGrid;

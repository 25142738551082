import React from 'react';
import { Box, Heading, Text, Center, VStack, HStack, Flex, Button, ButtonGroup, UnorderedList, ListItem } from '@chakra-ui/react';


const HowWeWork = () => {
  return (
    <><Box width="100%" p={4}>
    <Center>
        <Heading as="h3" size="lg" mb={2}>How We Work</Heading>
        </Center>
        <Text fontSize="lg">
        We work under several guiding principles:
        </Text>    
        <UnorderedList spacing={2}>
            <ListItem><b>Truth above all</b> - I'm not here to tell you what you want to hear. I'm hear to help you solve problems.</ListItem>
            <ListItem><b>Deliver fast</b> - My goal is to get you to value quickly. We can find simple solutions first and expand them as needed.</ListItem>
            <ListItem><b>Get you the help you need</b> - We pull in experts as needed to ensure your success. </ListItem>
            <ListItem><b>Build for Long-Term Success</b> - We strive to build solutions and train your team to use them and expand upon them.</ListItem>
            <ListItem><b>Not Happy? Get a Refund</b> - It really is that simple!</ListItem>
        </UnorderedList>

    </Box></>
  );
};

export default HowWeWork


import React from 'react';
import { Button, Box, Text, useDisclosure, HStack, Link, useMediaQuery } from '@chakra-ui/react';
import UserInfo from './UserInfo';
import { Link as RouterLink } from 'react-router-dom';
import { PickemContext } from '../handlers/context/PickemContext';


function NavBar() {
    const { state, dispatch } = React.useContext(PickemContext);
    const [isMobile] = useMediaQuery('(max-width: 600px)');
    //Get current url
    const currentUrl = window.location.pathname;

    const current_player = state?.players?.find((player) => player.user_id === state?.user?.id);


    return (
        <HStack py={2} px={{ base: 4, md: 8 }} width="100%" justifyContent={"space-between"}>
        <HStack>
        {!isMobile && currentUrl.includes('pickem') && 
        <>
        <Link to="/pickem" as={RouterLink}>Pick'em Home</Link>
        <Text> | </Text>
        </>
        }
        {!isMobile && currentUrl.includes('league') && !currentUrl.includes('event') && 
        <>
        <Link to={`/pickem/league/${state?.leagues?.[0]?.id}`} as={RouterLink}>{state?.leagues?.[0]?.league_name}</Link>
        </>
        }
        {currentUrl.includes('event') &&
        <>
        {!isMobile && <><Link to={`/pickem/league/${state?.event?.league_id}`} as={RouterLink}>{state?.event?.nfl_leagues?.league_name}</Link>
        <Text> | </Text></>}
        <Link to={`/pickem/event/${state?.event?.id}`} as={RouterLink}>{state?.event?.event_name}</Link>
        </>}
        {currentUrl.includes('picks') &&
        <>
        <Text> | </Text>
        <Link to={`/pickem/event/${state?.event?.id}/picks/${state?.current_week}/player/${current_player?.id}`} as={RouterLink}>Make Picks</Link>
        </>
        }
      </HStack>
      <UserInfo />
        </HStack>
    );
}

export default NavBar;
import React from 'react';
import { Box, Heading, Image, Text, Center, VStack, Link, HStack, Flex, Button, ButtonGroup, UnorderedList, ListItem, useDisclosure } from '@chakra-ui/react';
import NewContactModal from '../../general/components/NewContactModal';
const AboutConsulting = () => {
  const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure();
  const trackCustomEvent = (eventName) => {
    if (window.plausible) {
      window.plausible(eventName);
    }
  };

  const handleContactModalOpen = () => {
    trackCustomEvent('Contact Modal Open');
    onContactOpen();
  }


  return (
    <><Box width="100%" p={4}>
    <Center>
        <VStack margin={3}>
        <Heading as="h3" size="lg" mb={2}>Ben Mumma</Heading>
        <Image borderRadius="xl" src="/profile-square.jpeg" height="160px"  boxShadow="lg"></Image>
        </VStack>
        </Center>
        <VStack spacing={2}>
        <Text>Ben is an experienced consultant, product manager, and developer. 
        He's worked with many companies, large and small, over the past 12 years to translate data and technology into valuable solutions.</Text> 
        <Text>After five years in consulting at Applied Predictive Technologies, 
            Ben moved into Product Management and has worked with various firms since then to develop and implement technical and analytic products.</Text>
            <Text>His list of clients includes WalMart, AT&T, PepsiCo, Anheuser-Busch Inbev, Dunkin' Brands, Accel-KKR, Goldman Sachs, SunTrust, American Greetings, Chico's, and more.</Text>  
        <Text>Ben currently lives with his wife in Dallas, Texas. For more details, please feel free to check 
              out his <Link color="teal" href="./resume">resume</Link> for more detail or <Link color="teal" onClick={handleContactModalOpen}>just reach out</Link>.</Text>
              </VStack>
    </Box>
    <NewContactModal
      isOpen={isContactOpen}
      onClose={onContactClose}
      />
    </>
  ); 
};

export default AboutConsulting
import { useState, useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react';
import { supabase } from '../../api/supabaseClient';
import { PickemContext } from '../handlers/context/PickemContext';

const JoinModal = ({ isOpen, onClose, eventId }) => {
    const [email, setEmail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = useContext(PickemContext);

    const handleSubmit = async () => {
        setError('');
        setIsLoading(true);

        if (password !== confirmPassword) {
            setError("Passwords don't match");
            setIsLoading(false);
            return;
        }

        try {
            // 1. Create user in Supabase
            const { data, error: signUpError } = await supabase.auth.signUp({
                email,
                password,
                options: {
                    data: {
                        display_name: displayName,
                    }
                }
            });

            if (signUpError) throw signUpError;

            console.log('signUp data', data);

            if (!data.user) {
                throw new Error('User creation failed');
            }

            const data_to_send = {
                    user_id: data.user.id,
                    player_name: displayName,
                    event_id: eventId  
            }
            console.log('data_to_send', data_to_send);
            // 2. Call the create_new_player function
            const { data: funcData, error: funcError } = await supabase
                .rpc('create_new_player', data_to_send);

            if (funcError) throw funcError;

            console.log('Function call result:', funcData);

            // 3. Update app state
            dispatch({ type: 'SET_USER', payload: { id: data.user.id, email, displayName } });

            onClose();
            alert('Sign up successful! Please check your email to confirm your account.');
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Join a League</ModalHeader>
                <ModalBody>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input size="lg" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Display Name</FormLabel>
                        <Input size="lg"  type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Password (must be 6+ characters)</FormLabel>
                        <Input size="lg"  type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Confirm Password</FormLabel>
                        <Input size="lg"  type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </FormControl>
                    {error && <Text color="red.500" mt={2}>{error}</Text>}
                    {password && password.length < 6 && <Text color="red.500" mt={2}>Password must be at least 6 characters</Text>}
                    {confirmPassword && password !== confirmPassword && <Text color="red.500" mt={2}>Passwords don't match</Text>}
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!password || !password.length >= 6 || confirmPassword !== password} colorScheme="teal" width="100%" size="lg" onClick={handleSubmit} isLoading={isLoading}>
                        Join this League!
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default JoinModal;
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { gameModes } from "../../expound/data/constants";

function saveGameHistory(gameHistory) {
    localStorage.setItem('gameHistory', JSON.stringify(gameHistory));
  }

function loadGameHistory() {
    const storedHistory = localStorage.getItem('gameHistory');
    if (storedHistory) {
      return JSON.parse(storedHistory);
    } else {
      return [];
    }
  }

  function clearUserHistory() {
    localStorage.removeItem('gameHistory');
  }


  function calculateGameStats(gameHistory) {
    //const gameHistory = gameHistory
    let total_games = 0;
    let total_stars = 0;
  
    const modeStats = {};

    gameHistory.forEach((entry) => {
        const game_type = entry.gameId.split("_")[0];
        let game_name = '';
        total_games ++;

        if (game_type === 'trad') {
          game_name = 'Traditional';
        } else if (game_type==='scrab'){
          game_name = 'Scrabble';
          
        } else if (game_type==='race'){
          game_name = 'Race';
          
        } else if (game_type==='puzz'){
          game_name = 'Puzzle';
          
        } else if (game_type==='chal'){
          game_name = 'Challenge';
          
        } else if (game_type==='endur'){
          game_name = 'Endurance';
        }

        //Add the game type if we don't have it yet.
        if (!modeStats[entry.gameId]) {
          modeStats[entry.gameId] = {
            gameName: game_name,
            type:'level',
            count: 1,
            bestScore: entry.finalScore,
        };
        } else {        
          modeStats[entry.gameId].count += 1;
          if (0===1) {
              modeStats[entry.gameId].bestScore = Math.min(modeStats[entry.gameId].bestScore, entry.finalScore);
          }
          else {
              modeStats[entry.gameId].bestScore = Math.max(modeStats[entry.gameId].bestScore, entry.finalScore);
          }
        }
          
        if (!modeStats[game_type]) {
          modeStats[game_type] = {
            gameName: game_name,
            type:'aggregate',
            count: 1,
            bestScore: entry.finalScore,
        };
        } else {        
        modeStats[game_type].count += 1;
        if (0===1) {
            modeStats[game_type].bestScore = Math.min(modeStats[game_type].bestScore, entry.finalScore);
        }
        else {
            modeStats[game_type].bestScore = Math.max(modeStats[game_type].bestScore, entry.finalScore);
        }
      }
        
        
    });

    Object.entries(modeStats).forEach(([key, value]) => {
      if(value.type === 'level') {
        return;
      }
      const bs = value.bestScore;
      const game_type = key;
      let star_count = 0;
      if (game_type === 'trad') {
        if (bs >= 50) {
          star_count = 5;
        } else if (bs >= 47.5) {
          star_count = 4;
        } else if (bs >= 45) {
          star_count = 3;
        } else if (bs >= 42.5) {
          star_count = 2;
        } else if (bs >= 40) {
          star_count = 1;
        }
      } else if (game_type==='scrab'){
        if (bs >= 600) {
          star_count = 5;
        } else if (bs >= 550) {
          star_count = 4;
        } else if (bs >= 500) {
          star_count = 3;
        } else if (bs >= 450) {
          star_count = 2;
        } else if (bs >= 400) {
          star_count = 1;
        }
      } else if (game_type==='race'){
        if (bs >= 120) {
          star_count = 5;
        } else if (bs >= 110) {
          star_count = 4;
        } else if (bs >= 100) {
          star_count = 3;
        } else if (bs >= 90) {
          star_count = 2;
        } else if (bs >= 80) {
          star_count = 1;
        }
      } else if (game_type==='puzz'){
        if (bs >= 49) {
          star_count = 5;
        } else if (bs >= 41) {
          star_count = 4;
        } else if (bs >= 36) {
          star_count = 3;
        } else if (bs >= 30) {
          star_count = 2;
        } else if (bs >= 20) {
          star_count = 1;
        }
        
      } else if (game_type==='chal'){
        if (bs >= 120) {
          star_count = 5;
        } else if (bs >= 100) {
          star_count = 4;
        } else if (bs >= 80) {
          star_count = 3;
        } else if (bs >= 60) {
          star_count = 2;
        } else if (bs >= 45) {
          star_count = 1;
        }
      } else if (game_type==='endur'){
        if (bs >= 150) {
          star_count = 5;
        } else if (bs >= 180) {
          star_count = 4;
        } else if (bs >= 160) {
          star_count = 3;
        } else if (bs >= 140) {
          star_count = 2;
        } else if (bs >= 120) {
          star_count = 1;
        }
        
      }
      value.stars = star_count;
      total_stars += star_count;
    })

    const my_totals = {'games':total_games,'stars':total_stars}

    return ({modeStats, my_totals});
  }

  export {saveGameHistory,
        loadGameHistory,
        clearUserHistory,
        calculateGameStats};
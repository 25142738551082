import { useState, useEffect } from 'react';

export default function useGameTimer(initialDurationInSeconds) {
  const [startTime, setStartTime] = useState(null);
  const [paused, setPaused] = useState(true);
  const [durationInSeconds, setDurationInSeconds] = useState(initialDurationInSeconds);
  const [remainingTime, setRemainingTime] = useState(durationInSeconds);
  const [timerInterval, setTimerInterval] = useState(null);
  const [totalTimeUsed, setTotalTimeUsed] = useState(0);

  function startTimer() {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    setPaused(false);
    setStartTime(Date.now());
    setRemainingTime(durationInSeconds);
  }

  
  function getTotalTimeUsed() {
    if (paused) {
      return totalTimeUsed;
    } else {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      return totalTimeUsed + elapsedTime;
    }
  }

  

  function pauseGame() {
    //Set mode to paused
    setPaused(true);
    //Set all time used prior to timer being set
    setTotalTimeUsed(totalTimeUsed + (durationInSeconds - remainingTime));
  }

  function unpauseGame() {
    //Set mode to live
    setPaused(false);
    //Set a new Start Time to Now - (what would have passed)
    setStartTime(Date.now() - (durationInSeconds - remainingTime) * 1000);
  }

  function resetTimer() {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    setPaused(true);
    setRemainingTime(durationInSeconds);
    setTotalTimeUsed(0);
  }

  function setTimer(newDurationInSeconds) {
    setDurationInSeconds(newDurationInSeconds);
    setRemainingTime(newDurationInSeconds);
  }

  useEffect(() => {
    //If it's paused, do nothing
    if (paused) {
      return;
    }

    //Set the interval
    const interval = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      const remaining = durationInSeconds - elapsedTime;
      setRemainingTime(remaining);

      if (remaining <= 0) {
        clearInterval(interval);
        setPaused(true);
        setTotalTimeUsed(totalTimeUsed + durationInSeconds);
      }
    }, 1000);

    setTimerInterval(interval);

    return () => {
      clearInterval(interval);
    };
  }, [paused, startTime, durationInSeconds]);

  return {
    remainingTime,
    paused,
    startTimer,
    pauseGame,
    unpauseGame,
    resetTimer,
    setTimer,
    getTotalTimeUsed,
  };
}
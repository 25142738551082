const case_studies = [
    {case_name:'Price Optimization',
     type:'Data',
     url:'/consulting/priceopt',
     description:'A leading restaurant chain needed to refine their pricing strategy to handle an inflationary environment without losing customers.\
      We developed a price optimization model that allowed them to optimize their pricing strategy across 7000+ locations.',
     image_url:'/case/priceopt.png',
    },
    {case_name:'Customer Success Growth Strategy',
     type:'SaaS Growth',
     url:'/consulting/b2b',
     description:'A 50-person stealth-stage SaaS startup was about to launch publicly and needed to develop a professional services strategy, \
     business value assessment, and growth plan. We helped them develop a cohesive strategy to enable them to move forward with confidence.',
     image_url:'/case/b2b.png',
    },
    {case_name:'Fixing Customer Success Attrition',
     type:'SaaS Growth',
     url:'/consulting/csattrition',
     description:'A 300-person SaaS company was experiencing high turnover of their new consultants, stressing the business model. We developed a \
     comprehensive strategy to reduce attrition and improve the customer success team’s performance.',
     image_url:'/case/csattrition.png',
    },
]
export {case_studies};
import React, {useState} from 'react';
import { Box, Heading, Text, Center, VStack, 
  HStack, Flex, Button, ButtonGroup, SimpleGrid, Grid, GridItem, useColorModeValue, useDisclosure,
  Divider, Image,
  UnorderedList,
  ListItem
 } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import Footer from '../../general/components/Footer';
import {Link as ReachLink} from 'react-router-dom';
import CalendlyButtonModal from '../../general/components/CalendlyButtonModal';
import CompetencyBlock from '../content/CompetencyBlock';
import {data_competencies} from '../data/data_competencies';
import CaseStudyPromos from '../content/CaseStudyPromos';
import Testimonials from '../content/Testimonials';
import OurServices from '../content/OurServices';
import ProductModal from '../content/ProductModal';
import { trackCustomEvent } from '../../general/functions/plausibleEvent.js';


const ConsultingData = () => {
    const { isOpen: isProductOpen, onOpen: onProductOpen, onClose: onProductClose } = useDisclosure();
    const [selectedCardName, setSelectedCardName] = useState(null);
    const handleCardClick = (cardName) => {
        const eventName = "Click on: "+cardName
        console.log(eventName);
        trackCustomEvent(eventName);
        setSelectedCardName(cardName);
        onProductOpen();
      }
    
  
    return (<>
    <MyHeader />
    <Navbar />
    <Box py={8} px={{ base: 4, md: 8 }}>
      <Center maxWidth="100%">
      <VStack width="100%" maxWidth="800px">
                <Text width="100%" textAlign="left" as={ReachLink} to="/consulting" color="blue.500">Back to Consulting</Text>
                <Heading as="h1" fontSize="xl">Data, Analytics, and AI</Heading>
                <Divider py={2} />
                <Text>
                    Most companies still don't get the most out of the data they have available to them. Large companies struggle with volumes and varieties of data, while small companies often struggle to devote the time and resources to establish the proper fundamentals.
                </Text>
                <Text>
                    I've worked within the Data & Analytics space for most of my career. I've seen the good, the bad, the ugly, and worse. I've seen companies that have built incredible data products and companies that have struggled to get the basics right.
                </Text>
                <Text>
                    No matter where you are on your data journey, we can help you get to the next level. We can help you build a data strategy, build a data team, or build a data product. We can help you build a data warehouse, pipeline, or dashboard. And we can help you establish a data-driven culture with the right foundational education. Or we can even help you build product to leverage all the data you have.
                </Text>
                <CalendlyButtonModal colorScheme="green" url={'https://calendly.com/benjaminmumma/intro'} text={'Schedule a 30 minute call'} />
                <Divider py={2} />
                <OurServices onCardClick={handleCardClick} category="Data" />
                <CaseStudyPromos tn={0} hidden={0} category='Data'/>
                <Divider py={2} />
                <Testimonials tn={0} hidden={1} category='Data'/>
                {/*<Divider py={2} />*/}
                <Heading as="h3" size="md">Our Core Competencies</Heading>
                {data_competencies && data_competencies.map((competency, index) => (
                    <CompetencyBlock key={index} title={competency.cname} bulletPoints={competency.points} via_partners={competency.via_partners}  />
                ))}
                <Divider py={2} />

        </VStack>
        </Center>
        </Box>
        <ProductModal productName={selectedCardName} isOpen={isProductOpen} onClose={onProductClose} />
        <Footer />
        </>
    );
};

export default ConsultingData;

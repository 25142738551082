const infoObject = {
    title:'Expound',
    content: [
        {section:"The Essentials",
         text:"Expound is based off a fun road game we learned from our mom as kids. The idea: given a license plate that often has three letters (e.g. EPD-1234), find \
         a word that has those letters in that order (e.g. ExPounD). It's a fun way to test and expand your vocabulary!"},
        {section:"Game Modes",
         text:"We have a variety of game modes here to challenge you, which you'll unlock with higher and higher scores! They are: \n\
         Traditional - 30 seconds per word, you get full points for using the letters in order, half points for using them out of order.\n\
         Scrabble - 30 seconds, the only mode where order doesn't matter! Points are determined by scrabble letter values.\n\
         Puzzle - No time limit! Just solve each tough one to move on.\n\
         Race - Go as fast as you can! You get 10 points for a correct answer in the first 3 seconds, but lose points from there. Go go go!\n\
         Endurance - 60 seconds per word, but you go until you miss!\n\
         Challenge - Some faster and even more furious rule sets to provide the ultimate test!\n\
         "},
         {section:"Terms",
          text:"Expound is free to use and will continue to be hosted here barring any unforeseen catastrophies.\
           All current capabilities will remain free forever."},
          {section:"More Info",
          text:"Can be found here: [https://benmumma.notion.site/Expound-60738016b59e48cf844d51269c09ffdb]"},
    ]
}



export {infoObject}

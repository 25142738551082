
const pickemReducer = (state, action) => {
    switch (action.type) {

        case 'SET_LEAGUES':
            return {
                ...state,
                leagues: action.payload
            }
        case 'SET_GAMES':
            return {
                ...state,
                games: action.payload
            }
        case 'SET_TEAMS':
            return {
                ...state,
                teams: action.payload
            }
            case 'SET_USER':
                return { ...state, user: action.payload };
              case 'CLEAR_USER':
                return { ...state, user: null };
        case 'SET_PLAYERS':
            return {
                ...state,
                players: action.payload
            }
        case 'UPDATE_PLAYER_NAME':
            return {
                ...state,
                players: state.players.map(player => {
                    if (player.id === action.payload.player_id) {
                        return {
                            ...player,
                            player_name: action.payload.player_name
                        }
                    }
                    return player;
                }),
                event: {
                    ...state.event,
                    nfl_player_events: state.event.nfl_player_events.map(player => {
                        if (player.player_id === action.payload.player_id) {
                            return {
                                ...player,
                                nfl_players: {
                                    ...player.nfl_players,
                                    player_name: action.payload.player_name
                                }
                            }
                        }
                        return player;
                    })
                }

            }
        case 'JOIN_EVENT':
            const new_player = {
                ...action.payload,
                status: 1,
                nfl_players: {
                    ...state.players.find(player => player.id === action.payload.player_id)
                }
            }
            return {
                ...state,
                event: {
                    ...state.event,
                    nfl_player_events: [
                        ...state.event.nfl_player_events,
                        new_player
                    ]
                }

            }
        case 'SET_EVENT':
            return {
                ...state,
                event: action.payload
            }
            case 'UPDATE_PLAYER_PICKS':
                const updatedPicks = action.payload; // Assuming this is the array of new picks
            
                // Extract identifying information from the first pick
                const { player_id, event_id, season, week } = updatedPicks[0];
            
                // Create a new array of picks, replacing the old ones for this player/event/season/week
                const newNflPicks = state.event.nfl_picks.filter(pick => 
                    pick.player_id !== player_id ||
                    pick.event_id !== event_id ||
                    pick.season !== season ||
                    pick.week !== week
                ).concat(updatedPicks);
            
                return {
                    ...state,
                    event: {
                        ...state.event,
                        nfl_picks: newNflPicks
                    }
                }
        case 'SET_CURRENT_EVENT':
            return {
                ...state,
                current_event: action.payload
            }
        case 'SET_CURRENT_PLAYER':
            return {
                ...state,
                current_player: action.payload
            }
        case 'SET_CURRENT_SEASON':
            return {
                ...state,
                current_season: action.payload
            }
        case 'SET_CURRENT_WEEK':
            return {
                ...state,
                current_week: action.payload
            }
        case 'UPDATE_EVENT_STATS':
            return {
                ...state,
                event_stats: action.payload
            }
        case 'UPDATE_OVERALL_STATS':
            return {
                ...state,
                overall_stats: action.payload
            }
        case 'PRINT_STATE':
            console.log('State:', state)
            return {
                ...state,
            }
        default:
            return {
                ...state,
            };
  
  }
}
      
export default pickemReducer;
    
import React, {useState} from 'react';
import { Box, 
         VStack, 
         Flex, 
         Text, 
         Image, 
         useDisclosure, 
         ScaleFade, 
         LinkBox, 
         LinkOverlay, 
         HStack, 
         useMediaQuery,
         Spacer,
         useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

function ContentCard({ title, cost, discounted, description, imageUrl, url, onCardClick, cardName, showImages, asNewWindow, internal, action_callout }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const bgColorBase = useColorModeValue('forwardWhite.100','forwardBlue.700');
    const bgColorHover = useColorModeValue('blue.100','blue.700');

    const linkProps = internal
    ? {
        as: Link,
        to: url,
        tabIndex: 0,
      }
    : {
        href: url,
        tabIndex: 0,
        target: asNewWindow ? '_blank' : null,
      };

    const handleMouseEnter = () => {
      onOpen();
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      onClose();
      setIsHovered(false);
    };

    const handleClick = (event) => {
      if (onCardClick) {
        event.preventDefault();
        onCardClick(cardName);
      }
    };

    let price_format = ''
    let cost_color = ''

    if (discounted !== undefined) {
      price_format = 'line-through';
      cost_color = 'gray.600'
    }
    let target_path = null;
    if (asNewWindow) {
      target_path = '_blank';
    }

  return (
    <LinkBox
        as="article"
        borderWidth="0px"
        borderRadius={4}
        minWidth="300px"
        maxWidth="350px"
        overflow="hidden"
        _hover={{ textDecoration: 'none' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        boxShadow={isHovered ? '0 4px 6px rgba(70, 153, 255, 0.5)' : 'md'}
        bg={isHovered ? bgColorHover : bgColorBase}
        >
            <Box bg={isHovered ? 'black' : 'gray.600'}>
            {showImages && <Image
          src={imageUrl}
          alt={title}
          height="200px"
          objectFit="cover"
          transition="0.3s ease-in-out"
          filter={isHovered ? 'none' : 'grayscale(30%)'}
          mx="auto"
        />}
        </Box>
      <VStack p={4} spacing={2} height="100%" width="100%" alignItems="stretch">
        
        {cost !== undefined && 
        <HStack width="100%" justifyContent="space-between">
          <Text fontWeight="bold">{title}</Text>
          <VStack>
            <Text m={0} textAlign="right" color={cost_color} textDecoration={price_format}>{cost}</Text>
            {discounted !== undefined && <Text mt="0px !important" textAlign="right">{discounted}</Text>}
            </VStack>
            </HStack>}
        {cost === undefined && 
        <Flex width="100%" justifyContent="center">
          <Text fontWeight="bold">{title}</Text>
          </Flex>}
        <ScaleFade in={isOpen || isMobile || showImages===false} width="100%">
        <Text as="i" textAlign="center" fontSize="sm" noOfLines={3} width="100%">
          {description}
        </Text>
        </ScaleFade>
        <Spacer />
        {action_callout !== undefined && <HStack py={1} justifyContent="flex-end" width="100%"><Text textAlign="center" fontWeight="bold" >{action_callout}</Text><AiOutlineArrowRight fontWeight="bold" /></HStack>}
      </VStack>
      <LinkOverlay {...linkProps} />
    </LinkBox>
  );
}

export default ContentCard;
import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, ButtonGroup } from '@chakra-ui/react';
import { FaPauseCircle } from 'react-icons/fa';
import CustomSkipIcon from './CustomSkipIcon';

const WordInputButtons = ({ gameState, setGameState, handleSubmit, handleSkip, skipCount, pauseHandler, unpauseHandler }) => {

  return (
    <Box width="80%">
        <ButtonGroup flexDirection={{'base':'column','md':'row'}} width={{'base':'100%','md':'auto'}} >
        <Button type="submit" colorScheme="green" mt={4} mb={{'base':2,'md':2}} onClick={handleSubmit} boxShadow="lg">
            Submit
        </Button>
        <IconButton
          onClick={handleSkip}
          colorScheme="gray"
          shadow="lg"
          mb="2"
          mt={{'base':0,'md':4}}
          p="2"
          disabled={skipCount === 0}
          aria-label="Skip"
          icon={<CustomSkipIcon skipCount={skipCount} />}
        />
        {gameState!=='paused' && <Button
          colorScheme="gray"
          shadow="lg"
          mb="2"
          mt={{'base':0,'md':4}}
          p="2"
          aria-label="Pause"
          onClick={pauseHandler}>
          <FaPauseCircle/>&nbsp;Pause
          </Button>}
          {gameState==='paused' && <Button
          colorScheme="gray"
          shadow="lg"
          mb="2"
          mt={{'base':0,'md':4}}
          p="2"
          aria-label="unpause"
          onClick={unpauseHandler}>
          <FaPauseCircle/>&nbsp;Resume
          </Button>}
        <Button colorScheme="red" 
                shadow="lg"
                mb="2" 
                mt={{'base':0,'md':4}}
                p="2"
                onClick={() => {
                  console.log('Ending Early!');
                  setGameState('gameOver');
                }}>
          Quit
        </Button>
        </ButtonGroup>
        </Box>
  );
};

export default WordInputButtons;
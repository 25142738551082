import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';


function BlogRedirect() {
    const navigate = useNavigate();
    React.useEffect(() => {
      navigate('/', { replace: true });
    }, [navigate]);
  
    return null;
  }
  
export default BlogRedirect;
import React, { useState, useEffect, useRef } from "react";
import { Box, FormControl, Flex, Text, Button, HStack} from "@chakra-ui/react";

const WordInput = ({ letters, onChange, input, setInput, onSubmit }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [letters]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInput(newValue);
    onChange(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    onSubmit(event, input);
    setInput("");
  };

  function getIndices(input, letters) {
    const inputLower = input.toLowerCase();
    const usedIndices = new Set();
    let lastIndex = -1;
  
    const indexArray = letters.map((letter) => {
      const letterLower = letter.toLowerCase();
      let index = inputLower.indexOf(letterLower, lastIndex + 1);
  
      if (index === -1) {
        // Loop back to the beginning of the string if no suitable index is found
        let loopIndex = 0;
  
        while (loopIndex <= lastIndex) {
          if (inputLower[loopIndex] === letterLower && !usedIndices.has(loopIndex)) {
            index = loopIndex;
            break;
          }
          loopIndex++;
        }
      }
  
      if (index === -1 || usedIndices.has(index)) {
        return null;
      }
  
      usedIndices.add(index);
      lastIndex = index;
      return index;
    });
  
    return indexArray;
  }
  
  

  function getColors(indexArray) {
    const colorArray = indexArray.map((value, index, array) => {
      // If value is null, return "none"
      if (value === null) {
        return "none";
      }
  
      // If it's the first value and not null, return "green"
      if (index === 0) {
        return "green";
      }
  
      // If any value before the current index is not green, return "yellow"
      for (let i = 0; i < index; i++) {
        if (array[i] !== null && array[i] >= value) {
          return "yellow";
        }
      }
  
      // If all previous colors are green, and each previous value is greater than the value before it, return "green"
        if (
          array.slice(0, index).every((prevValue, prevIndex, prevArray) => {
            return prevIndex === 0 || prevValue > prevArray[prevIndex - 1];
          }) &&
          array.slice(0, index).every((prevValue) => prevValue !== null)
        ) {
          return "green";
        } else {
          // Otherwise, return "yellow"
          return "yellow";
        }
    });
  
    return colorArray;
  }
  


  const renderLetters = () => {
    console.log(input);

    const my_indices = getIndices(input, letters);
    const my_colors = getColors(my_indices);

    console.log(input)
    console.log(letters)
    console.log(my_indices);
    console.log(my_colors);

    const letterElements = letters.map((letter, index) => {
      const shading=my_colors[index];

      return (
        <Flex
          key={index}
          display="inline-block"
          p={0}          
          textAlign="center"
          fontWeight="bold"
          minWidth="40px"
          minHeight="40px"
          boxShadow={
            shading === "green"
              ? "inner"
              : shading === "yellow"
              ? "inner"
              : "lg"
          }
          mx="1"
          background={
            shading === "green"
              ? "green.500"
              : shading === "yellow"
              ? "yellow.500"
              : "none"
          }
          color={shading !== "none" ? "black" : "default"}
          borderRadius="xl"
          border={
            shading === "green"
              ? "4px"
              : shading === "yellow"
              ? "2px"
              : "1px"
          }
          borderColor={
            shading === "green"
              ? "green.700"
              : shading === "yellow"
              ? "yellow.700"
              : "gray.500"
          }
        >
          {letter}
        </Flex>
      );
    });
  
    return (
      <>
        <Flex height="40px" width={{'base':'100%','md':'auto'}}  fontWeight="semibold" color="default" flexDirection={{'base':'column','md':'row'}} alignItems="center">
          <Text display="inline" fontSize={{'base':'lg','md':'xl'}}>Find a word with:</Text>
          <HStack>
          <Text display="inline-block" fontSize="2xl">{letterElements}</Text>
          </HStack>
          </Flex>
      </>
    );
  };

  return (
    <Flex>
      <FormControl onSubmit={(event) => handleSubmit(event)}>
        <form>
          <Flex flexDirection="column">
          <Box>
          {renderLetters()}
          </Box>
          <Box height="70px">
          <input
            ref={inputRef}
            value={input}
            onChange={handleChange}
            placeholder="type your answer here!"
            style={{
              margintop: "25px",
              width: "100%",
              fontSize: "1.6rem",
              fontWeight: "normal",
              background: "transparent",
              outline: "none",
              border: "none",
              borderBottom: "2px solid",
              borderColor: "gray.300",
              color: "gray.700",
            }}
          />
          </Box>
          </Flex>
        </form>
      </FormControl>
    </Flex>
  );
};

export default WordInput;
import React, { useEffect, useContext, useState } from 'react';
import { Box, Heading, VStack, HStack, Link, Text, Table, Td, Tr, Th, Thead, Button, useDisclosure, useColorModeValue} from '@chakra-ui/react';
import MyHeader from '../../general/components/MyHeader';
import { PickemProvider, PickemContext } from '../handlers/context/PickemContext';
import { useEvents } from '../handlers/full/useEvents';
import { useEventStats } from '../handlers/full/useEventStats';
import { useTeams } from '../handlers/full/useTeams';
import { usePlayers } from '../handlers/full/usePlayers';
import { useGames } from '../handlers/full/useGames';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { get_week_color } from '../calc/scoring';
import JoinModal from '../elements/JoinModal';
import UserInfo from '../elements/UserInfo';
import { useAuth } from '../auth/useAuth';
import NavBar from '../elements/NavBar';
import PickWeekSelector from '../elements/PickWeekSelector';
import PlayerList from '../elements/PlayerList';

const EventContent = ({ event_id }) => {
  const { state, dispatch } = useContext(PickemContext);
  const navigate = useNavigate();
  const { syncEvent } = useEvents();
  const { syncTeams } = useTeams();
  const { syncPlayers, joinEvent } = usePlayers();
  const { syncGames } = useGames();
  const { calcEventStats } = useEventStats();
  const [isLoading, setIsLoading] = useState(true);
  const [isGamesLoading, setIsGamesLoading] = useState(true);
  const [isStatsLoading, setIsStatsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bannerBg = useColorModeValue('gray.200', 'gray.700');


  const dataRefresh = async (event_id) => {
    setIsLoading(true);
    setError(null);
    try {
        await syncEvent(event_id);
        if (!state.players || state.players.length === 0) {
          await syncPlayers();
        }
        if (!state.teams || state.teams.length === 0) {
          await syncTeams();
        }
        if (state.event && state.event.season) {
          await syncGames(state.event.season);
        }
        dispatch({ type: 'PRINT_STATE' });
      } catch (err) {
        console.error('Error refreshing data:', err);
        setError('Failed to load data. Please try again.');
      } finally {
        setIsLoading(false);
        dispatch({ type: 'PRINT_STATE' });

      }
  };

  useEffect(() => {
    console.log('Event ID:', event_id);
    dataRefresh(event_id);
  }, [event_id]);

  useEffect(() => {
    if (!isLoading && state.event && state.event.season) {
        try {
      console.log('Event or season changed, refreshing games');
      syncGames(state.event.season);
        }
        catch (err) {
          console.error('Error refreshing games:', err);
          setError('Failed to load games. Please try again.');
        }
        finally {
          setIsGamesLoading(false);
        }
    }
  }, [state.event, isLoading]);

  useEffect(() => {
    const { event, games, players } = state;
    if (
        !isLoading &&
        !isGamesLoading &&
        isStatsLoading &&
        event &&
        event.configuration &&
        event.nfl_picks &&
        games &&
        players &&
        players.length > 0 &&
        games.length > 0 &&
        event.nfl_picks.length > 0
      )  {
      console.log('Calculating event stats');
      calcEventStats();
      setIsStatsLoading(false)
    } else {
      console.log('Not all data available for stat calculation');
      console.log('Event:', !!event);
      console.log('NFL Picks:', !!(event && event.nfl_picks));
      console.log('Games:', !!games, games ? games.length : 0);
      console.log('Players:', !!players, players ? players.length : 0);
    }
  }, [state.event, state.games, state.players, calcEventStats]);

  const handleJoinClick = () => {
    console.log('Joining event:', state.event.id);
    onOpen();
  };

  const existingJoinClick = () => {
    console.log('Joining event:', state.event.id);
    const player_id = state?.players?.find(player => player.user_id === state?.user?.id)?.id || null;
    const user_id = state?.user?.id;
    const event_id = state?.event?.id;
    console.log('Joining event:', player_id, user_id, event_id);
    joinEvent({player_id, user_id, event_id})
  }

  const handleWeekChange = (week) => {
    console.log('Week changed:', week);
    const player_id = state?.players?.find(player => player.user_id === state?.user?.id)?.id || null;
    navigate(`/pickem/event/${state?.event?.id}/picks/${week}/player/${player_id}`);
  }

  if(!state.event) {
    return (
      <Box py={8} px={{ base: 4, md: 8 }}>
        <Heading as="h1" size="xl" mb={4}>Loading...</Heading>
      </Box>
    );
  }

  const isSeasonComplete = state?.current_season > state?.event?.season;
  const hasSeasonStarted = state?.current_season >= state?.event?.season && state?.current_week > 1;
  const start_week = state?.event?.configuration?.picks?.start_week;
  const end_week = state?.event?.configuration?.picks?.end_week;

  const current_player = state?.players?.find((player) => player.user_id === state?.user?.id) || null;

  return (
    <>
      <MyHeader />
      <NavBar />
      <Box py={8} px={{ base: 4, md: 8 }}>
        <HStack width="100%" justifyContent="space-between">
        <Heading as="h1" size="xl" mb={4}>Welcome to {state.event && state.event.event_name}!</Heading>
        {/*
               // Three cases: already joined, signed in but not joined, not signed in
               // #1: Already joined:
                */}
                { current_player && 
                !hasSeasonStarted && !isSeasonComplete &&
                state.event.nfl_player_events.find((pe) => pe.player_id === current_player?.id) 
                &&
                <Text fontStyle="oblique" textColor="gray.600">You have already joined!</Text>}
                {// #2: Signed in but not joined:
                state.user && 
                !hasSeasonStarted && !isSeasonComplete &&
                !state.event.nfl_player_events.find((pe) => pe.player_id === current_player?.id) 
                &&
                <Button colorScheme="teal" size="lg" width="100%" onClick={() => existingJoinClick()}>Join this event!</Button>
                }
                {// #3: Not signed in:
                !state.user && !isSeasonComplete && !hasSeasonStarted && <Button colorScheme="teal" size="lg" width="100%" onClick={() => handleJoinClick()}>Join this League!</Button>
                }
        </HStack>
        <VStack width="100%">
            {/* If the season is complete, let's show a banner */}
            {isSeasonComplete && 
            <Box width="100%" textAlign="center" bg="teal.400" p={2} flex="1">Season Complete!</Box>}

            {/* If the season hasn't started yet, let's show the player box to let players join! */}
            {!isSeasonComplete && !hasSeasonStarted &&
            <Box width="100%" textAlign="center" bg={bannerBg} p={2} flex="1">Season has not started</Box>}
            {/* Show the week selector to let players see/set their picks */}
            <Heading as="h2" size="md">Set/View Picks:</Heading>
            <PickWeekSelector 
            start_week={start_week} 
            end_week={end_week} 
            selected_season={state.event.season} 
            selected_week={null}
            handleWeekChange={handleWeekChange} />
            { 
            /*!hasSeasonStarted && */
            <>
            <PlayerList />
            </>
            }

            {/* To do - built last week and current week summaries. Last week = results, this week = picks in/show picks */}
            {!isSeasonComplete && hasSeasonStarted && 
            <>
            <Box flex="1">Current Week Pick Summary</Box>
            <Box flex="1">Last Week Results</Box>
            </>
            }
            
            {/* Show the full season summary */}
            {!isStatsLoading && (hasSeasonStarted || isSeasonComplete) && 
            <>
            <Box flex="1" fontSize="xl" fontWeight="bold">{isSeasonComplete ? 'Final' : 'Current'} Standings</Box>
            <Table size="sm">
    <Thead>
        <Tr>
            <Th colSpan={4}>Official</Th>
            <Th colSpan={5}>Bonuses</Th>
            <Th colSpan={6}>Point Composition</Th>

        </Tr>
        <Tr>
            <Th>Rank</Th>
            <Th>Player</Th>
            <Th>Points</Th>
            <Th>SOV</Th>
            <Th>Key Points</Th>
            <Th>1H Points</Th>
            <Th>2H Points</Th>
            <Th>Playoff Points</Th>
            <Th>Week Wins</Th>
            <Th>Base</Th>
            <Th>Key</Th>
            <Th>Underdog</Th>
            <Th>First Time Winner</Th>
            <Th>Blowout</Th>
            <Th>Excluded Weeks</Th>
        </Tr>
    </Thead>
    {state.overall_stats.player_summary.map((player, index) => {
        const leadingStats = state.overall_stats.leading_values;

        return (
            <Tr key={player.id} p={0}>
                <Td>{index + 1}</Td>
                <Td>{player.player_name}</Td>
                <Td minWidth="450px" p={0} m={0} style={{ backgroundColor: player.valid_total_points >= leadingStats.valid_total_points ? '#FFD700' : 'transparent' }}>
                    <div style={{
                        width: `${(player.valid_total_points / leadingStats.valid_total_points) * 100}%`,
                        backgroundColor: 'var(--chakra-colors-green-500)',
                        height: '28px',
                        textAlign: 'left',
                        paddingRight: '5px',
                        color: 'white',
                        fontWeight: 'bold'
                    }}>
                        {player.valid_total_points.toFixed(0)}
                    </div>
                </Td>
                <Td style={{ backgroundColor: player.sov >= leadingStats.sov ? '#FFD700' : 'transparent' }}>
                    {player.sov}
                </Td>
                <Td style={{ backgroundColor: player.key_points >= leadingStats.key_points ? '#FFD700' : 'transparent' }}>
                    {player.key_points}
                </Td>
                <Td style={{ backgroundColor: player.fh_points >= leadingStats.fh_points ? '#FFD700' : 'transparent' }}>
                    {player.fh_points.toFixed(0)}
                </Td>
                <Td style={{ backgroundColor: player.sh_points >= leadingStats.sh_points ? '#FFD700' : 'transparent' }}>
                    {player.sh_points.toFixed(0)}
                </Td>
                <Td style={{ backgroundColor: player.po_points >= leadingStats.po_points ? '#FFD700' : 'transparent' }}>
                        {player.po_points.toFixed(0)}
                </Td>
                <Td style={{ backgroundColor: player.best_weeks >= leadingStats.best_weeks ? '#FFD700' : 'transparent' }}>
                    {player.best_weeks.toFixed(1)}
                </Td>
                <Td style={{ backgroundColor: player.base_points >= leadingStats.base_points ? '#FFD700' : 'transparent' }}>
                    {player.base_points.toFixed(0)}
                </Td>
                <Td style={{ backgroundColor: player.key_points >= leadingStats.key_points ? '#FFD700' : 'transparent' }}>
                    {player.key_points}
                </Td>
                <Td style={{ backgroundColor: player.underdog_points >= leadingStats.underdog_points ? '#FFD700' : 'transparent' }}>
                    {player.underdog_points}
                    </Td>
                <Td>{player.first_time_winner_points}</Td>
                <Td>{player.blowout_points}</Td>
                <Td>{player.excluded_points.toFixed(0)}</Td>
            </Tr>
          )
})
}

</Table>
</>
}
{/* Show the weekly summary */}
{!isStatsLoading && (hasSeasonStarted || isSeasonComplete) &&
<>
            <Box flex="1" fontSize="xl" fontWeight="bold">Week-by-Week Results</Box>
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th>Rank</Th>
                        <Th>Player</Th>
                        {!isStatsLoading && Object.keys(state.event_stats[state.overall_stats.player_summary[0].id].weeklyStats).map((week) => {
                            return (
                                <Th>{week}</Th>
                            )
                        })}
                    </Tr>
                </Thead>
            {!isStatsLoading && state.overall_stats.player_summary.map((player, index) => {
                return (
                    <Tr p={0} m={0}>
                        <Td>{index+1}</Td>
                        <Td>{player.player_name}</Td>
                        {Object.keys(state.event_stats[player.id].weeklyStats).map((week) => {

                            if(state?.event?.season === state.current_season && week >= state.current_week) {
                                return (
                                    <Td>-</Td>
                                )
                            }

                            const weekStats= state.event_stats[player.id].weeklyStats[week];
                            

                            return (
                                <Td bgColor={get_week_color(weekStats.total_points)}>{week ? weekStats.total_points.toFixed(0) : 0}</Td>
                            )
                        }
                        )}

                    </Tr>
                )
            })
            }
            </Table>
            </>
}
        </VStack>
      </Box>
      <JoinModal isOpen={isOpen} onClose={onClose} eventId={event_id}/>
    </>
  );
};

const EventPage = () => {
  const { event_id } = useParams();
  console.log('Event ID from params:', event_id);
  const event_as_int = parseInt(event_id);
  const { user, loading } = useAuth();

  return (
    <PickemProvider>
      <EventContent event_id={event_as_int} />
    </PickemProvider>
  );
};

export default EventPage;
const testimonials = [
    {
      name: 'Will Wilson',
      role: 'CEO @ Antithesis',
      category: 'SaaS Growth',
      highlight: '',
      fullText: "Ben joined us at Antithesis for three months and was immensely helpful. \
      He dove in, quickly developed a thorough understanding of our business, and then worked with our team to establish a roadmap \
      for how we could scale our professional services team as we grew. Ben's integration of industry best practices, gleaned from \
      his extensive experience, was both insightful and adeptly customized to our specific business model. \
      Notably, he devised a comprehensive, yet straightforward, economic assessment model for our client data. \
      This tool not only enhanced our client relationships but also solidified our value proposition, setting a robust foundation for our expansion. \
      Ben's contributions have left an enduring mark, positioning Antithesis for sustained success.",
      profileUrl: '/headshots/wwilson.jpg',
      linkedInUrl: 'https://www.linkedin.com/in/will-wilson-330276112/',
    },
    // Add more objects as needed
  ];

  
  export {testimonials};
// SignInModal.jsx
import React, { useState, useContext } from 'react';
import { PickemContext } from '../handlers/context/PickemContext';
import { Link, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Button, FormControl, Input } from "@chakra-ui/react";
import { supabase } from '../../api/supabaseClient';

const SignInModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useContext(PickemContext);
    const [error, setError] = useState('');

  const handleSubmit = async () => {
    console.log('sign in', email, password);
    await signIn(email, password);
    onClose();
  };

  const handlePasswordReset = async () => {
      if (!email || email === '') {
          setError('Please enter your email first');
          return;
      }
      try {
          const { error } = await supabase.auth.resetPasswordForEmail(email);
          if (error) throw error;
          alert('Password reset email sent. Please check your inbox.');
      } catch (error) {
          setError(error.message);
      }
  };


  if (!isOpen) return null;

return (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Sign In</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormControl>
                    <Input
                        type="email"
                        placeholder="Email"
                        variant="filled"
                        bgColor="forwardWhite.500"
                        size="lg"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        m={2}
                        required
                    />
                </FormControl>
                <FormControl>
                    <Input
                        m={2}
                        type="password"
                        placeholder="Password"
                        variant="filled"
                        bgColor="forwardWhite.500"
                        size="lg"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </FormControl>
                <Link onClick={handlePasswordReset} color="blue.500" mt={2} display="inline-block">
  Forgot Password?
</Link>
{error && <Text color="red.500" mt={2}>{error}</Text>}
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="teal" width="100%" onClick={() => handleSubmit()}>Sign In</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
);
};

export default SignInModal;
import React, {useState} from 'react';
import { Box, Heading, Text, Center, VStack, 
  HStack, Flex, Button, ButtonGroup, SimpleGrid, Grid, GridItem, useColorModeValue, useDisclosure,
  Divider, Image,
  UnorderedList,
  ListItem
 } from '@chakra-ui/react';
import Navbar from '../../general/components/Navbar';
import MyHeader from '../../general/components/MyHeader';
import OurServices from '../content/OurServices';
import Footer from '../../general/components/Footer';
import Testimonials from '../content/Testimonials';
import OurExamples from '../content/OurExamples';
import AboutConsulting from '../content/AboutConsulting';
import HowWeWork from '../content/HowWeWork';
import ProductModal from '../content/ProductModal';
import SubstackFeed from '../../general/components/SubstackFeed';
import OpenForBusiness from '../../general/components/OpenForBusiness';
import {Link as ReachLink} from 'react-router-dom';
import CalendlyButtonModal from '../../general/components/CalendlyButtonModal';
import CaseStudyPromos from '../content/CaseStudyPromos';


const Consulting = () => {
  const bgColor = useColorModeValue("gray.100","gray.700");
  const topBgColor = useColorModeValue("forwardWhite.100","forwardBlue.800");
  const { isOpen: isProductOpen, onOpen: onProductOpen, onClose: onProductClose } = useDisclosure();
  const [selectedCardName, setSelectedCardName] = useState(null);

      const trackCustomEvent = (eventName) => {
      if (window.plausible) {
        window.plausible(eventName);
      }
    };


  const handleCardClick = (cardName) => {
    const eventName = "Click on: "+cardName
    console.log(eventName);
    trackCustomEvent(eventName);
    setSelectedCardName(cardName);
    onProductOpen();
  }

  return (
    <>
    <MyHeader />
    <Navbar />
    <Box py={8} px={{ base: 4, md: 8 }}>
      <Center maxWidth="100%">
        <VStack width="100%" maxWidth="1000px">
      <HStack><Image src="/home/m_consulting_2.png" height="40px" borderRadius="10px"></Image><Heading as="h2" mb={1}>
        Consulting & Services
      </Heading></HStack>
      <Divider py={2} />
      <Text py={2} fontSize="2xl" fontWeight="semibold" maxWidth="1000px">
        We help clients develop modern tech solutions with business fluency
      </Text>
      <VStack maxWidth="800px" fontSize="lg">
      <Text>Most companies struggle to translate the vast swaths of data they have quickly into actionable insights and products that actually drive business value.</Text>
      <Text>
      We address this by bridging gaps between technical and business teams and from executives down to individual contributors.
      </Text>
      <Text>We specialize in: </Text>
      <Box
        width="100%"
        textAlign="center"
        boxShadow="md"
        borderRadius="md"
        p={4}
        backgroundColor={topBgColor}
      >
        <HStack width="100%" textAlign="center">
          <VStack flex="1" spacing={3}>
            <Heading fontSize="xl" as="h5">
              Data, Analytics, & AI Development
            </Heading>
            <UnorderedList fontSize="sm" fontStyle="oblique" listStyleType={'none'}>
              <ListItem>Data Architecture & Strategy</ListItem>
              <ListItem>Analytic Services</ListItem>
              <ListItem>Data & AI Development</ListItem>
            </UnorderedList>
            <Button as={ReachLink} to="./data" colorScheme="green" variant="solid" size="sm">
              Learn More
            </Button>
          </VStack>
          <Divider orientation="vertical" height="120px" borderWidth="2px" colorScheme="blackAlpha"/>
          <VStack flex="1" spacing={3}>
            <Heading fontSize="xl" as="h5">
              Scaling B2B Customer Success
            </Heading>
            <UnorderedList fontSize="sm" fontStyle="oblique" listStyleType={'none'}>
              <ListItem>CS Best Practices & Growth Plans</ListItem>
              <ListItem>Business Value Calculation</ListItem>
              <ListItem>Product Strategy</ListItem>
            </UnorderedList>
            <Button as={ReachLink} to="./saas" colorScheme="green" variant="solid" size="sm">
              Learn More
            </Button>
          </VStack>
        </HStack>
      </Box>
      </VStack>
      {/*<UnorderedList fontSize="lg" maxWidth="800px">
        <ListItem>Ad Hoc Analytic Services</ListItem>
        <ListItem>SaaS Client Services Growth Planning</ListItem>
        <ListItem>Data & AI Architecture and Strategy</ListItem>
        <ListItem>Data & AI Application Development</ListItem>
        <ListItem>Product Management as a Service</ListItem>
  </UnorderedList>*/}
         <Divider py={4} />
         <OpenForBusiness available={false} />
         <Text>Want to go faster?</Text>
         {/*<CalendlyButton url={'https://calendly.com/benjaminmumma/intro'} buttonText={'Schedule a 30 minute call'} widgetType={'popup'}/>*/}
         <CalendlyButtonModal url={'https://calendly.com/benjaminmumma/intro'} text={'Schedule a 30 minute call'} />
         <Text fontStyle="oblique">Even faster? Call:</Text>
         <Text>703-615-8408</Text>
         <Divider py={4} />
         <CaseStudyPromos tn={1} hidden={0} />


         <Divider py={4} />
          <OurServices onCardClick={handleCardClick} hidden={false}/>

      <Heading as="h3" marginBottom="12px !important" marginTop="12px !important">About Us</Heading>
      <Grid width="100%" 
       templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(2, 1fr)',
        xl: 'repeat(2, 1fr)',
      }}
      gap={2} px={2}>
        <GridItem height="100%" bgColor={topBgColor} boxShadow="lg">
          <AboutConsulting />
        </GridItem>
        <GridItem height="100%" bgColor={topBgColor} boxShadow="lg">
          <HowWeWork />
        </GridItem>
      </Grid>
      <Divider py={4} />
      <SubstackFeed
        title="Latest Blog Content"
        blogs={['bizlandia']}
        numberOfPosts={5}
      />
      <Divider py={4} />
      <Testimonials tn={0} px={4} hidden={0} />
      <Divider py={4} />
      <OurExamples />
      </VStack>
      </Center>
    </Box>
    <ProductModal productName={selectedCardName} isOpen={isProductOpen} onClose={onProductClose} />
    <Footer />
    </>
  );
};

export default Consulting;
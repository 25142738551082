import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export const HomeButton = props => {
  const navigate=useNavigate();

  return (
    <IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color="current"
      marginLeft="2"
      onClick={() => navigate('/')}
      icon={<FaHome />}
      {...props}
    />
  );
};

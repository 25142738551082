import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const GameHeader = () => {
  return (
    <Box
      as="header"
      mb="2"
      py="4"
      px="6"
      bgGradient="linear(to-r, teal.500, blue.500)"
      borderRadius="md"
      boxShadow="xl"
    >
      <Heading
        as="h1"
        fontSize={['2xl', '3xl', '4xl']}
        fontWeight="extrabold"
        color="white"
        textAlign="center"
      >
        Expound!
      </Heading>
      <Text fontSize="lg" color="white" textAlign="center">
        Just another fast & fun word game!
      </Text>
    </Box>
  );
};

export default GameHeader;
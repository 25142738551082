

const saas_competencies = [
    {cname:'Client Services Growth',
    via_partners:false,
    points:[
        'We\'ll get an understanding of your business model and client structure.',
        'Based on that, we\'ll provide recommendations and best practices based on how larger, high-performance SaaS companies operate.',
        'We\'ll provide training sessions for your team to help to ensure that recommendations are translated into action.',
    ]},
    {cname:'Business Value Assessment',
    via_partners:false,
    points:[
        'Define a framework for assessing the value generated for a client, using quantitative approaches.',
        'Work with your clients to assess the framework and come to a mutually agreeable approach.',
        'Systematize how you track value generated and how you communicate that to your clients.',
    ]},
    {cname:'Product Management Services',
    via_partners:true  ,  
    points:[
        'As you grow, you may struggle to translate the vision of leadership into delivered product features.',
        'We can act as a short-to-mid-term product manager to help you get through this phase of growth.',
        'We\'ll establish structures and processes that will make your first full-time product hire successful.',
    ]},
    {cname:'Financial Planning',
    via_partners:true,
    points:[
        'Assess your businesses books and provide high level guidance on achieving your mid-to-long term financial targets.',
        'Offer templates and setup for a "CFO out of the box" solution to get you started on the right foot.',  
    ]},
]
export {saas_competencies};
import React from 'react';
import { Box, Text, Center, VStack, useColorModeValue } from '@chakra-ui/react';


const Timer = ({ hasTimer, remainingTime }) => {
  const base_timer_color = useColorModeValue("gray.700","gray.200");
  // Your other component code...
  const getTimerColor = () => {
    if (remainingTime <= 5) {
      return 'red.500';
    } else if (remainingTime <= 10) {
      return 'orange.500';
    } else if (remainingTime <= 15) {
      return 'yellow.600';
    } else {
      return base_timer_color;
    }
  };
  const pulseEffect = () => {
    if (remainingTime === 15 || remainingTime === 10 || remainingTime === 5) {
      return {
        animationName: 'pulse',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: 3,
        '@keyframes pulse': {
          '0%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.2)' },
          '100%': { transform: 'scale(1.1)' },
        },
      };
    }
    return {};
  };
  if(!hasTimer) {
    return (
      <Box p="3">
        <Center>
          <VStack>
        <Text m="0" fontSize="xs">Time Left:</Text>
        <Text
          fontSize={{'base':'md','md':'xl'}}
          fontWeight="bold"
          color="default"
        >
          --
        </Text>
        </VStack>
        </Center>
      </Box>
    ); 
  }

  return (
    <Box p="3">
      <Center>
          <VStack>
      <Text m="0" fontSize="xs">Time Left:</Text>
      <Text
        fontSize={{'base':'md','md':'xl'}}
        fontWeight="bold"
        color={getTimerColor()}
        sx={pulseEffect()}
      >
        {remainingTime > 0 ? remainingTime : 0} s
      </Text>
      </VStack>
      </Center>
    </Box>
  );
};

export default Timer;
import React, {useState} from 'react';
import { Box, Heading, Image, Text, Link, Center, VStack, HStack, Flex, Button, ButtonGroup, useColorModeValue, IconButton } from '@chakra-ui/react';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai'
import { testimonials } from '../data/testimonials';

const Testimonials = ({tn, hidden, category = null}) => {

    const [tNum, setTNum] = useState(tn);
    const bgColor = useColorModeValue("forwardWhite.100","forwardBlue.700");
    //const highlightColor = useColorModeValue("blue.700","teal.200")

    const testimonial = testimonials[tNum];
    let n_testimonials = testimonials.length;
    if (category !== null) {
        n_testimonials = testimonials.filter(t => t.category === category).length;
    }

    function handle_prev() {
        if(tNum > 0) {
            setTNum(tNum-1);
        }
        else {
            setTNum(n_testimonials-1);
        }
    }

    function handle_next() {
        if(tNum === n_testimonials-1) {
            setTNum(0);
        }
        else {
            setTNum(tNum+1);
        }
    }

    if(hidden === 1) {
        return <></>;
    }

    
  return (
    <>
    <VStack>
    <Heading as="h3" mt={3}>Testimonials</Heading>
    <Box>
        {n_testimonials > 1 && <>
    <Button variant="ghost" size="sm" m={2} onClick={handle_prev}><AiOutlineArrowLeft /> Previous</Button>
    <Button variant="ghost" size="sm" m={2} onClick={handle_next}>Next <AiOutlineArrowRight /></Button>
    </>}
    </Box>
    <Box width="100%" bgColor={bgColor} p={4}  boxShadow="lg">
        <HStack flexDirection={{'base':'column','md':'row'}}>
        <Box><Image src={testimonial.profileUrl} minWidth="150px" maxWidth="150px" borderRadius="md" boxShadow="lg"></Image></Box>
        <Box>
        <Heading size="md">{testimonial.name} ({testimonial.role}) says:</Heading>
        <Text fontSize="lg" fontWeight="bold" fontStyle="italic">{testimonial.highlight}</Text>
        <Text>{testimonial.fullText}</Text>
        <Text fontStyle="italic">See {testimonial.name} on <Link color="teal" href={testimonial.linkedInUrl}>LinkedIn</Link></Text>
        </Box>
        </HStack>
    </Box>
    </VStack>
    </>
  );
};

export default Testimonials
import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Flex,
  Button,
  useColorModeValue,
  Stack,
  VStack,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import axios from 'axios';
import {API_BASE_URL} from '../../api/constants';
import { parse, set } from 'date-fns';


const InputCard = ({ onResults }) => {
  // Dark mode background and text colors
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.700');
  const color = useColorModeValue('gray.800', 'white');

  // States for all inputs
  const [rawRRValue, setRawRRValue] = useState('$1,000'); // Raw input value
  const [currentRunRate, setCurrentRunRate] = useState(1000);
  const [currentRetention, setCurrentRetention] = useState(98.00);
  const [goalRunRate, setGoalRunRate] = useState(3500);
  const [rawGValue, setRawGValue] = useState('$3,500'); // Raw input value
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(() => {
        const currentDate = new Date();
        const threeYearsFromNow = new Date(currentDate.getFullYear() + 3, currentDate.getMonth(), currentDate.getDate());
        return threeYearsFromNow.toISOString().split('T')[0];
    });
  const [currentNewBusiness, setCurrentNewBusiness] = useState(100);
  const [rawNBValue, setRawNBValue] = useState('$100'); // Raw input value
  const [currentNBGrowth, setCurrentNBGrowth] = useState(15);


    // Custom formatter for displaying value as a percentage
    const formatPercent = (val) => `${val}%`;
    const parsePercent = (val) => val.replace(/%$/, '');

    const formatDollars = (val) => {
        // Convert the number to a string with US currency formatting
        return `$${Number(val).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      };
    const parseDollars = (val) => {
        // Ensure val is a string
        const new_val = val.toString().replace(/[$,]/g, '');
        console.log(val.toString().replace(/[$,]/g, ''));
        return new_val;
      };

      const handleDollarInputChange = (valueString,field) => {
        const parsedValue = parseDollars(valueString); // Parse input to number
        if (field === 'crr') {
        setRawRRValue(valueString); // Update raw input
        setCurrentRunRate(parsedValue); // Update numeric value
        }
        else if (field === 'grr') {
            setRawGValue(valueString); // Update raw input
            setGoalRunRate(parsedValue); // Update numeric value
            }
        else if (field === 'cnb') {
            setRawNBValue(valueString); // Update raw input
            setCurrentNewBusiness(parsedValue); // Update numeric value
            }
      };
    
      const handleDollarInputBlur = (field) => {
        // Format the value when input loses focus
        if(field === 'crr') {
            setRawRRValue(formatDollars(currentRunRate));
        }
        else if (field === 'grr') {
            setRawGValue(formatDollars(goalRunRate));
        }
        else if (field === 'cnb') {
            setRawNBValue(formatDollars(currentNewBusiness));
        }
        
      };
        

  // Handler for when any input changes
  const handleInputChange = (value, setValue) => {
    setValue(value);
  };

  // Handler for date changes
  const handleDateChange = (value, setValue) => {
    setValue(value);
  };

  // Function to send data to the backend and fetch results
  const calculateResults = async () => {
        //trackCustomEvent('Math 24 Solver Run');
        console.log(API_BASE_URL);
        //event.preventDefault();

        const input = {
            start_date: startDate,
            end_date: endDate,
            current_rate: currentRunRate,
            current_nb: currentNewBusiness,
            goal_rate: goalRunRate,
            starting_retention: currentRetention/100.,
            retention_interval: 0,
            starting_nb_growth: currentNBGrowth/100.,
            nb_interval: 0,
          };
          console.log(input);

        try {
          const response = await axios.post(API_BASE_URL+'arr/', input);
          onResults(response.data);

          console.log(response);


          if (!response.statusText === 'OK') {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          console.log(response);
        } catch (err) {
          toast({
            title: 'An error occurred.',
            description: `Could not calculate results: ${err.message}`,
            status: 'error',
            duration: 9000,
            isClosable: true,
        });
        }
      };
    

  // Collect all input values and pass them to the parent component
    // Submit Button
    const handleSubmit = () => {
        calculateResults();
      };

  return (
    <Box bg={bgColor} color={color} p={4} boxShadow="md" borderRadius="lg">
      <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
        {/* Input for Current Run Rate */}
        <VStack flex={2}>
        <FormControl>
        <Tooltip cursor="pointer" label="Your current monthly recurring revenue under contract.">
            <FormLabel>Current $MRR</FormLabel>
            </Tooltip>
            <NumberInput 
                value={rawRRValue}
                onChange={(valueString) => handleDollarInputChange(valueString, 'crr')}
                onBlur={() => handleDollarInputBlur('crr')}
                precision={2}
                step={1000}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </FormControl>





        <FormControl>
        <Tooltip cursor="pointer" label="Your current monthly retention percentage, or how much of your existing business you keep on average each month.">
      <FormLabel>Cur. Monthly Retention %</FormLabel>
      </Tooltip>
            <NumberInput 
                value={currentRetention} 
                onChange={(valueString) => handleInputChange(parsePercent(valueString), setCurrentRetention)}
                precision={2}
                step={0.5} // Step by whole percentage points
                min={0}
                max={150}
                format={formatPercent}
                parse={parsePercent}
            >
            <NumberInputField />
            <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
        </FormControl>
        </VStack>




        {/* Input for Current New Business */}
        <VStack flex={2}>
        <FormControl>
        <Tooltip cursor="pointer" label="Your current new business dollars generated per month.">
          <FormLabel>Cur. New Business $/mo</FormLabel>
          </Tooltip>
          <NumberInput 
            value={rawNBValue}
            onChange={(valueString) => handleDollarInputChange(valueString, 'cnb')}
                onBlur={() => handleDollarInputBlur('cnb')}
            precision={2}
            step={1000}
            >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
            </NumberInput>
        </FormControl>




        <FormControl>
        <Tooltip cursor="pointer" label="Your current rate of growth in generating new business (annualized).">
          <FormLabel>Annual NB Growth %</FormLabel>
          </Tooltip>
          <NumberInput 
                value={currentNBGrowth} 
                onChange={(valueString) => handleInputChange(parsePercent(valueString), setCurrentNBGrowth)}
                precision={2}
                step={1} // Step by whole percentage points
                min={0}
                max={150}
                format={formatPercent}
                parse={parsePercent}
            >
            <NumberInputField />
            <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
        </FormControl>
        </VStack>
        {/* Input for Exit Run Rate Requirement */}
        <VStack flex={2}>
        <FormControl>
        <Tooltip cursor="pointer" label="Your goal for Monthly Recurring Revenue.">
            <FormLabel>
              Goal $MRR
            </FormLabel>
          </Tooltip>
          <NumberInput 
            value={rawGValue}
            onChange={(valueString) => handleDollarInputChange(valueString, 'grr')}
                onBlur={() => handleDollarInputBlur('grr')}
            precision={2}
            step={1000}
            >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
            </NumberInput>
        </FormControl>
        </VStack>

        {/* Input for Start Date */}
        <VStack flex={1}>
        <FormControl>
          <Tooltip cursor="pointer" label="The date you want to start your MRR growth calculations.">
          <FormLabel>Start Date</FormLabel>
          </Tooltip>
          <Input type="date" value={startDate} onChange={(e) => handleDateChange(e.target.value, setStartDate)} />
        </FormControl>
        {/* Input for Exit Date */}
        <FormControl>
          <Tooltip cursor="pointer" label="The date by which you are trying to hit your MRR goal.">
          <FormLabel>Exit Date</FormLabel>
          </Tooltip>
          <Input type="date" value={endDate} onChange={(e) => handleDateChange(e.target.value, setEndDate)} />
        </FormControl>
        </VStack>

        {/* ... more inputs for other states */}

        {/* Submit Button */}
        <VStack flex={1}>
        <Button colorScheme="blue" onClick={handleSubmit} mt={4}>Calculate</Button>
        </VStack>
      </Stack>
    </Box>
  );
};

export default InputCard;

import React, { useState } from 'react';
import { Box, Flex, Link, useColorModeValue, Menu, Image, HStack, Text,
  MenuButton,
  MenuList,
  MenuItem,
  } from '@chakra-ui/react';
  import { FaChevronDown } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const Navbar = ({session, ...props}) => {
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const [isOpen, setIsOpen] = useState(false);
    const [isAbOpen, setIsAbOpen] = useState(false);

  return (
    <Box backgroundColor={bgColor} py={4} fontSize={{'base':'xs','md':' md','lg':'lg'}} {...props}>
      <Flex alignContent="center" alignItems="center" justifyContent="center" spacing={{'base':1,'md':2,'lg':4}}>
        <Link as={RouterLink} to="/" fontWeight="bold" mx={{'base':2,'md':2,'lg':4}}>
          Home
        </Link>
        <Link as={RouterLink} to="/consulting" fontWeight="bold" mx={{'base':2,'md':2,'lg':4}} spacing={{'base':1,'md':2,'lg':4}}>
        <Flex alignItems="center" alignContent="center" flexDirection={{'base':'column','md':'row'}}>
          <Image height="20px" width="20px" mx={{'base':0,'md':1}}  borderRadius="5px" src="/home/m_consulting_2.png"></Image><Text>Consulting</Text></Flex>
        </Link>
        <Link as={RouterLink} to="https://www.theforwardapp.com"  fontWeight="bold" mx={{'base':2,'md':2,'lg':4}}>
        <Flex alignItems="center" alignContent="center" flexDirection={{'base':'column','md':'row'}} spacing={{'base':1,'md':2,'lg':4}}>
          <Image height="20px" width="20px" borderRadius="5px" src="/home/forward_logo_v1_min.png" mx={{'base':0,'md':1}}></Image><Text>Forward</Text>
          </Flex>
        </Link>
        <Link as={RouterLink} to="https://www.intellect.email"  fontWeight="bold" mx={{'base':2,'md':2,'lg':4}}>
        <Flex alignItems="center" alignContent="center" flexDirection={{'base':'column','md':'row'}} spacing={{'base':1,'md':2,'lg':4}}>
          <Image bg="white" height="20px" width="20px" borderRadius="5px" src="/home/ii-v1.png" mx={{'base':0,'md':1}}></Image><Text textAlign={'center'}>Intellect Inbox</Text>
          </Flex>
        </Link>
        <Box onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}>
        <Menu isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}>
          <MenuButton as={Link}  fontWeight="bold" mx={{'base':2,'md':2,'lg':4}}>
            More
          </MenuButton>
          <MenuList>
            <MenuItem as={RouterLink} to="/mrr">
              MRR Calculator
            </MenuItem>
            <MenuItem as={RouterLink} to="/expound">
              Expound Word Game
            </MenuItem>
            <MenuItem as={RouterLink} to="/math24">
              Math24 Solver
            </MenuItem>
          </MenuList>
        </Menu>
        </Box>
        <Box onMouseEnter={() => setIsAbOpen(true)}
          onMouseLeave={() => setIsAbOpen(false)}>
        <Menu isOpen={isAbOpen}
          onClose={() => setIsAbOpen(false)}
          onOpen={() => setIsAbOpen(true)}>
          <MenuButton as={Link}  fontWeight="bold" mx={{'base':2,'md':2,'lg':4}}>
            About
          </MenuButton>
          <MenuList>
          <MenuItem as={RouterLink} to="/about">
              About Mumma Labs
            </MenuItem>
            <MenuItem as={RouterLink} to="/tos">
              Terms of Service
            </MenuItem>
            <MenuItem as={RouterLink} to="/privacy">
              Privacy Policy
            </MenuItem>
          </MenuList>
        </Menu>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;
import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link, HStack, Divider, Badge} from '@chakra-ui/react';
import { fontSizes, headerSizes, bigFontSizes, bigHeaderSizes, smallFontSizes } from '../../general/responsive.js'
import {AiFillMail, AiFillHome, AiFillPhone} from 'react-icons/ai'

const ResumeHead = () => {
  return (
    <Box padding={2} w="100%">
        <Heading as="h2" size={bigHeaderSizes} textAlign="center">Benjamin Mumma</Heading>
        <HStack w="100%" justifyContent="space-between" spacing={{ base: 0, md: 20 }} py={2} flexDirection={{ base: 'column', md: 'row',print:'row' }}>
          <HStack width="100%" justifyContent='center'><AiFillMail /><Badge colorScheme="blue">ben@mumma.co</Badge></HStack>
          <HStack width="100%" justifyContent='center'><AiFillHome /><Badge colorScheme="blue">Dallas, TX</Badge></HStack>
          <HStack width="100%" justifyContent='center'><AiFillPhone /><Badge colorScheme="blue">703-615-8408</Badge></HStack>
        </HStack>
        <Divider />
    </Box>
  )
}

export default ResumeHead;
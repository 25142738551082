import { Text } from '@chakra-ui/react';

function RoundResult({ round, letters, word, valid, timeTaken, score }) {
    return (
        <>
      <Text color={valid ? 'green.600' : 'red.600'}>
        R{round}: [{letters.join(',')}] - {word.toUpperCase()} - {score} pts
      </Text>
      </>
    );
  }

export default RoundResult;
import React, { useEffect, useContext, useState, useMemo} from 'react';
import { Box, Heading, VStack, HStack, Text, Link, Button, Alert, Center, useColorModeValue} from '@chakra-ui/react';
import MyHeader from '../../general/components/MyHeader';
import { PickemProvider, PickemContext } from '../handlers/context/PickemContext';
import { useEvents } from '../handlers/full/useEvents';
import { useTeams } from '../handlers/full/useTeams';
import { usePlayers } from '../handlers/full/usePlayers';
import { useGames } from '../handlers/full/useGames';
import { Route, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import GameRow from '../elements/GameRow';
import { FaLessThan } from 'react-icons/fa';
import NavBar from '../elements/NavBar';
import PickWeekSelector from '../elements/PickWeekSelector';
import { usePicks } from '../handlers/full/usePicks';
import sh from '../calc/scoring';
import dh from '../calc/dates';

const PicksContent = ({ event_id, week_id, player_id }) => {
  const { state, dispatch } = useContext(PickemContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isGamesLoading, setIsGamesLoading] = useState(true);
  const [error, setError] = useState(null);
  const bgColor = useColorModeValue('forwardWhite.300', 'forwardBlue.700');

  const [myGames, setMyGames] = useState([]);
  const [myPicks, setMyPicks] = useState([]);
  const [myWeek, setMyWeek] = useState(week_id);
  const [thisPlayer, setThisPlayer] = useState(null);

  const signedInPlayer = state?.players?.find((player) => player.user_id === state?.user?.id) || null;
  const isAdmin = signedInPlayer?.is_admin || false;
  const playerMatched = signedInPlayer?.id === player_id;
  console.log('Player ID:', player_id);
  const blockTheView = !playerMatched 
                        && !isAdmin
                        && player_id > 0
                        && parseInt(state?.current_season) === parseInt(state?.event?.season) 
                        && parseInt(state?.current_week) === parseInt(week_id)

  const { syncEvent }= useEvents();
  const { syncTeams } = useTeams(); 
  const { syncPlayers } = usePlayers();
  const { syncGames } = useGames();
  const { handleUpsertPicks } = usePicks();

  //Score myPicks, useMemo
  //sh.calc_full_game_score(my_pick, game, my_config, state?.event?.nfl_picks, state?.games, is_game_scored ? null : 1)
  //I need this to iterate through all the picks in myPicks and add up the scores, and memoize it.
  const myPickScoreObject = useMemo(() => {
    return myPicks.map((pick) => {
      const game = myGames.find((game) => pick.pick === game.home_team || pick.pick === game.away_team);
      const my_config = state?.event?.configuration;
      const is_game_scored = game?.home_points !== null && game?.away_points !== null;
      const gameScores = sh.calc_full_game_score(pick, game, my_config, state?.event?.nfl_picks, state?.games, is_game_scored ? null : 1);
      return { pick: pick, score: gameScores };
    });
  }, [myPicks, myGames]);
  
  console.log('My Picks Score:', myPickScoreObject);
  


  const keyGamesCount = myPicks.filter((pick) => pick.is_key).length;

  const MAX_PICKS = state?.event?.configuration?.picks?.weekly_games;
  const MAX_KEY_GAMES = state?.event?.configuration?.picks?.key_games;


  const addPick = (team, is_key = false) => {
    if (myPicks.length >= MAX_PICKS) {
      console.log(`You can only pick ${MAX_PICKS} games.`);
      return;
    }
    const new_pick = { week: myWeek, player_id: player_id, pick: team, is_key: is_key };
    setMyPicks(prevPicks => [...prevPicks, new_pick]);
  
  };

  const removePick = (team) => {
    setMyPicks(prevPicks => {
      const updatedPicks = prevPicks.filter(pick => pick.pick !== team);
      const removedPick = prevPicks.find(pick => pick.pick === team);

      return updatedPicks;
    });
  };

  const toggleKeyGame = (team_id) => {
    setMyPicks(prevPicks => {
      const updatedPicks = prevPicks.map(pick => {
        if (pick.pick === team_id) {
          return { ...pick, is_key: !pick.is_key };
        } else if (state?.event?.configuration?.picks?.key_games === 1) {
          // If only one key game is allowed, unset any other key game
          return { ...pick, is_key: false };
        }
        return pick;
      });

      return updatedPicks;
    });
  };

  const submitPicks = () => {
    console.log('Submitting picks:', myPicks);
    const data_to_send = myPicks.map((pick) => {
      return {
        player_id: pick.player_id, 
        event_id: event_id,
        season: state.event.season,
        week: pick.week,
        pick: pick.pick, 
        is_key: pick.is_key };
    });
    console.log('Data to send:', data_to_send);
    const {response} = handleUpsertPicks({new_picks: data_to_send});
    
  };


  const handleWeekChange = (new_week) => {
    setMyWeek(new_week);
  };


  const dataRefresh = async (event_id) => {
    setIsLoading(true);
    setError(null);
    try {
        if(!state.event || state.event.id !== event_id) {
          await syncEvent(event_id);
        }
        if (!state.players || state.players.length === 0) {
          await syncPlayers();
        }
        if (!state.teams || state.teams.length === 0) {
          await syncTeams();
        }
        if (state.event && state.event.season) {
          await syncGames(state.event.season);
        }
        dispatch({ type: 'PRINT_STATE' });
      } catch (err) {
        console.error('Error refreshing data:', err);
        setError('Failed to load data. Please try again.');
      } finally {
        setIsLoading(false);
        dispatch({ type: 'PRINT_STATE' });

      }
  };

  useEffect(() => {
    console.log('Event ID:', event_id);
    dataRefresh(event_id);
  }, [event_id]);

  useEffect(() => {
    if (!isLoading && state.event && state.event.season) {
        try {
      console.log('Event or season changed, refreshing games');
      syncGames(state.event.season);
        }
        catch (err) {
          console.error('Error refreshing games:', err);
          setError('Failed to load games. Please try again.');
        }
        finally {
          setIsGamesLoading(false);
        }
    }
  }, [state.event, isLoading]);

  /*const my_games = state.games.filter((game) => game.week === week_id);
  console.log('My Games:', my_games);

  const my_picks = state.event.nfl_picks.filter((pick) => pick.week === week_id && pick.player === player_id);
  */

 useEffect(() => {
    if (!isGamesLoading && state.games && state.games.length > 0) {
      const my_games = state.games.filter((game) => game.week === myWeek);
      console.log('My Games:', my_games);
      setMyGames(my_games);
    }
  }, [state.games, isGamesLoading, myWeek, thisPlayer]);

  useEffect(() => {
    if (!isLoading && state.event && state.event.nfl_picks && state.event.nfl_picks.length > 0) {
      const my_picks = state.event.nfl_picks.filter((pick) => pick.week === myWeek && pick.player_id === player_id);
      console.log('My Picks:', my_picks);
      setMyPicks(my_picks);

    }
  }, [state.event, isLoading, myWeek, thisPlayer]);

  useEffect(() => {
    if (!isLoading && state.players && state.players.length > 0) {
      const this_player = state.players.find((player) => player.id === player_id);
      console.log('This Player:', this_player);
      setThisPlayer(this_player);
    }
  }, [state.players, isLoading, player_id]);

  const start_week = state?.event?.configuration?.picks?.start_week;
  const end_week = state?.event?.configuration?.picks?.end_week;


  if(!state.event) {
    return (
      <Box py={8} px={{ base: 4, md: 8 }}>
        <Heading as="h1" size="xl" mb={4}>Loading...</Heading>
      </Box>
    );
  }


  const base_points = myPickScoreObject.reduce((acc, pick) => {
    return acc + (pick.score.correct_pick * state?.event?.configuration?.scoring?.base);
  }, 0);
  const udog_points = myPickScoreObject.reduce((acc, pick) => {
    return acc + pick.score.underdog_points;
  }, 0);
  const key_points = myPickScoreObject.reduce((acc, pick) => {
    return acc + pick.score.key_points;
  }, 0);
  const first_time_winner = myPickScoreObject.reduce((acc, pick) => {
    return acc + pick.score.ftw_points;
  }, 0);

  const total_points = myPickScoreObject.reduce((acc, pick) => {
    return acc + pick.score.total_points;
  }, 0);
  

  return (
    <>
      <MyHeader />
      <NavBar />
      <Center>
      <Box py={8} px={{ base: 4, md: 8 }} width="100%" maxWidth="1000px">
        <VStack width="100%">
        
        {signedInPlayer === null && <Alert status="warning">You must be signed in to make picks.</Alert>}
        {signedInPlayer !== null && signedInPlayer?.id !== player_id && 
        <Alert status="warning">You are viewing picks for {thisPlayer?.player_name}. Set/Edit your picks&nbsp; 
        <Link as={RouterLink} to={`/pickem/event/${event_id}/picks/${week_id}/player/${signedInPlayer?.id}`} fontWeight="bold">here</Link>
        </Alert>}
        <PickWeekSelector start_week={start_week} end_week={end_week} selected_season={state.event.season} selected_week={myWeek} handleWeekChange={handleWeekChange} />
        <VStack width="100%">
          <Heading as="h1" size="xl" mb={4}>Picks for Week {myWeek}</Heading>
          {(playerMatched || isAdmin) && 
          <HStack justifyContent="space-between" width="100%" p={4} border="1px solid" boxShadow="md" borderColor="forwardWhite.500" bg={bgColor}>
          <VStack spacing="0" alignItems="flex-start">
            <Text fontWeight="bold">Point Summary</Text>
            <Text>Base: {base_points}</Text>
            <Text>Udog Pts: {udog_points}</Text>
            <Text>Key Game: {key_points}</Text>
            <Text>First-time Winner: {first_time_winner}</Text>
            <Text fontWeight="bold">Total: {total_points}</Text>
          </VStack>
          <VStack spacing={0}>
            <Text textColor={myPicks.length === MAX_PICKS ? 'green.300' : 'red.300'}>Picks: {myPicks.length}/{MAX_PICKS}</Text>
            <Text textColor={keyGamesCount === MAX_KEY_GAMES ? 'green.300':'red.300'}>Key Games: {keyGamesCount}/{state?.event?.configuration?.picks?.key_games}</Text>
            <Button 
            colorScheme="teal"
            size="lg"
            isDisabled={myPicks.length !== MAX_PICKS || keyGamesCount !== MAX_KEY_GAMES || (signedInPlayer.id !== player_id && !isAdmin)}
            onClick={() => submitPicks()}>
            Submit</Button>
          </VStack>
          </HStack>
          }
          <HStack width="100%" >
          <Box flex="1">Event: {state.event.event_name}</Box>
          <Box flex="1">Week: {myWeek}</Box>
          <Box flex="1">Player: {thisPlayer?.player_name}</Box>
        </HStack>
          <VStack width="100%">
            {blockTheView && <Alert status="warning">You can't view other players picks until the week is done.</Alert>}
            {!isLoading && !isGamesLoading && !blockTheView && myGames
  .sort((a, b) => new Date(a.game_time) - new Date(b.game_time))
  .map((game, index, games) => {
    // Check if the previous game's time is different from the current game's time
    const isDifferentTime = index === 0 || (new Date(game.game_time) - new Date(games[index - 1].game_time)) > 3600000;
    
    return (
      <React.Fragment key={game.id}>
        {isDifferentTime && (
          <HStack p={2} bg={bgColor} className="game-time-header" justifyContent={"space-between"} borderBottom="2px solid" width="100%">
            <Heading size="md">{dh.formatUTCDate(new Date(game.game_time),'iii MMM do')}</Heading>
            <Heading size="sm">{dh.formatUTCDate(new Date(game.game_time),'h:mm a')}</Heading>
          </HStack>
        )}
        <GameRow
          game={game}
          picks={myPicks}
          addPick={addPick}
          removePick={removePick}
          toggleKeyGame={toggleKeyGame}
          isPickDisabled={
            signedInPlayer === null ||
            myPicks.length >= MAX_PICKS + 1 ||
            dh.convertESTtoUTC(game.game_time) < new Date() ||
            (signedInPlayer.id !== player_id && !isAdmin)
          }
          isKeyGameDisabled={
            signedInPlayer === null ||
            keyGamesCount >= MAX_KEY_GAMES + 1 ||
            dh.convertESTtoUTC(game.game_time) < new Date() ||
            (signedInPlayer.id !== player_id && !isAdmin)
          }
        />
      </React.Fragment>
    );
  })}
          </VStack>  
        </VStack>
        </VStack>
      </Box>
      </Center>
    </>
  );
};

const PicksPage = () => {
  /* <Route path="/pickem/event/:event_id/picks/:week_id/player/:player_id" element={<PicksPage />}  /> */
  const { event_id, week_id, player_id } = useParams();
  console.log('Event ID from params:', event_id);
  const event_as_int = parseInt(event_id);
  const week_as_int = parseInt(week_id);
  const player_as_int = parseInt(player_id);
  console.log('Player ID from params:', player_as_int);

  return (
    <PickemProvider>
      <PicksContent event_id={event_as_int} week_id={week_as_int} player_id={player_as_int} />
    </PickemProvider>
  );
};

export default PicksPage;
import React, { useEffect, useContext, useState } from 'react';
import { Box, Heading, VStack, HStack} from '@chakra-ui/react';
import MyHeader from '../../general/components/MyHeader';
import { PickemProvider, PickemContext } from '../handlers/context/PickemContext';
import { useEvents } from '../handlers/full/useEvents';
import { useTeams } from '../handlers/full/useTeams';
import { usePlayers } from '../handlers/full/usePlayers';
import { useGames } from '../handlers/full/useGames';
import { useParams } from 'react-router-dom';
import { useLeagues } from '../handlers/full/useLeagues';
import { Link as RouterLink } from 'react-router-dom';
import NavBar from '../elements/NavBar';

const LeagueContent = ({ league_id }) => {
  const { state, dispatch } = useContext(PickemContext);
  const { syncLeagues } = useLeagues();

  const dataRefresh = async () => {
    await syncLeagues();
    dispatch({ type: 'PRINT_STATE' });
  };

    useEffect(() => {
    dataRefresh();
    }, []);

  if(!state.leagues) {
    return (
      <Box py={8} px={{ base: 4, md: 8 }}>
        <Heading as="h1" size="xl" mb={4}>Loading...</Heading>
      </Box>
    );
  }

  const my_league = state.leagues.find(league => league.id === league_id);

  return (
    <>
      <MyHeader />
      <NavBar />
      <Box py={8} px={{ base: 4, md: 8 }}>
        <Heading as="h1" size="xl" mb={4}>Welcome to {my_league && my_league.league_name}</Heading>
          <Heading as="h2" size="lg">Events:</Heading>
          {my_league && my_league.nfl_events.length === 0 && <Box>No events</Box>}
          <VStack alignItems="flex-start">
          {my_league && my_league.nfl_events.length > 0 && my_league.nfl_events.map((league_event) => {
            return (

                <Heading size="sm" as={RouterLink} to={`/pickem/event/${league_event.id}`}>{league_event.event_name}</Heading>

            );
          })}
                        </VStack>
      </Box>
    </>
  );
};

const LeaguePage = () => {
  const { league_id } = useParams();
  console.log('Event ID from params:', league_id);
  const league_as_int = parseInt(league_id);

  return (
    <PickemProvider>
      <LeagueContent league_id={league_as_int} />
    </PickemProvider>
  );
};

export default LeaguePage;
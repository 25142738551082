import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
//import { sharedStyles } from '../../../calc/expound/theme';


const RoundCounter = ({ currentRound, totalRounds, gameMode, gameState }) => {

if (gameState === 'gameOver') {
    return <Box h="50px">
    <Text fontSize="xs">{gameMode}</Text>
    <Text fontSize={{'base':'md','md':'xl'}} fontWeight="bold">
        Complete
</Text>
</Box>;
}

return <Box h="50px">
    <Text fontSize="xs">{gameMode}</Text>
    <Text fontSize={{'base':'md','md':'xl'}} fontWeight="bold">
Round {currentRound} / {totalRounds}
</Text>
</Box>;

};

export default RoundCounter;
import React, { useRef } from 'react';
import { Button, useToast } from '@chakra-ui/react';

const ShareButton = ({ my_stats }) => {
  const textAreaRef = useRef(null);
  const toast = useToast();

  const handleShare = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: 'That fun new word game everyone is talking about!',
          text: my_stats,
          url: 'https://mumma.co/expound',
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      // Fallback to copying the text to clipboard
      console.log('Web Share API not supported. Copying to clipboard.');
      navigator.clipboard
        .writeText(`${my_stats} https://mumma.co/expound`)
        .then(() => {
          toast({
            title: 'Copied to clipboard',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });

    }
  };

  return (
    <>
      <Button onClick={handleShare} colorScheme="blue">
        Share
      </Button>
      <textarea
        ref={textAreaRef}
        readOnly
        hidden
        style={{ position: 'absolute', left: '-9999px' }}
      />
    </>
  );
};

export default ShareButton;

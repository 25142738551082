import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Heading,
    Text,
    VStack,
    HStack,
    ButtonGroup,
    Divider,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Icon,
    Flex,
    useColorModeValue,
    useDisclosure,
  } from "@chakra-ui/react";
  import { FaCog } from "react-icons/fa";
  import {loadGameHistory, clearUserHistory} from '../calc/statistics.js';
  import ModeButton from '../content/ModeButton';
  import PersonalBests from "../content/PersonalBests.jsx";
  import { gameModes } from "../data/constants.js";
  import ShortRules from "../content/ShortRules.jsx";
  import { calculateGameStats } from "../calc/statistics.js";
  import { ColorModeSwitcher } from '../../general/components/ColorModeSwitcher';
  import { HomeButton } from "../../general/components/HomeButton.jsx";
  import ShareButton from "../content/ShareButton.jsx";
  import FeedbackModal from "../../general/components/FeedbackModal.jsx";
  import InfoModal from "../../general/components/InfoModal.jsx";
  import { infoObject } from "../data/info.js";

  function StartScreen({ onStartGame, gameMode }) {
    //console.log(gameMode);
    //console.log(gameHistory);
    const [selectedMode, setSelectedMode] = useState(null);
    const { isOpen: isPFOpen, onOpen: onPFOpen, onClose: onPFClose } = useDisclosure();
    const { isOpen: isInfoOpen, onOpen: onInfoOpen, onClose: onInfoClose } = useDisclosure();

    const trackCustomEvent = (eventName, props) => {
      if (window.plausible) {
        const options = {props:props};
        console.log(options);
        window.plausible(eventName, options);
      }
    };

    const handleStart = () => {
      const eventName = 'New Expound Game';
      console.log(eventName);
      const props = {'mode': selectedMode.name}
      trackCustomEvent(eventName,props);
      onStartGame(selectedMode);
    };

    useEffect(() => {
      if (gameMode) {
        const matchingMode = gameModes.find((mode) => mode.name === gameMode);
        setSelectedMode(matchingMode);
      }
    }, [gameMode]);

    const userHistory = loadGameHistory();
    const {modeStats,my_totals} = calculateGameStats(userHistory);
    console.log(userHistory);
    console.log(modeStats);

    const isModeUnlocked = (mode) => {
      return mode.unlock_conditions.every((condition) => {
        return modeStats[condition.id] && modeStats[condition.id]['bestScore'] >= condition.score;
      });
    };

    //console.log(selectedMode);
    const desc_color = useColorModeValue("gray.600","gray.100");

    return (
      <>
        <VStack spacing="4">
          <HStack width="100%" justifyContent="space-between">
          <Heading as="h3" size="md" mb="1">
            Welcome to Expound!
          </Heading>
            <ButtonGroup flexDirection={{'base':'row','md':'row'}} className="buttons">
          <Menu>
          <MenuButton as={Button} colorScheme="gray" iconSpacing={0} size={{'base':'xs','md':'md'}}>
          <Icon as={FaCog} float="right" />
            </MenuButton>
          <MenuList>
            <MenuItem onClick={onInfoOpen}>Game Info</MenuItem>
            <MenuItem onClick={onPFOpen}>Share Feedback</MenuItem>
            <MenuItem onClick={clearUserHistory}>Clear User History</MenuItem>
          </MenuList>
        </Menu>
        <HomeButton  size={{'base':'xs','md':'md'}} />
        <ColorModeSwitcher  size={{'base':'xs','md':'md'}} />
        </ButtonGroup>
        </HStack>
        <VStack maxWidth="100%" p={2}>
          <ShortRules />
          <Heading as="h5" size="m" mb="2">Choose a Game Mode:</Heading>
          <Flex flexDirection={{'base':'column','md':'row'}}>
            {gameModes.map((mode, index) => {
              const unlocked = isModeUnlocked(mode);
              return (
                <ModeButton
                  key={mode.id}
                  mode={mode}
                  onModeSelected={setSelectedMode}
                  selectedMode={selectedMode}
                  isLocked={!unlocked}
                  gameModes={gameModes}
                  isModeUnlocked={isModeUnlocked}
                />
              );
            })}
          </Flex>
        <Text textAlign="center" fontStyle="italic" textColor={desc_color}>{(selectedMode !== null && selectedMode !== undefined) ? selectedMode.description : ''}</Text>
          <Button mt="6" mb="6" size="lg" bg='green.600' onClick={handleStart} isDisabled={!selectedMode} boxShadow="xl" width={{'base':'100%','md':'auto'}}>
            Start Game
          </Button>
          </VStack>
          <Divider />
          <PersonalBests gameHistory={userHistory} />
        </VStack>
        <FeedbackModal 
        isOpen={isPFOpen}
        onClose={onPFClose}
        product="Expound" />
      <InfoModal 
        isOpen={isInfoOpen}
        onClose={onInfoClose}
        infoObject={infoObject} />
        </>
      );
  }

  export default StartScreen;
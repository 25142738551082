export const paddingSizes = {
    base: 4,
    md: 8,
  };
  
  export const fontSizes = {
    base: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    print:'md',
  };
  export const bigFontSizes = {
    base: 'sm',
    sm: 'md',
    md: 'lg',
    lg: 'xl',
    print:'lg'
  };
  export const smallFontSizes = {
    base: '2xs',
    sm: 'xs',
    md: 'sm',
    lg: 'md',
    print:'sm',
  };

  export const headerSizes = {
    base: 'xs',
    sm: 'sm',
    md: 'sm',
    lg: 'md',
    print:'sm',
  }
  export const bigHeaderSizes = {
    base: 'sm',
    sm: 'md',
    md: 'lg',
    lg: 'xl',
    print:'lg',
  }
  export const smallHeaderSizes = {
    base: 'xs',
    sm: 'xs',
    md: 'sm',
    lg: 'sm',
    print:'sm',
  }



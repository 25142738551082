import axios from 'axios';
import { scrabbleValues } from '../data/constants';

/**********************************************/
/* Helper Functions */
/**********************************************/


function calculateFinalScore(roundResults) {
        return roundResults.reduce((totalScore, roundResult) => totalScore + roundResult.score, 0);     
      }

async function wordExists(word) {
    try {
    const response = await axios.get(
        `https://api.datamuse.com/words?sp=${word}&max=1`
    );
    return response.data.length > 0 && response.data[0].word === word;
    } catch (error) {
    console.error('Error while checking word:', error);
    return false;
    }
}

function calculateRoundScore(isCorrect, input, timeTaken, scoringMode, letters, minLengthAllowed, maxLengthAllowed) {
    console.log(scoringMode);
    const longEnough = input.length >= minLengthAllowed;
    const shortEnough = input.length <= maxLengthAllowed;
 
    if (scoringMode === 'basic') {
        return isCorrect>0 ? 1*longEnough*shortEnough : 0;
    }
    if (scoringMode === 'traditional') {
        return isCorrect*longEnough*shortEnough;
    }
    if (scoringMode === 'traditional_plus') {
        return isCorrect*(letters.length)*longEnough*shortEnough;
    }
    else if (scoringMode === 'scrabble') {
        const bonus = letters.length-1;
                
        if (isCorrect===0 || !longEnough || !shortEnough) {
            return 0;
        }
        return input
                .toLowerCase()
                .split('')
                .reduce((sum, letter) => sum + bonus*scrabbleValues[letter], 0);

    }
    else if (scoringMode === 'timed') {
        const penalty = isCorrect===1 ? 0 : 20*(1-isCorrect);
        const lenPenalty = 20*(1-Math.min(longEnough,shortEnough));
        return Math.ceil(10-(timeTaken/3)) - Math.max(penalty,lenPenalty);
    }
}

async function validateWord(letters, word) {
    if (!letters || !word) {
        return { value: 0, isWord: false };
    }
    const lowerCaseWord = word.trim().toLowerCase();
    const lowerCaseLetters = letters.map((letter) => letter.toLowerCase());
    // Check if all letters are included in the word
    const allLettersIncluded = lowerCaseLetters.every((letter) => {
        const letterCountInWord = lowerCaseWord.split(letter).length - 1;
        const letterCountInLetters = lowerCaseLetters.filter(
        (l) => l === letter
        ).length;
        return letterCountInWord >= letterCountInLetters;
    });

    // Check if the word is an english word!
    const exists = await wordExists(lowerCaseWord);

    if (!exists) {
        return { value: 0, isWord: false };
    }

    if (!allLettersIncluded) {
        return { value: 0, isWord: true };
    }

    //Now, scan to see if ordering matches either forward or in reverse!
    let forwardIndex = 0;
    let reverseIndex = lowerCaseLetters.length - 1;

    for (let i = 0; i < lowerCaseWord.length; i++) {
        if (lowerCaseWord[i] === lowerCaseLetters[forwardIndex]) {
        forwardIndex++;
        }
        if (lowerCaseWord[i] === lowerCaseLetters[reverseIndex]) {
        reverseIndex--;
        }
    }

    const forwardOrder = forwardIndex === lowerCaseLetters.length;
    const reverseOrder = reverseIndex === -1;
    let value;

    if (forwardOrder) {
        value = 1;
    } else if (reverseOrder) {
        value = 0.75;
    } else {
        value = 0.5;
    }

    return { value: value, isWord: true };
}

export {calculateFinalScore,
        calculateRoundScore,
        wordExists,
        validateWord};
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';


function ForwardRedirect() {
  React.useEffect(() => {
    window.location.href = 'https://www.theforwardapp.com';
  }, []);

  return null; // This will render nothing while the redirection is taking place
}

export default ForwardRedirect;
import React, { useEffect, useContext} from 'react';
import { Box, Heading, Link, VStack } from '@chakra-ui/react';
import MyHeader from '../../general/components/MyHeader';
import { PickemProvider, PickemContext } from '../handlers/context/PickemContext';
import { useLeagues } from '../handlers/full/useLeagues';
import { useGames } from '../handlers/full/useGames';
import { useTeams } from '../handlers/full/useTeams';
import { usePlayers } from '../handlers/full/usePlayers';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';


// Create a new component that uses the hook
const PickemContent = () => {
  const { state, dispatch } = useContext(PickemContext);
  const { syncLeagues } = useLeagues();

  const { syncTeams } = useTeams();
  const { syncPlayers } = usePlayers();

  const dataRefresh = async () => {
    await syncLeagues();
    await syncTeams();
    await syncPlayers();
  }

  useEffect(() => {
    dataRefresh();
  }, []);

  return (
    <>
      <MyHeader />
      <Box py={8} px={{ base: 4, md: 8 }}>
        <Heading as="h1" size="xl" mb={4}>Welcome to Mumma's NFL Pick'em!</Heading>
        {state.leagues.map((league) => {
          return (
          <>
          <Box key={league.id} p={4} border="1px solid" borderColor="teal">
            <Heading size="lg">{league.league_name}</Heading>
            {league.nfl_events.length === 0 && <Box>No events</Box>}
            {league.nfl_events.length > 0 && <Heading size="sm">Events:</Heading>}
            {league.nfl_events.map((league_event) => {
              return (
                <VStack alignItems="flex-start">
              <Link p={2} key={league_event.id} as={RouterLink} to={`/pickem/event/${league_event.id}`}>
                {league_event.event_name}
              </Link>
              </VStack>
            )
            }
            )}  
          </Box>
            </>
        )
      }
      )}
      </Box>
    </>
  );
};

// Main component that provides the context
const PickemMain = () => {
  const { user, loading } = useAuth();
  return (
    <PickemProvider>
      <PickemContent />
    </PickemProvider>
  );
};

export default PickemMain;
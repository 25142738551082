import { Heading } from '@chakra-ui/react';


function Score({ roundResults, calculateFinalScore, scoringMode }) {
    const score = calculateFinalScore(roundResults, scoringMode);
  
    return (
      <Heading as="h3" fontSize={{'base':'md','md':'xl'}} mb="2">
        Final Score: {score}
      </Heading>
    );
  }

  export default Score;